export enum SORT_BY {
  CHEAP_TO_EXPENSIVE = "CHEAP_TO_EXPENSIVE",
  EXPENSIVE_TO_CHEAP = "EXPENSIVE_TO_CHEAP",
}

export const SORT_BY_TEXT: { [key in SORT_BY]: string } = {
  [SORT_BY.CHEAP_TO_EXPENSIVE]: "Sort by cheapest to most expensive",
  [SORT_BY.EXPENSIVE_TO_CHEAP]: "Sort by most expensive to cheapest",
};

export const getSortString = (sortBy: SORT_BY | string): string => {
  const convertedSortBy = typeof sortBy === "string" ? sortBy : SORT_BY[sortBy];
  switch (convertedSortBy) {
    case SORT_BY.CHEAP_TO_EXPENSIVE:
      return "-price";
    case SORT_BY.EXPENSIVE_TO_CHEAP:
      return "price";
    default:
      return "";
  }
};
