import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { AboutUsSection } from "../components/AboutUsSection";
import { Ad } from "../components/Ad";
import { ErrorFallback } from "../components/ErrorFallback";
import { H4 } from "../components/Header";
import { HomeHero } from "../components/HomeHero";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { LinkButton } from "../components/LinkButton";
import { ListingTilePreview } from "../components/ListingTilePreview";
import { ParentingAppTile } from "../components/ParentingAppTile";
import { SectionHeading } from "../components/SectionHeading";
import { COLORS } from "../constants/colors";
import { useListing } from "../hooks/useListing";

const HomePageMainContent = styled.div``;

export const HomeHeartLogo = styled.img`
  width: 36px;
  height: 36px;
`;

const LeftHeadingSubText = styled.div`
  margin-left: 50px;
`;

const FeaturedListingsGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-padding-left: 20px;
  scroll-snap-type: x proximity;
  scrollbar-width: none;
  padding: 10px;

  ${up("lg")} {
    padding: 0;
    overflow-x: initial;
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(231px, 1fr));
  }
`;
const RecentListingsGrid = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  flex-wrap: wrap;
  /* overflow-x: auto; */
  scroll-behavior: smooth;
  scroll-padding-left: 20px;
  scroll-snap-type: x proximity;
  scrollbar-width: none;

  ${up("lg")} {
    display: grid;
    grid-row-gap: 40px;
    grid-column-gap: 20px;
    justify-items: center;

    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

const ParentingAppsGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(201px, 1fr));
  margin-bottom: 100px;
`;

const FeaturedListingsSection = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;

  ${up("lg")} {
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
  } ;
`;

const RecentListingsSection = styled.div`
  background-color: white;
  border-top: 1px solid ${COLORS.BLACK25};
  padding-top: 60px;
  padding-bottom: 60px;

  ${up("lg")} {
    max-width: 1130px;
    margin-left: auto;
    margin-right: auto;
  } ;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const ListingHeaderButton = styled.div`
  width: 425px;
  max-width: 100%;
`;

export const HomePage = () => {
  return (
    <div>
      <HomeHero />
      <LayoutBodyFullwidth>
        <LayoutBody>
          <HomePageMainContent>
            {/* <FeaturedListings /> */}
            <RecentListings />
            {/* <ParentingApps /> */}
          </HomePageMainContent>
        </LayoutBody>
      </LayoutBodyFullwidth>
    </div>
  );
};

const SubTitle = styled.div`
  color: #f27442;
  font-size: 20px;
  font-weight: normal;
  text-align: left;

  ${up("lg")} {
    margin-left: 60px;
  }
`;

const FeaturedListings = () => {
  const { featuredListings, getFeaturedListings } = useListing();

  useEffect(() => {
    getFeaturedListings();
  }, [getFeaturedListings]);

  const shortList = featuredListings.slice(0, 4);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FeaturedListingsSection>
        <SectionHeading
          subTitle={
            <SubTitle>
              A selection of listings for you - hand-picked by us!
            </SubTitle>
          }
          icon={
            <HomeHeartLogo
              src="/assets/images/bellybutton-hearticon.png"
              alt="Belly Button Heart Icon"
            />
          }
          title="Featured Listings"
          titleColor={COLORS.DARK_ORANGE}
        >
          <ListingHeaderButton>
            <LinkButton to="/featuredlistings" type={"secondary"}>
              BROWSE ALL FEATURED LISTINGS
            </LinkButton>
          </ListingHeaderButton>
        </SectionHeading>
        <LeftHeadingSubText></LeftHeadingSubText>
        <FeaturedListingsGrid>
          {shortList.map((listing) => (
            <ListingTilePreview
              key={listing.id.uuid}
              isFeatured
              listing={listing}
            />
          ))}
        </FeaturedListingsGrid>
      </FeaturedListingsSection>
    </ErrorBoundary>
  );
};

const RecentListings = () => {
  const { publicListings, getPublicListings } = useListing();
  useEffect(() => {
    getPublicListings();
  }, [getPublicListings]);

  // const limitedListings = publicListings.slice(0, 8);
  const limitedListings = publicListings;

  return (
    <RecentListingsSection>
      <SectionHeading
        titleColor="#353b3c"
        icon={
          // <HomeHeartLogo
          //   src="/assets/images/bellybutton-hearticon.png"
          //   alt="Belly Button Heart Icon"
          // />
          <></>
        }
        title="Current Stock"
      >
        <ListingHeaderButton>
          {/* <LinkButton to="/buy" type="secondary">
            {"Browse all recent listings".toUpperCase()}
          </LinkButton> */}
        </ListingHeaderButton>
      </SectionHeading>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <RecentListingsGrid>
          {limitedListings.map((listing) => (
            <ListingTilePreview key={listing.id.uuid} listing={listing} />
          ))}
        </RecentListingsGrid>
      </ErrorBoundary>
    </RecentListingsSection>
  );
};

const ParentHeaderButton = styled.div`
  width: 311px;
`;

const ParentingApps = () => {
  const navigate = useNavigate();
  return (
    <RecentListingsSection>
      <SectionHeading
        icon={
          <HomeHeartLogo
            src="/assets/images/bluebgheart.svg"
            alt="Bellybutton Blue Heart Background"
          />
        }
        title="Parenting Apps"
        subTitle={
          <H4
            style={{
              color: "#304669",
              fontSize: "20px",
              fontWeight: "normal",
              textAlign: "left",
              overflow: "wrap",
            }}
          >
            A selection of apps available on the Google Play Store and iOS,
            hand-picked and recommended by us, to help make <br></br> your
            parenting journey a little easier.
          </H4>
        }
      >
        <ParentHeaderButton>
          <LinkButton to="/parentingapps" type="blueborder">
            {"Browse all apps".toUpperCase()}
          </LinkButton>
        </ParentHeaderButton>
      </SectionHeading>

      <ParentingAppsGrid>
        <ParentingAppTile
          title="Raising Children"
          link="https://raisingchildren.org.nz/"
          subTitle="Made in NZ newborn to primary parenting tips, video library & more"
          imageUrl="/assets/images/raisingchild.jpg"
        />
        <ParentingAppTile
          title="Well Child"
          link="https://www.wellchild.org.nz/"
          subTitle="Education & information for NZ parents; can be used with any health book"
          imageUrl="/assets/images/wellchild.jpg"
        />
        <ParentingAppTile
          title="Whare Kahika - Home Safety"
          link="https://www.healthnavigator.org.nz/apps/w/whare-kahik%C4%81-home-safety-app/"
          subTitle="Helping NZ parents identify potential safety risks at home"
          imageUrl="/assets/images/wharekahika.jpg"
        />
        <ParentingAppTile
          title="Positively Pregnant"
          link="https://www.positivelypregnant.org.nz/"
          subTitle="Improve parenting confidence & build social support"
          imageUrl="/assets/images/positivelypregnant.jpg"
        />
        <ParentingAppTile
          title="Pregnancy + Tracker"
          link="https://philips-digital.com/pregnancy-new/"
          subTitle="Track development, get expert advice and mother care tips"
          imageUrl="/assets/images/pregnancytracker.jpg"
        />
      </ParentingAppsGrid>
    </RecentListingsSection>
  );
};

export default HomePage;
