import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { useAuth } from "../hooks/useAuth";
import { IListing, useListing } from "../hooks/useListing";
import { IHistoricListing } from "../hooks/useTransaction";
import { getListingDateAsStringFromListing } from "../utils/date";
import { getImageURLByListing } from "../utils/images";
import { formatPriceFromPriceObject } from "../utils/price";
import { getUsernameForListing } from "../utils/users";
import { Button } from "./Button";
import { ContactSellerModal } from "./ContactSellerModal";

interface IFeedbackListingTileProps {
  listing: IHistoricListing;
}

export const PurchasedListingTile = (props: IFeedbackListingTileProps) => {
  const { user } = useAuth();
  const { listing } = props;
  const { allMetadata } = useListing();
  const navigate = useNavigate();
  const imageUrl = getImageURLByListing(listing, allMetadata);
  const [showDialog, setShowDialog] = useState(false);
  const listingAuthor = getUsernameForListing(listing, allMetadata);

  const giveFeedbackClicked = () => {
    navigate(
      "/give-feedback/" +
        listing.transaction.id.uuid +
        "?transactionStep=" +
        props.listing.nextFeedbackTransition +
        "&listingTitle=" +
        props.listing.attributes.title +
        "&otherPerson=" +
        listingAuthor
    );
  };

  const contactSellerClicked = () => {
    setShowDialog(true);
  };

  const dateFormatted = getListingDateAsStringFromListing(listing);
  const formattedPrice = formatPriceFromPriceObject(listing.attributes.price);
  return (
    <Wrapper>
      <ContactSellerModal
        showDialog={showDialog}
        toggle={() => {
          setShowDialog((prev) => !prev);
        }}
        senderId={user?.id?.uuid ?? ""}
        recipientId={listing.relationships.author.data.id.uuid}
        listingName={listing.attributes.title}
      />
      <HeadingArea>
        <Title>{listing.attributes.title}</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Date>
            <i>Listed {dateFormatted}</i>
          </Date>
          <Price>{formattedPrice}</Price>
        </div>
      </HeadingArea>
      <Image src={imageUrl} />
      <ButtonArea>
        <Button
          type="secondary"
          buttonType="button"
          text="CONTACT SELLER"
          onClick={contactSellerClicked}
        />
        {!listing.canGiveFeedback && (
          <div style={{ marginBottom: "12px" }}>
            You have already given feedback.
          </div>
        )}
        {listing.canGiveFeedback && (
          <Button
            type="secondary"
            buttonType="button"
            text="GIVE FEEDBACK"
            onClick={giveFeedbackClicked}
          />
        )}
      </ButtonArea>
    </Wrapper>
  );
};

const Image = styled.img`
  height: 240px;
  object-fit: contain;
  margin-bottom: 12px;
  background-color: white;
  border-top: 1px solid ${COLORS.LIGHT_CHARCOAL_GREY};
  border-bottom: 1px solid ${COLORS.LIGHT_CHARCOAL_GREY};
`;

const Wrapper = styled.div`
  width: 288px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${COLORS.DARK_ORANGE};
  background-color: ${COLORS.LIGHT_GREY};
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  border-radius: 4px;
  border: 1px solid ${COLORS.DARK_CHARCOAL_GREY};
`;

const HeadingArea = styled.div`
  text-align: left;
  flex-direction: column;
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 8px;
`;

const DateTitleArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
`;

const Date = styled.h2`
  font-size: 12px;
`;

const Price = styled.h3`
  font-size: 32px;
  font-weight: bold;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  align-self: center;

  button {
    margin-bottom: 20px;
    font-size: 12px;
  }
`;
