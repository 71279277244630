import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { ALL_LOCATIONS } from "../constants/locations";
import { useAuth } from "../hooks/useAuth";
import { useTransaction } from "../hooks/useTransaction";
import { formatDateForProfile } from "../utils/date";

const ListItemNormal = styled.div`
  text-decoration: none;
  line-height: 40px;
  font-size: 16px;
  color: #304669;
`;

const ListItemBold = styled.div`
  line-height: 40px;
  text-decoration: none;
  font-weight: 700;
  font-size: 20px;
  color: #304669;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Wrapper = styled.div`
  display: none;
  ${up("lg")} {
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
  }
`;

export const AccountDetailsGrid = () => {
  const { user, usersById, getUserById } = useAuth();
  const { userScoresById } = useTransaction();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getUserById(id);
    }
  }, [getUserById, id]);

  const userToUse = id ? usersById[id] : user;
  if (!userToUse) {
    return <div>Loading...</div>;
  }

  const userScore = userScoresById[userToUse.id.uuid];
  const createdAt = formatDateForProfile(userToUse.attributes.createdAt);
  const { displayName } = userToUse.attributes.profile;
  const { location } = userToUse.attributes.profile.publicData;
  return (
    <>
      <MobileWrapper>
        <Row>
          <Bold>Username</Bold>
          <div> {displayName}</div>
        </Row>
        <Row>
          <Bold>Member since</Bold>
          <div> {createdAt}</div>
        </Row>
        <Row>
          <Bold>Location</Bold>
          <div> {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}</div>
        </Row>
        <Row>
          <Bold>Feedback</Bold>
          <div>
            {userScore} positive review{userScore > 1 ? "s" : ""}
          </div>
        </Row>
      </MobileWrapper>
      <Wrapper>
        <Column>
          <ListItemBold>Username</ListItemBold>
          <ListItemBold>Location</ListItemBold>
        </Column>
        <Column>
          <ListItemNormal>{displayName}</ListItemNormal>
          <ListItemNormal>
            {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
          </ListItemNormal>
        </Column>
        <Column>
          <ListItemBold>Member since</ListItemBold>
          <ListItemBold>Feedback</ListItemBold>
        </Column>
        <Column>
          <ListItemNormal>{createdAt}</ListItemNormal>
          <ListItemNormal>
            {userScore} positive review{userScore > 1 ? "s" : ""}
          </ListItemNormal>
        </Column>
      </Wrapper>
    </>
  );
};

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  ${up("lg")} {
    display: none;
  }
`;

const Row = styled.div`
  font-size: 1rem;
  ${up("sm")} {
    font-size: 1.5rem;
  }
  display: flex;
  justify-content: space-between;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 8px;
  text-align: right;
`;

const Bold = styled.div`
  font-weight: 700;
  margin-right: 3rem;
`;
