import styled, { css } from "styled-components";
import {
  getButtonBorderColor,
  getButtonBackgroundColor,
  getButtonColor,
  getButtonHoveredBackgroundColor,
  getButtonHoveredColor,
  ButtonType,
} from "../utils/buttonColors";

interface IButtonProps {
  size?: "sm" | "md" | "lg";
  type?: ButtonType;
  text: string;
  buttonType: "submit" | "reset" | "button";
  onClick?: (event: any) => void;
}

export const Button = (props: IButtonProps) => {
  const { size, type, text, buttonType, onClick } = props;

  return (
    <ButtonWrapper
      type={buttonType}
      onClick={onClick}
      size={size}
      styleType={type}
    >
      {text.toUpperCase()}
    </ButtonWrapper>
  );
};

export const BaseButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  min-height: 34px;
  min-width: 128px;
  width: 100%;
  min-height: 34px;
  font-weight: bold;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  box-shadow: none;
  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.button<{ styleType: any; size: any }>`
  ${BaseButtonStyle}
  border-color: ${(props: any) => getButtonBorderColor(props.styleType)};
  color: ${(props: any) => getButtonColor(props.styleType)};
  background-color: ${(props: any) =>
    getButtonBackgroundColor(props.styleType)};
  :hover {
    background-color: ${(props: any) =>
      getButtonHoveredBackgroundColor(props.styleType)};
    color: ${(props: any) => getButtonHoveredColor(props.styleType)};
  }
`;
