import { useEffect } from "react";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { H1 } from "../components/Header";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { ListingTilePreview } from "../components/ListingTilePreview";
import { COLORS } from "../constants/colors";
import { useListing } from "../hooks/useListing";

const HeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f27442;
  background-image: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  margin-bottom: 40px;

  ${up("lg")} {
    height: 250px;
    margin-bottom: 80px;
    background-image: url("/assets/images/header-image.png");
  }
`;

const Heart = styled.img`
  display: flex;
  width: 120px;
  height: 120px;
`;

const StyledH1 = styled.h1`
  color: #304669;
  font-size: 40px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

const Hero = () => {
  return (
    <HeroWrapper>
      <StyledH1>Featured Listings</StyledH1>
    </HeroWrapper>
  );
};

export const FeaturedListings = () => {
  return (
    <Wrapper>
      <Hero />
      <LayoutBodyFullwidth>
        <LayoutBody>
          {/* <Title /> */}
          <Listings />
        </LayoutBody>
      </LayoutBodyFullwidth>
    </Wrapper>
  );
};

const Listings = () => {
  const { featuredListings, getFeaturedListings } = useListing();

  useEffect(() => {
    getFeaturedListings();
  }, [getFeaturedListings]);
  return (
    <ListingsWrapper>
      {featuredListings.map((listing) => (
        <ListingTilePreview key={listing.id.uuid} listing={listing} />
      ))}
    </ListingsWrapper>
  );
};

const ListingsWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(312px, 1fr));
  grid-row-gap: 40px;
`;

const Title = () => {
  return (
    <div
      style={{
        paddingRight: "20px",
        paddingLeft: "8px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        alignItems: "center",
        marginBottom: "40px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {/* <div>BELLYBUTTON TODO</div> */}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Heart src="/assets/images/bellybutton-hearticon.svg" />
      </div>
      <h2
        style={{
          fontSize: "50px",
          lineHeight: "55px",
          color: COLORS.DARK_ORANGE,
          fontWeight: "bold",
        }}
      >
        {/* Featured listings */}
      </h2>
    </div>
  );
};

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

export default FeaturedListings;
