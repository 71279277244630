import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Title } from "../../App";
import { BlogPosts, IBlogPost } from "../../constants/blogposts";

// Why are line breaks not working in the content?
// Probably because it's not a string, but a JSX element.
// to fix this we need to use the dangerouslySetInnerHTML prop

// Here are content blocks, an array of blocks of the content but split out so as to put each line break in a new array element

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const BlogPage = () => {
  // Invert the order of the blog posts so that the most recent one is first
  const sortedBlogPost = BlogPosts.slice().reverse();
  return (
    <div>
      {sortedBlogPost.map((post, index) => (
        <UnstyledLink key={post.slug} to={`${post.slug}`}>
          <BlogPost key={index} {...post} />
        </UnstyledLink>
      ))}
    </div>
  );
};

const BlogPostContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  text-align: left;
  box-sizing: content-box;
`;

const BlogPostCard = styled.div`
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
`;

const BlogPostTitle = styled.h1`
  font-size: 36px;
  /* font-weight: bold; */
  margin-bottom: 20px;
  text-align: center;
`;

const BlogPostContent = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;

  // Make the first letter fancy and big only if the first paragraph
  &:first-of-type {
    // Make the first letter fancy and big only if the first paragraph
    &:first-letter {
      font-size: 20px;
      font-weight: bold;
      color: black;
    }
  }
`;

const BlogPostSubtitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const BlogPostImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const BlogPostImage = styled.img`
  width: 33.33%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
`;

const Date = styled.div`
  font-size: 14px;
  /* font-weight: bold; */
  margin-bottom: 20px;
  text-align: left;
  // Make this text feel more like a date text
  color: #f27442;
  font-weight: bold;
  // italics
  font-style: italic;
`;

// Gets the blog post data from the constants file
// Select the blog post slug based on the url slug
export const SingleBlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const blogPost = BlogPosts.find((post) => post.slug === slug);
  if (!blogPost) return <div>Blog post not found</div>;
  return (
    <>
      <Title title={blogPost.title} />
      <BlogPost {...blogPost} />;
    </>
  );
};

export const BlogPost = ({ title, content, date, imageUrls }: IBlogPost) => {
  return (
    <BlogPostContainer>
      <BlogPostCard>
        <BlogPostTitle>{title}</BlogPostTitle>
        <Date>{date}</Date>
        {/* Insert new paragraph for every new line character in the content */}
        {/* Check if content is of type string */}
        {typeof content !== "string" && content}
        {typeof content === "string" &&
          content
            .split("\n")
            .map((paragraph, index) => (
              <BlogPostContent key={index}>{paragraph}</BlogPostContent>
            ))}
        {imageUrls.length > 0 && (
          <BlogPostImages>
            {imageUrls.map((imageUrl) => (
              <BlogPostImage key={imageUrl} src={imageUrl} alt={title} />
            ))}
          </BlogPostImages>
        )}
      </BlogPostCard>
    </BlogPostContainer>
  );
};
