import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ErrorList } from "../components/ErrorList";
import { ImagePicker } from "../components/ImagePicker";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { SellConfirmArea } from "../components/SellConfirmArea";
import { SellDetails } from "../components/SellDetails";
import { ListItemHero } from "../components/SellPageHero";
import { ICategory, ISubCategory } from "../constants/categories";
import { CONDITIONS } from "../constants/conditions";
import { POSTAGE } from "../constants/postage";
import { useListing } from "../hooks/useListing";
import {
  getCategoryByApiName,
  getSubCategoryByApiName,
} from "../utils/categories";
import {
  CreateListingMainContent,
  CreateListingWrapper,
} from "./CreateListing";

interface IFormFields {
  title: string;
  description: string;
  price: number;
  category: any;
  subCategory: any;
  condition: CONDITIONS;
  postage: POSTAGE;
  location: string;
  images: string[];
}

export const EditListing = () => {
  const {
    getOwnListingById,
    ownListingById,
    updateListing,
    newListingImages,
    removeAllImages,
  } = useListing();
  const navigate = useNavigate();
  const [showImageError, setShowImageError] = useState(false);

  const { id } = useParams();

  const [subCategoriesForCategory, setSubCategoriesForCategory] = useState<
    ISubCategory[]
  >([]);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const rawCategory = watch("category");

  const onSubmit = (listingFormData: IFormFields) => {
    if (!(newListingImages.length > 0)) {
      setShowImageError(true);
      return;
    }

    if (!ownListingById) {
      return;
    }

    updateListing(
      {
        id: ownListingById.id,
        ...listingFormData,
        // category: JSON.parse(listingFormData.category).apiName,
        // subCategory: listingFormData.subCategory
        //   ? JSON.parse(listingFormData.subCategory).apiName
        //   : undefined,
      },
      (draftListingId: string) => {
        navigate(`/listingpreview/${draftListingId}`);
      }
    );
  };

  // Fetch the current listing on load.
  useEffect(() => {
    if (id) {
      getOwnListingById(id);
    }
  }, [id, getOwnListingById]);

  // Keep errors up to date.
  useEffect(() => {
    if (newListingImages.length > 0) {
      setShowImageError(false);
    }
  }, [newListingImages]);

  // Set all the values of the form from the loaded in data once the data loads in..
  useEffect(() => {
    if (ownListingById) {
      setValue("title", ownListingById.attributes.title);
      setValue("description", ownListingById.attributes.description);
      setValue("price", ownListingById.attributes.price.amount / 100);
      setValue("condition", ownListingById.attributes.publicData.condition);
      setValue("postage", ownListingById.attributes.publicData.postage);
      setValue("location", ownListingById.attributes.publicData.location);
    }

    if (ownListingById?.attributes.publicData.category) {
      const category = getCategoryByApiName(
        ownListingById?.attributes.publicData.category
      );

      setSubCategoriesForCategory(category.subCategories ?? []);
      setValue("category", JSON.stringify(category));
    }

    if (ownListingById?.attributes.publicData.subCategory) {
      const subCategory = getSubCategoryByApiName(
        ownListingById?.attributes.publicData.category,
        ownListingById?.attributes.publicData.subCategory
      );

      setValue("subCategory", JSON.stringify(subCategory));
    }
  }, [ownListingById]);

  // When the categorie changes change the available subCategories the user can choose from.
  useEffect(() => {
    // Don't need to do this immediatly because we already have one.
    if (rawCategory && subCategoriesForCategory.length > 0) {
      const parsedCat: ICategory = JSON.parse(rawCategory as any as string);
      setSubCategoriesForCategory(parsedCat.subCategories ?? []);
    }
  }, [rawCategory]);

  if (!ownListingById) {
    return <div>Loading...</div>;
  }

  const price = watch("price");
  const showPriceWarning = price === 0;

  return (
    <CreateListingWrapper>
      <ListItemHero text={"Edit your listing"} />
      <LayoutBodyFullwidth>
        <LayoutBody>
          <CreateListingMainContent onSubmit={handleSubmit(onSubmit)}>
            <ImagePicker existingListing={ownListingById} />
            <SellDetails
              showPriceWarning={showPriceWarning}
              existingListing={ownListingById}
              subCategories={subCategoriesForCategory}
              register={register}
            />
            {(Object.keys(errors).length > 0 || showImageError) && (
              <ErrorList>
                {Object.keys(errors).map((key) => (
                  <li key={key}> {errors[key].message}</li>
                ))}
                {showImageError && (
                  <li>Please upload at least one image for this listing.</li>
                )}
              </ErrorList>
            )}
            <SellConfirmArea
              handleSubmit={handleSubmit}
              createDraftListing={onSubmit}
            />
          </CreateListingMainContent>
        </LayoutBody>
      </LayoutBodyFullwidth>
    </CreateListingWrapper>
  );
};
