import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { H1 } from "../components/Header";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { SectionHeading } from "../components/SectionHeading";
import { SelectInputClass } from "../components/SelectInput";
import { SuccessText } from "../components/SuccessText";
import { COLORS } from "../constants/colors";

const ContactUsHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
  background-color: #f27442;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  margin-bottom: 90px;

  ${up("lg")} {
    height: 250px;
    margin-bottom: 80px;
    background-image: url("/assets/images/header-image.png");
  }
`;

const StyledH1 = styled.h1`
  color: #304669;
  font-size: 40px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

export const ContactUs = () => {
  return (
    <>
      <div>
        <ContactUsHero>
          <StyledH1>Report a user</StyledH1>
        </ContactUsHero>
        <LayoutBodyFullwidth>
          <LayoutBody>
            <Body />
          </LayoutBody>
        </LayoutBodyFullwidth>
      </div>
    </>
  );
};

// const POST_URL = "https://hooks.zapier.com/hooks/catch/12265884/bkv8rk5/";
const POST_URL =
  "https://7kv7znnkx8.execute-api.ap-southeast-2.amazonaws.com/prod/report-user";

const Body = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    fetch(POST_URL, {
      method: "POST",
      body: JSON.stringify({ complaint: data }),
    }).then(() => {
      setIsSubmitted(true);
    });
  };
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <BodyWrapper>
      <SectionHeading
        titleColor={COLORS.DARK_ORANGE}
        title="Get more help & support"
      >
        <></>
      </SectionHeading>
      <SubHeadingButtons>
        <FormButton2>
          <Button
            onClick={() => {
              navigate("/contactus");
            }}
            buttonType="button"
            type="primary"
            text="CONTACT US"
          />
        </FormButton2>
        <FormButton2>
          <Button
            onClick={() => {
              navigate("/faq");
            }}
            buttonType="button"
            type="secondary"
            text="FAQ"
          />
        </FormButton2>
      </SubHeadingButtons>
      <Delimiter />
      <DisclaimerText>
        We will endeavour to respond to you within 24 hours. For urgent
        enquiries, you can call on{" "}
        <a href="tel:+64276427119">+64 27 642 7119</a> .
      </DisclaimerText>
      {isSubmitted && (
        <div style={{ marginBottom: "60px" }}>
          <SuccessText
            title="You have reported a user"
            description="Thanks for helping to make our community stronger. We will investigate and try to get back to you within 24 hours."
          />
        </div>
      )}
      {!isSubmitted && (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <FormColumn style={{ width: "100%" }}>
              <label>Your registered email address</label>
              <TextInput
                width="100%"
                type="email"
                placeholder="email@email.com"
                {...register("email", { required: true })}
              />
            </FormColumn>
          </FormRow>
          <FormRow style={{ width: "100%", justifyContent: "space-between" }}>
            <FormColumn style={{ width: "100%" }}>
              <label>First name</label>
              <TextInput
                placeholder="Name"
                {...register("firstName", { required: true })}
              />
            </FormColumn>
            <FormColumn style={{ width: "100%" }}>
              <label>Surname</label>
              <TextInput
                placeholder="Surname"
                {...register("lastName", { required: true })}
              />
            </FormColumn>
          </FormRow>
          <FormRow style={{ width: "100%", justifyContent: "space-between" }}>
            <FormColumn style={{ width: "100%" }}>
              <FormRow style={{ width: "100%" }}>
                <FormColumn style={{ width: "100%" }}>
                  <label>Your username</label>
                  <TextInput
                    style={{ width: "100%" }}
                    placeholder="Username"
                    {...register("username", { required: true })}
                  />
                </FormColumn>
              </FormRow>
              <FormRow style={{ width: "100%" }}>
                <FormColumn style={{ width: "100%" }}>
                  <label>Username you're reporting</label>
                  <TextInput
                    style={{ width: "100%" }}
                    placeholder="Username"
                    {...register("reportedUsername", { required: true })}
                  />
                </FormColumn>
              </FormRow>
              <FormRow style={{ width: "100%" }}>
                <FormColumn style={{ width: "100%" }}>
                  <label>Why are you reporting this user?</label>

                  <SelectInputClass
                    {...register("type", { required: true })}
                    defaultValue={""}
                  >
                    <option disabled value="">
                      Select type
                    </option>

                    <option value="InappropriateMessages">
                      Inappropriate messages
                    </option>
                    <option value="NotCompletingSale">
                      Not completing sale
                    </option>
                    <option value="InnapropriateLanguageInListing">
                      Innapropriate language in listing.
                    </option>
                  </SelectInputClass>
                </FormColumn>
              </FormRow>
            </FormColumn>
            <FormColumn style={{ width: "100%" }}>
              <label>Describe the issue</label>
              <TextArea
                placeholder="Description"
                {...register("description", { required: true })}
              />
            </FormColumn>
          </FormRow>
          <FormButton>
            <Button buttonType="submit" type="primary" text="SEND EMAIL" />
          </FormButton>
        </FormWrapper>
      )}
    </BodyWrapper>
  );
};
const BodyWrapper = styled.div`
  margin-top: -40px;

  ${up("lg")} {
    margin-top: 0px;
  }
`;

const SubHeadingButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 190px;
    font-size: 14px;
  }

  ${up("lg")} {
    display: flex;
    flex-direction: row;
    align-items: none;
  }
`;
const Delimiter = styled.div`
  width: 80%;
  margin-left: 30px;
  height: 1px;
  margin-bottom: 56px;
  background-color: ${COLORS.NAVY_BLUE};

  ${up("lg")} {
    width: 100%;
    margin-left: 0px;
  }
`;

const DisclaimerText = styled.p`
  text-align: center;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 34px;
  padding-left: 20px;
  padding-right: 20px;

  ${up("lg")} {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  label {
    font-size: 20px;
    line-height: 40px;
    font-weight: bold;
    color: ${COLORS.NAVY_BLUE};
  }
  ${up("lg")} {
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const FormRow = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: center;

  ${up("lg")} {
    display: flex;
    flex-direction: row;
    align-items: none;
    gap: 40px;
  }
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  ${up("lg")} {
    align-items: none;
  }
`;

const TextInput = styled.input`
  height: 50px;
  padding-left: 20px;
  padding-top: 17px;
  padding-bottom: 17px;
  border: solid 1px ${COLORS.LIGHT_CHARCOAL_GREY};
  ::placeholder {
    color: ${COLORS.NAVY_BLUE};
    font-size: 14px;
  }
`;
const TextArea = styled.textarea`
  max-width: 776px;
  height: 290px;
  padding-left: 20px;
  padding-top: 17px;
  padding-bottom: 17px;
  margin-bottom: 30px;
  border: solid 1px ${COLORS.LIGHT_CHARCOAL_GREY};
  ::placeholder {
    color: ${COLORS.NAVY_BLUE};
    font-size: 14px;
  }
`;
const FormButton = styled.div`
  max-width: 190px;
  font-size: 12px;
  margin-left: 70px;
  margin-bottom: 56px;

  button {
    font-size: 12px;
  }

  ${up("lg")} {
    margin-right: 12px;
    margin-left: 0px;
  }
`;

const FormButton2 = styled.div`
  max-width: 190px;
  font-size: 12px;
  margin-left: 0px;
  margin-bottom: 20px;

  button {
    font-size: 12px;
  }

  ${up("lg")} {
    margin-right: 12px;
    margin-left: 0px;
    margin-bottom: 56px;
  }
`;

export default ContactUs;
