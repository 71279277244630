import { Button } from "../components/Button";
import { H1, H2, H3, H4 } from "../components/Header";
import { ListingTilePreviewContainer } from "../components/ListingTilePreviewContainer";
// import { MockListing } from "../hooks/useListing";

export const Stylesheet = () => {
  return (
    <div>
      <h1>Stylesheet</h1>
      <H1>H1</H1>
      <H2>H2</H2>
      <H3>H3</H3>
      <H4>H4</H4>

      <Button
        buttonType="button"
        onClick={() => alert("Click")}
        text="Primary"
        type="primary"
      />
      <Button
        buttonType="button"
        onClick={() => alert("Click")}
        text="Secondary"
        type="secondary"
      />
      <Button
        buttonType="button"
        onClick={() => alert("Click")}
        text="Tertiary"
        type="tertiary"
      />

      <ListingTilePreviewContainer>
        {/* <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} />
        <ListingTilePreview listing={MockListing()} /> */}
      </ListingTilePreviewContainer>
    </div>
  );
};
