import { IApiTransaction, MY_ROLE, TRANSITIONS } from "../hooks/useTransaction";

// Checks last state of a transaction to see if its too early or late for the current party to still provide feedback
export const canIGiveFeedbackOnApiTransaction = (
  apiTransaction: IApiTransaction,
  myRole: MY_ROLE
): {
  canGiveFeedback: boolean;
  nextFeedbackTransition: TRANSITIONS | undefined;
} => {
  const lastTransition = apiTransaction.attributes.lastTransition;

  let canGiveFeedback = false;
  let nextFeedbackTransition = undefined;
  switch (myRole) {
    case MY_ROLE.CUSTOMER:
      canGiveFeedback = [
        TRANSITIONS.ACCEPT_STOCK,
        TRANSITIONS.REVIEW_ONE_BY_PROVIDER,
      ].includes(lastTransition);
      break;
    case MY_ROLE.PROVIDER:
      canGiveFeedback = [
        TRANSITIONS.ACCEPT_STOCK,
        TRANSITIONS.REVIEW_ONE_BY_CUSTOMER,
      ].includes(lastTransition);
      break;

    default:
      break;
  }

  if (lastTransition === TRANSITIONS.ACCEPT_STOCK) {
    // No one has given review yet
    if (myRole === MY_ROLE.CUSTOMER) {
      nextFeedbackTransition = TRANSITIONS.REVIEW_ONE_BY_CUSTOMER;
    } else {
      nextFeedbackTransition = TRANSITIONS.REVIEW_ONE_BY_PROVIDER;
    }
  } else if (lastTransition === TRANSITIONS.REVIEW_ONE_BY_PROVIDER) {
    // Provider has
    if (myRole === MY_ROLE.CUSTOMER) {
      nextFeedbackTransition = TRANSITIONS.REVIEW_TWO_BY_CUSTOMER;
    }
  } else if (lastTransition === TRANSITIONS.REVIEW_ONE_BY_CUSTOMER) {
    // Customer has
    if (myRole === MY_ROLE.PROVIDER) {
      nextFeedbackTransition = TRANSITIONS.REVIEW_TWO_BY_PROVIDER;
    }
  }
  // All other states are not ones in which we are allowed to give further feedback.

  return { canGiveFeedback, nextFeedbackTransition };
};
