export const defaultTheme = {
  breakpoints: {
    xs: "375px",
    sm: "480px",
    md: "768px",
    lg: "960px",
    xl: "1024px",
    xxl: "1200px",
    xxxl: "1440px",
  },
};
