import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Ad } from "../components/Ad";
import { ErrorList } from "../components/ErrorList";
import { ImagePicker } from "../components/ImagePicker";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { SellConfirmArea } from "../components/SellConfirmArea";
import { SellDetails } from "../components/SellDetails";
import { ListItemHero } from "../components/SellPageHero";
import { ICategory, ISubCategory } from "../constants/categories";
import { CONDITIONS } from "../constants/conditions";
import { POSTAGE } from "../constants/postage";
import { useListing } from "../hooks/useListing";

interface IFormFields {
  title: string;
  description: string;
  price: number;
  category: any;
  subCategory: any;
  condition: CONDITIONS;
  postage: POSTAGE;
  location: string;
  images: string[];
}

export const CreateListing = () => {
  const { createListing, newListingImages, removeAllImages } = useListing();
  const isDraft = true;
  const navigate = useNavigate();
  const [showImageError, setShowImageError] = useState(false);
  const [subCategoriesForCategory, setSubCategoriesForCategory] = useState<
    ISubCategory[]
  >([]);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const rawCategory = watch("category");

  const createDraftListing = (listing: IFormFields) => {
    if (!(newListingImages.length > 0)) {
      setShowImageError(true);
      return;
    }
    createListing(
      {
        ...listing,
      },
      (draftListingId: string) => {
        navigate(`/listingpreview/${draftListingId}`);
      }
    );
  };

  useEffect(() => {
    return () => {
      setValue("title", undefined);
      setValue("description", undefined);
      setValue("price", undefined);
      setValue("category", undefined);
      setValue("subCategory", undefined);
      setValue("condition", undefined);
      setValue("postage", undefined);
    };
  }, []);

  useEffect(() => {
    if (newListingImages.length > 0) {
      setShowImageError(false);
    }
  }, [newListingImages]);

  useEffect(() => {
    if (rawCategory) {
      const parsedCat: ICategory = JSON.parse(rawCategory as any as string);
      setSubCategoriesForCategory(parsedCat.subCategories ?? []);
    }
  }, [rawCategory]);

  const price = watch("price");
  const showPriceWarning = price === 0;

  return (
    <CreateListingWrapper>
      <ListItemHero text={!isDraft ? "Edit your listing" : "List your item"} />
      <LayoutBodyFullwidth>
        <LayoutBody>
          <CreateListingMainContent onSubmit={handleSubmit(createDraftListing)}>
            <ImagePicker />
            <SellDetails
              showPriceWarning={showPriceWarning}
              subCategories={subCategoriesForCategory}
              register={register}
            />
            {(Object.keys(errors).length > 0 || showImageError) && (
              <ErrorList>
                {Object.keys(errors).map((key) => (
                  <li key={key}> {errors[key].message}</li>
                ))}
                {showImageError && (
                  <li>Please upload at least one image for this listing.</li>
                )}
              </ErrorList>
            )}
            <SellConfirmArea
              handleSubmit={handleSubmit}
              createDraftListing={createDraftListing}
            />
          </CreateListingMainContent>
        </LayoutBody>
      </LayoutBodyFullwidth>
    </CreateListingWrapper>
  );
};

export const CreateListingWrapper = styled.div`
  margin-bottom: 0px;
`;

export const CreateListingMainContent = styled.form`
  margin-top: 60px;
  margin-top: 60px;
  ${up("md")} {
    margin-left: 120px;
    margin-right: 120px;
  }
`;
