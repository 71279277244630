import { IListing } from "../hooks/useListing";
import { formatRelative, differenceInDays, format } from "date-fns";
import { enNZ } from "date-fns/locale";

export const getListingDateAsStringFromListing = (listing: IListing) => {
  if (!listing?.attributes.createdAt) {
    return "FAULT";
  }
  const listDate = new Date(listing.attributes.createdAt);
  // calculate If the listing was created today or yesterday

  const relativeString = formatRelative(listDate, new Date(), {
    locale: enNZ,
  });
  const listedTodayOrYesterday =
    relativeString.includes("today") || relativeString.includes("yesterday");
  // Include only first two words of relative string
  const relativeStringArray = relativeString.split(" ");
  const relativeStringArrayLength = relativeStringArray.length;

  // If the listing was created today or yesterday only return the first word of the string
  if (listedTodayOrYesterday) {
    return relativeStringArray[0];
  }
  // If the listing was created more than 2 days ago return the first two words of the string
  if (relativeStringArrayLength >= 2) {
    return `${relativeStringArray[0]} ${relativeStringArray[1]}`;
  }

  return relativeStringArray[0];
};

export const getDateAsRelativeString = (listDate: Date) => {
  // calculate If the listing was created today or yesterday
  const listedTodayOrYesterday = differenceInDays(new Date(), listDate) < 2;

  const relativeString = formatRelative(listDate, new Date(), {
    locale: enNZ,
  });
  // Include only first two words of relative string
  const relativeStringArray = relativeString.split(" ");
  const relativeStringArrayLength = relativeStringArray.length;

  // If the listing was created today or yesterday only return the first word of the string
  if (listedTodayOrYesterday) {
    return relativeStringArray[0];
  }
  // If the listing was created more than 2 days ago return the first two words of the string
  if (relativeStringArrayLength > 2) {
    return `${relativeStringArray[0]} ${relativeStringArray[1]}`;
  }

  return relativeStringArray[0];
};

export const formatDateForProfile = (date: Date) => {
  return format(date, "MMMM yyyy", { locale: enNZ });
};
