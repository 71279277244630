import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
interface IImageCarouselProps {
  imageUrls: string[];
}

export const ImageCarousel = (props: IImageCarouselProps) => {
  return (
    // @ts-ignore
    <Carousel thumbWidth={"33%"} dynamicHeight={true}>
      {props.imageUrls.map((imageUrl: string) => (
        <div key={imageUrl}>
          <img src={imageUrl} alt="carousel" />
        </div>
      ))}
    </Carousel>
  );
};
