import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";

const ChangeEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 120px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  ${up("lg")} {
    margin-top: 80px;
  }
`;

const ChangeEmailForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fbf0eb;
  width: 332px;
  height: 435px;
  padding-top: 60px;
  margin-top: 40px;

  ${up("lg")} {
    width: 732px;
    height: 435px;
  }
`;

const ChangeEmailLabelClass = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #304669;
  font-weight: bold;
  text-align: center;
  margin-left: 0px;

  ${up("lg")} {
    margin-left: 110px;
    text-align: left;
  }
`;

const ChangeEmailInputClass = styled.input`
  width: 254px;
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 10px;
  align-self: center;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }

  ${up("lg")} {
    width: 508px;
  }
`;

const ChangeEmailButton = styled.button`
  align-self: center;
  margin-top: 40px;
  margin-bottom: 60px;
  font-size: 14px;
  font-weight: bold;
  width: 190px;
  height: 63px;
  background-color: #304669;
  border-style: solid;
  border-radius: 3px;
  border-color: #304669;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #304669;
    color: #304669;
    transition-duration: 500ms;
  }

  ${up("lg")} {
    margin-bottom: 0px;
  }
`;

const ChangeEmailSubText = styled.div`
  margin-top: 20px;
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 36px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

const StyledH4 = styled.h4`
  color: #304669;
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;

  ${up("lg")} {
    font-size: 30px;
    line-height: 40px;
  }
`;

// PAGE LAYOUT EXPORT

export const ChangeEmail = () => {
  return (
    <ChangeEmailWrapper>
      <ChangeEmailHeroWrap />
      <LayoutBodyFullwidth>
        <ChangeEmailFormWrap />
      </LayoutBodyFullwidth>
    </ChangeEmailWrapper>
  );
};

const ChangeEmailHeroWrap = () => {
  return (
    <div>
      <StyledH1>Change your email</StyledH1>
      <ChangeEmailSubText>
        <StyledH4>
          Change your email below address below. This is the <br></br>
          email where all communication will be sent <br></br> relating to
          anything you buy or sell.
        </StyledH4>
      </ChangeEmailSubText>
    </div>
  );
};

const ChangeEmailFormWrap = () => {
  return (
    <ChangeEmailForm>
      <ChangeEmailLabelClass>Current email address </ChangeEmailLabelClass>
      <ChangeEmailInputClass placeholder="email@email" />
      <ChangeEmailLabelClass>New email address</ChangeEmailLabelClass>
      <ChangeEmailInputClass placeholder="email@email" />
      <ChangeEmailLabelClass>
        Type your new email address again
      </ChangeEmailLabelClass>
      <ChangeEmailInputClass placeholder="email@email" />
      <ChangeEmailButton type="submit">CHANGE EMAIL</ChangeEmailButton>
    </ChangeEmailForm>
  );
};

export default ChangeEmail;
