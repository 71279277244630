import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { H1 } from "../components/Header";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { SectionHeading } from "../components/SectionHeading";
import { COLORS } from "../constants/colors";
import { useListing } from "../hooks/useListing";
import { ListingByIdWithDetails } from "./Listing";

const ListingPreviewHero = styled.div`
  /* background-color: ${COLORS.DARK_ORANGE}; */
  height: 150px;
  margin-bottom: 60px;
  color: ${COLORS.NAVY_BLUE} !important;

  ${up("md")} {
    h1 {
      color: ${COLORS.NAVY_BLUE} !important;
    }
    /* background-image: url("/assets/images/header-image.png"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    margin-bottom: 100px;
  }

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListingPreviewHeroText = styled.div``;

const ButtonRow = styled.div`
  display: flex;
  ${up("md")} {
    button {
      max-width: 288px;
      margin-right: 64px;
    }
  }
  margin-bottom: 141px;
`;

const Delimiter = styled.div`
  height: 1px;
  background-color: ${COLORS.NAVY_BLUE};
  width: 100%;
  margin-top: 100px;
  margin-bottom: 54px;
`;

// const getOwnListingById = (listings: IListing[], id: string) => {
//   return listings.find((listing) => listing.id.uuid === id);
// };

export const ListingPreview = () => {
  // Get the draft listing id from url params
  const { id } = useParams();
  const { getOwnListingById, ownListingById, publishDraftListing } =
    useListing();
  const loading = !ownListingById;

  const navigate = useNavigate();

  useEffect(() => {
    getOwnListingById(id ?? "");
  }, [getOwnListingById, id]);

  const publishPressed = () => {
    if (id && ownListingById) {
      publishDraftListing(id, () => {
        navigate("/listingliveconfirmed/" + id);
      });
    }
  };

  if (!id) {
    return <div>Something went wrong. Please try again. </div>;
  }

  if (loading) {
    // TODO replace with a prettier loading screen.
    return <div>Loading...</div>;
  }

  return (
    <LayoutBodyFullwidth>
      <ListingPreviewHero>
        <ListingPreviewHeroText>
          <H1
            style={{
              color: "#304669",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Listing preview
          </H1>
        </ListingPreviewHeroText>
      </ListingPreviewHero>
      <LayoutBody>
        <ListingByIdWithDetails
          showAuthor={false}
          hideMoreButtons
          listing={ownListingById}
        />
        <Delimiter />
        <SectionHeading
          icon={<></>}
          titleColor={"#353B3C"}
          title="Confirm listing"
          subTitle={
            <p
              style={{
                color: COLORS.NAVY_BLUE,
                fontSize: "16px",
                lineHeight: "20px",
                paddingTop: "14px",
              }}
            >
              If you're happy with everything above, click the Publish button
              below and your listing will be live! If you want to make changes
              first, click the Go Back button.
            </p>
          }
        >
          <></>
        </SectionHeading>
        <ButtonRow>
          <Button
            buttonType="button"
            onClick={() => {
              navigate("/edit-listing/" + id);
            }}
            type="secondary"
            text="Go back"
          />
          <Button
            buttonType="button"
            type="primary"
            text="Publish Listing"
            onClick={publishPressed}
          />
        </ButtonRow>
      </LayoutBody>
    </LayoutBodyFullwidth>
  );
};

const ConfirmIcon = () => (
  <img width={35} src="/assets/images/foldercheck.png" />
);

export default ListingPreview;
