import { useState } from "react";
import { useForm } from "react-hook-form";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";
import { COLORS } from "../constants/colors";
import { useAuth } from "../hooks/useAuth";

const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 120px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const ChangePasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 332px;
  height: 300px;
  padding-top: 60px;
  margin-top: 40px;

  ${up("lg")} {
    width: 732px;
  }
`;

const ChangePasswordLabelClass = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #304669;
  font-weight: bold;
  text-align: center;
  margin-left: 0px;

  ${up("lg")} {
    text-align: left;
    margin-left: 110px;
  }
`;

const ChangePasswordInputClass = styled.input`
  width: 254px;
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  align-self: center;
  padding-left: 10px;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }

  ${up("lg")} {
    width: 508px;
  }
`;

const ChangePasswordButton = styled.button`
  align-self: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: 40px;
  width: 190px;
  height: 63px;
  background-color: #353b3c;
  border-style: solid;
  border-radius: 3px;
  border-color: #353b3c;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #353b3c;
    color: #353b3c;
    transition-duration: 500ms;
  }
`;

const ChangePasswordSubText = styled.div`
  margin-top: 20px;
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 36px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

const StyledH4 = styled.h4`
  color: #304669;
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;

  ${up("lg")} {
    font-size: 30px;
    line-height: 40px;
  }
`;

// PAGE LAYOUT EXPORT

export const ForgotPassword = () => {
  return (
    <ChangePasswordWrapper>
      {/* <ChangePasswordHeroWrap /> */}
      <LayoutBodyFullwidth>
        <ChangePasswordFormWrap />
      </LayoutBodyFullwidth>
    </ChangePasswordWrapper>
  );
};

const ChangePasswordHeroWrap = () => {
  return (
    <div>
      <StyledH1>Change your password</StyledH1>
      <ChangePasswordSubText>
        <StyledH4>Change your password below.</StyledH4>
      </ChangePasswordSubText>
    </div>
  );
};

const ChangePasswordFormWrap = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { requestPasswordReset } = useAuth();
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data: any) => {
    await requestPasswordReset(data.email, () => {
      setHasSubmitted(true);
    });
  };
  return (
    <div>
      <ChangePasswordForm onSubmit={handleSubmit(onSubmit)}>
        {hasSubmitted && (
          <>
            <p style={{ color: COLORS.NAVY_BLUE, fontSize: "20px" }}>
              Reset request submitted. Please check your emails for a reset
              link.
            </p>
          </>
        )}
        {!hasSubmitted && (
          <>
            <ChangePasswordLabelClass>Email</ChangePasswordLabelClass>
            <ChangePasswordInputClass
              {...register("email", { required: "Enter email" })}
              type="email"
              placeholder="Email"
            />
            <ChangePasswordButton type="submit">
              Request password reset
            </ChangePasswordButton>
          </>
        )}
      </ChangePasswordForm>
    </div>
  );
};
