export enum ALL_LOCATIONS {
  WellingtonCity = "Wellington City",
  LowerHutt = "Lower Hutt",
  UpperHutt = "Upper Hutt",
  Porirua = "Porirua",
  KapitiCoast = "Kapiti Coast",
  Wairarapa = "Wairarapa",
  RestOfNorthIsland = "Rest of North Island",
  SouthIsland = "South Island",
  Other = "Other",
}
