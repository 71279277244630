import { IImage } from "../hooks/useImage";
import { IListing } from "../hooks/useListing";

export const getImageURLByListing = (
  listing: IListing,
  listingImagesMap: any
) =>
  listingImagesMap[listing?.relationships?.images?.data[0].id.uuid]?.attributes
    .variants?.default?.url;

export const getImageURLsByListing = (
  listing: IListing,
  listingImagesMap: any
) =>
  listing?.relationships?.images?.data.map((image: IImage) => {
    return listingImagesMap[image.id.uuid]?.attributes.variants?.default?.url;
  });

export const getAllImageObjectsFromListingObject = (
  listing: IListing
): IImage[] => {
  return listing?.relationships?.images?.data;
};

export const getAllImageIdsFromListingObject = (
  listing: IListing
): string[] => {
  try {
    return listing?.relationships?.images?.data.map(
      (image: IImage) => image.id.uuid
    );
  } catch (error) {
    return [];
  }
};
