import { ALL_CATEGORIES, ICategory } from "../constants/categories";

// Find the full ICategory including subcategories for the enum Category given by the listing
export const getCategoryByApiName = (apiName: string) => {
  const name = ALL_CATEGORIES.find((c) => c.apiName === apiName)?.name ?? "";
  const subCategories =
    ALL_CATEGORIES.find((c) => c.apiName === apiName)?.subCategories ?? [];

  const category: ICategory = {
    name,
    apiName,
    subCategories,
  };

  return category;
};

export const getSubCategoryByApiName = (
  categoryApiName: string,
  subCategoryApiName: string
) => {
  const category = ALL_CATEGORIES.find((c) => c.apiName === categoryApiName);
  const subCategory = category?.subCategories?.find(
    (c) => c.apiName === subCategoryApiName
  );

  return subCategory;
};
