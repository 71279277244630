import styled from "styled-components";
import { BaseButtonStyle } from "./Button";
import {
  getButtonBorderColor,
  getButtonBackgroundColor,
  getButtonColor,
  getButtonHoveredBackgroundColor,
  getButtonHoveredColor,
} from "../utils/buttonColors";
import { Link } from "react-router-dom";

export const LinkButton = styled(Link)<{ type: any }>`
  ${BaseButtonStyle}
  border-color: ${(props: any) => getButtonBorderColor(props.type)};
  color: ${(props: any) => getButtonColor(props.type)} !important;
  background-color: ${(props: any) => getButtonBackgroundColor(props.type)};
  :hover {
    background-color: ${(props: any) =>
      getButtonHoveredBackgroundColor(props.type)};
    color: ${(props: any) => getButtonHoveredColor(props.type)} !important;
  }
`;
