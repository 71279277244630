import { useNavigate, useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { SuccessText } from "../components/SuccessText";

const HeartLogo = styled.img`
  width: 89px;
  height: 89px;
  ${up("lg")} {
    width: 119px;
    height: 119px;
  }
`;

const ListingConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 120px;
`;

const ListingButtons = styled.div`
  width: 300px;
  justify-content: space-between;
`;

const ButtonItem = styled.div`
  margin-top: 10px;
`;

export const ListingLiveConfirmed = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  return (
    <ListingConfirmationWrapper>
      <SuccessText
        title="Congratulations!"
        description="Your listing is now live."
      />
      <ListingButtons>
        <ButtonItem>
          <Button
            buttonType="button"
            onClick={() => {
              navigate(`/listing/${id}`);
            }}
            type="bluebg"
            text="VIEW MY LISTING"
          />
        </ButtonItem>
        <ButtonItem>
          <Button
            buttonType="button"
            onClick={() => {
              navigate("/sell");
            }}
            type="blueborder"
            text="LIST SOMETHING ELSE"
          />
        </ButtonItem>
        <ButtonItem>
          <Button
            buttonType="button"
            onClick={() => {
              navigate("/myaccount");
            }}
            type="blueborder"
            text="GO TO MY ACCOUNT"
          />
        </ButtonItem>
      </ListingButtons>
    </ListingConfirmationWrapper>
  );
};

export default ListingLiveConfirmed;
