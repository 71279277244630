import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";

const FaqHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbf0eb;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 260px;
  margin-bottom: 20px;

  ${up("lg")} {
    height: 363px;
  }
`;

const FaqHeroContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  ${up("lg")} {
    width: 60%;
  }
`;

const HeroLeft = styled.img`
  display: none;
  ${up("lg")} {
    display: flex;
    width: 145px;
  }
`;

const HeroRight = styled.div`
  text-align: left;
  margin-left: 20px;
`;

const SubHeroText = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const FaqPageContent = styled.div`
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;

  ${up("lg")} {
    width: 80%;
  }
`;

const FaqPageText = styled.div`
  text-align: left;
  max-width: 80%;
`;

const StyledH1 = styled.h1`
  color: #304669;
  font-size: 40px;
  font-weight: bold;
`;

const StyledH3 = styled.h3`
  color: #304669;
  font-size: 20px;
  font-weight: normal;
`;

const StyledH2 = styled.h2`
  color: #304669;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 40px;
`;

const StyledParagraph = styled.p`
  color: #304669;
  margin-top: 5px;
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
`;

// PAGE LAYOUT EXPORT

export const Faq = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <LayoutBodyFullwidth>
        <FaqContentWrap />
      </LayoutBodyFullwidth>
    </div>
  );
};

const FaqContentWrap = () => {
  return (
    <FaqPageContent>
      FAQ Coming soon
      {/* <HeadingBar
        type="blue"
        text="Hello world"
        subText="This is some heading subtext"
        button="blue"
        buttonText="hello"
      />
      <HeadingBar
        type="orange"
        text="Hello world"
        subText="This is some heading subtext"
        button="orange"
        buttonText="hello"
      /> */}
      {/* <FaqPageText>
        <StyledH2>
          How is belly button different from other marketplaces?
        </StyledH2>
        <StyledParagraph>
          We are not the big players, we are a social enterprise that helps
          connect people experiencing that joy of raising a family. bellybutton
          allows you to buy, sell and gift items that you are looking for or no
          longer need. We connect you with relevant service providers as well as
          others in your area going through the same experiences as you are.
        </StyledParagraph>
        <StyledH2>Why are items a fixed price and not an auction?</StyledH2>
        <StyledParagraph>
          At bellybutton, we just think that it is easier. Set your price and if
          somebody wants it, they'll make you an offer.
        </StyledParagraph>
        <StyledH2>How do I complete a buy or sell transaction?</StyledH2>
        <StyledParagraph>
          bellybutton is not currently utilising a payment gateway. If a buyer
          and seller agree to a transaction, you can share your details and
          arrange payment and shipping or collection.
        </StyledParagraph>
        <StyledH2>
          How do I contact the seller if I have a question before buying?
        </StyledH2>
        <StyledParagraph>
          In each listing there is a place to ask questions. You must login to
          ask a seller questions.
        </StyledParagraph>
        <StyledH2>
          I have requested to buy something but have not heard back?
        </StyledH2>
        <StyledParagraph>
          Try reaching out through the email messaging system. We all sometimes
          miss things, emails could have gone to junk, it's ok to give a
          friendly nudge. Please don't hesitate to{" "}
          <Link
            style={{
              color: "#304669",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            to={"/contactus"}
          >
            get in touch
          </Link>{" "}
          with us if you haven't had any luck.
        </StyledParagraph>
        <StyledH2>
          How do I send or recieve a product that I have sold or brought?
        </StyledH2>
        <StyledParagraph>
          Buyers and sellers will agree to the exchange of goods. NZ Post,
          courier services or arrange for a pickup, maybe over a coffee.
        </StyledParagraph>
        <StyledH2>I haven't received an item, what should I do?</StyledH2>
        <StyledParagraph>
          Sometimes things take a bit longer than expected. If it was sent via
          courier you can try the track and trace number with the courier
          company. You can always contact the seller and check the status of
          your order as well. If needed, we can also talk to the seller if you
          are having trouble.
        </StyledParagraph>
        <StyledH2>Is it safe to trade during Covid?</StyledH2>
        <StyledParagraph>
          We'd ask you to be mindful and protect yourself as much as possible.
          Be smart and be thorough.
        </StyledParagraph>
        <StyledH2>
          How much does it cost to buy or sell through bellybutton? How much
          commission do you take?
        </StyledH2>
        <StyledParagraph>
          Buying or selling on bellybutton is currently free. You can create
          your account{" "}
          <Link
            style={{
              color: "#304669",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            to={"/sellregister"}
          >
            here
          </Link>
          . Any changes to this free fee structure will be announced to users
          before they happen.
        </StyledParagraph>
        <StyledH2>Will there be more items on bellybutton?</StyledH2>
        <StyledParagraph>
          We've only just launched and like all new things, with enough support
          we'll grow. You can help spread the word, it's the best way to get a
          bigger variety of items.
        </StyledParagraph>
        <StyledH2>How do I find out if there are items near me?</StyledH2>
        <StyledParagraph>
          Simply filter on the location tab in search tab and you'll see
          everything that is listed in your general area.
        </StyledParagraph>
        <StyledH2>How do I search for specific items such as buggies?</StyledH2>
        <StyledParagraph>
          To make it easier, we've listed a few of the most searched for
          categories already. If we haven't got it listed there, you can refine
          your search using the search filters.
        </StyledParagraph>
        <StyledH2>Why isn't my listing selling?</StyledH2>
        <StyledParagraph>
          Are your photos clear? Is your price attractive? <br></br>
          We can try and help find out what is holding you back -{" "}
          <Link
            style={{
              color: "#304669",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            to={"/contactus"}
          >
            get in touch
          </Link>{" "}
          and we'll get back to you.
        </StyledParagraph>
        <StyledH2>Can I edit a listing?</StyledH2>
        <StyledParagraph>
          You can always edit your listings - go to My Listings while logged in.
          From there you can make the changes that you need to make to your
          listing.
        </StyledParagraph>
        <StyledH2>My item sold elsewhere. How do I close a listing?</StyledH2>
        <StyledParagraph>
          Go to my listings while logged in. From there you can withdraw your
          listings. Once you withdraw a listing it will be permanently deleted.
        </StyledParagraph>
        <StyledH2>
          I want something that is for free but I can't collect?
        </StyledH2>
        <StyledParagraph>
          Ask the seller if you can book a courier to collect.
        </StyledParagraph>
        <StyledH2>Can I send my purchase back if I am not happy?</StyledH2>
        <StyledParagraph>
          You need to check with the seller to see if this is something that
          they will accept. It is up to the seller and the buyer to agree to
          this and what the conditions or terms are.
        </StyledParagraph>
        <StyledH2>
          I received an item that doesn't match the listing description/photos.
          What should I do?
        </StyledH2>
        <StyledParagraph>
          If you received an item that is wrong, damaged or not as described,
          you should contact the seller directly so they can help with the
          issue. Listings should always be honest about items and show photos
          and descriptions of any defects.
        </StyledParagraph>
        <StyledH2>What determines if an item is not as described?</StyledH2>
        <StyledParagraph>
          - You received a different item <br></br>- The condition of the item
          is incorrect <br></br>- The item is missing parts <br></br>- The item
          was defective <br></br>- The item was damanged during shipment{" "}
          <br></br>
        </StyledParagraph>
        <StyledH2>
          Is bellybutton responsible for things bought and sold via the
          platform?
        </StyledH2>
        <StyledParagraph>
          Bellybutton is a peer to peer marketplace that connects buyers and
          sellers and as such, both the buyer and seller are responsible for
          things sold on the bellybutton site. Although every care is taken to
          ensure that the listing is accurate and safe, all details within a
          listing are based on the information loaded by the seller. Neither
          bellybutton or Plunket are responsible for any issues arising from the
          purchase of sale of any items.
          <br></br>
          <br></br>
          <br></br>
          If there is anything that we haven't answered or you are unsure of,
          please don't hesitate to{" "}
          <Link
            style={{
              color: "#304669",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            to={"/contactus"}
          >
            get in touch
          </Link>
          .
        </StyledParagraph>
      </FaqPageText> */}
    </FaqPageContent>
  );
};
