import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { H1, H3 } from "../components/Header";
import { LayoutBodyFullwidth } from "../components/Layout";

const WithdrawListingConfirmLogo = styled.img`
  width: 89px;
  height: 89px;
  ${up("lg")} {
    width: 120px;
    height: 120px;
  }
`;

const WithdrawListingConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 60px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  ${up("lg")} {
    margin-top: 60px;
  }
`;

const SubText = styled.div`
  margin-top: 20px;

  ${up("lg")} {
    margin-top: 33px;
  }
`;

const WithdrawListingConfirmButtons = styled.div`
  width: 300px;
  margin-top: 20px;

  ${up("lg")} {
    width: 288px;
    margin-top: 33px;
  }
`;

const StyledButton = styled.button`
  background-color: #304669;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: #304669;
  width: 288px;
  height: 63px;
  border-radius: 10px;
  :hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #304669;
    transition: all 0.3s ease-in-out;
  }
`;

const StyledH1 = styled.h1`
  margin-top: 21px;
  color: #f27442;
  font-size: 30px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
    margin-top: 51px;
  }
`;

const StyledH4 = styled.h4`
  color: #304669;
  font-size: 24px;
  font-weight: normal;

  ${up("lg")} {
    font-size: 30px;
  }
`;

export const WithdrawListingConfirm = () => {
  const navigate = useNavigate();
  const onGoToMyAccountClicked = () => {
    navigate("/myaccount");
  };
  return (
    <LayoutBodyFullwidth>
      <WithdrawListingConfirmWrapper>
        <WithdrawListingConfirmLogo
          src="/assets/images/bellybutton-hearticon.png"
          alt="Bellybutton Heart Icon"
        />
        <StyledH1>Success</StyledH1>
        <SubText>
          <StyledH4>Your listing has been withdrawn and deleted.</StyledH4>
        </SubText>
        <WithdrawListingConfirmButtons>
          <StyledButton onClick={onGoToMyAccountClicked}>
            GO TO MY ACCOUNT
          </StyledButton>
        </WithdrawListingConfirmButtons>
      </WithdrawListingConfirmWrapper>
    </LayoutBodyFullwidth>
  );
};

export default WithdrawListingConfirm;
