import { down } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";

export const ParentingAppTile = (props: {
  imageUrl: string;
  link: string;
  title: string;
  subTitle: string;
}) => {
  return (
    <Wrapper href={props.link}>
      <ImageWrapper src={props.imageUrl} />
      <Title>{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
      <PlatformsBadge>iOS & ANDROID</PlatformsBadge>
    </Wrapper>
  );
};

const Title = styled.h1`
  ${down("lg")} {
    margin-left: 20px !important;
  }

  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: ${COLORS.NAVY_BLUE};
  text-align: left;
  margin-bottom: 14px;
`;
const SubTitle = styled.h1`
  ${down("lg")} {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.NAVY_BLUE};
  text-align: left;
`;

const Wrapper = styled.a`
  transition: all 0.1s ease-in-out;
  position: relative;
  text-decoration: none;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const ImageWrapper = styled.img`
  width: 100%;
  height: 218px;
  object-fit: cover;
  margin-bottom: 18px;
`;

const PlatformsBadge = styled.div`
  position: absolute;
  font-size: 10px;
  color: white;
  text-align: center;
  font-weight: bold;
  border-radius: 6px;
  padding: 8px 20px 8px 20px;
  top: 12px;
  right: 12px;
  background-color: ${COLORS.NAVY_BLUE};
`;
