import { Link } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { useAuth } from "../hooks/useAuth";
import { StyledLink } from "./Link";
import { MobileNav } from "./MobileNav";

{
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
  />;
}

const DesktopWrapper = styled.nav`
  display: none;
  z-index: 2000;
  background-color: #fff;
  ${up("lg")} {
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 88px;
    padding-right: 80px;
    justify-content: space-between;
  }
`;

const NavLeftContent = styled.div`
  display: flex;
  align-items: center;
`;
const NavRightContent = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  transition: all 0.3s ease-in-out;
  width: 202px;
  height: 44px;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    width: 140px;
    align-items: center;
  }

  @media (max-width: 376px) {
    width: 140px;
  }
`;

export const Navigation = () => {
  const { isSignedIn } = useAuth();

  return (
    <div>
      <MobileNav />
      <DesktopWrapper>
        <NavLeftContent>
          <Link to={"/"}>
            <img width={75} src="/assets/images/bellybutton.svg" />
          </Link>
          {/* <Link style={{ fontSize: "2rem", color: COLORS.BLACK75 }} to={"/"}>
            NZ Battery Marketplace
          </Link> */}
        </NavLeftContent>
        <NavRightContent>
          {/* <StyledLink text="Buy" type="tertiary" href="buy" /> */}
          {/* Blog link */}
          <StyledLink text="Blog" type="tertiary" href="blog" />
          <StyledLink text="Create listing" type="tertiary" href="sell" />
          {/* <StyledLink text="Apps" type="tertiary" href="parentingapps" /> */}
          <StyledLink text="FAQ" type="tertiary" href="faq" />
          {!isSignedIn && (
            <>
              <StyledLink
                text="Register"
                type="secondary"
                href="sellregister"
              />
              <StyledLink text="LOGIN" type="primary" href="login" />
            </>
          )}

          {isSignedIn && (
            <>
              <StyledLink
                text="My account"
                type="secondary"
                href="/myaccount"
              />
              <StyledLink text="Logout" type="primary" href="/logout" />
            </>
          )}
        </NavRightContent>
      </DesktopWrapper>
    </div>
  );
};
