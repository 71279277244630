import styled from "styled-components";

export const SelectInputClass = styled.select`
  max-width: 100%;
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 20px;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }
`;
