import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { H2, H3 } from "../components/Header";
import { LayoutBodyFullwidth } from "../components/Layout";

const PurchaseLogo = styled.img`
  width: 89px;
  height: 89px;

  ${up("lg")} {
    width: 119px;
    height: 119px;
  }
`;

const PurchaseConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;

  ${up("lg")} {
    margin-top: 80px;
    margin-bottom: 120px;
  }
`;

const PurchaseHeadingGrid = styled.div`
  display: grid;
  grid-template-columns: 10% 90%;
  margin-top: 20px;
`;

const PurchaseHeadingIcon = styled.img`
  height: 37px;
  width: 37px;
  margin-right: 40px;
  margin-top: -5px;

  ${up("lg")} {
    margin-top: 0px;
  }
`;

const PurchaseHeadingCongratulations = styled.div``;

const ConfirmPurchaseButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  row-gap: 20px;
  grid-column-gap: 40px;
  margin-top: 40px;

  ${up("lg")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 600px;
    grid-column-gap: 40px;
    margin-top: 40px;
    row-gap: 0px;
  }
`;

const SubTextOrange = styled.div`
  margin-top: 20px;
  line-height: 35px;
`;

const SubTextBlue = styled.div`
  margin-top: 20px;
  line-height: 35px;
`;

const StyledButton = styled.button`
  background-color: #304669;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #304669;
  width: 288px;
  height: 63px;
  :hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #304669;
    transition: all 0.3s ease-in-out;
  }
`;

const StyledButtonTwo = styled.button`
  background-color: #ffffff;
  color: #304669;
  font-size: 12px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #304669;
  width: 288px;
  height: 63px;
  :hover {
    cursor: pointer;
    background-color: #304669;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
  }
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 24px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 40px;
  }
`;

const StyledH2 = styled.h2`
  color: #f27442;
  font-size: 20px;
  font-weight: normal;
  line-height: 25px;

  ${up("lg")} {
    font-size: 30px;
    line-height: 35px;
  }
`;

const StyledH3 = styled.h3`
  color: #304669;
  font-size: 20px;
  font-weight: normal;
  line-height: 25px;

  ${up("lg")} {
    font-size: 30px;
    line-height: 35px;
  }
`;

// PAGE LAYOUT EXPORT

export const ConfirmPurchase = () => {
  return (
    <LayoutBodyFullwidth>
      <PurchaseConfirmationWrapper>
        <ConfirmPurchaseHeroWrap />
        <ConfirmPurchaseContentWrap />
        <PurchaseButtonWrap />
      </PurchaseConfirmationWrapper>
    </LayoutBodyFullwidth>
  );
};

const ConfirmPurchaseHeroWrap = () => {
  return (
    <div>
      <PurchaseLogo
        src="/assets/images/bellybutton-hearticon.png"
        alt="Bellybutton Heart Icon"
      />
      <PurchaseHeadingGrid>
        <PurchaseHeadingIcon
          src="/assets/images/smilingicon.svg"
          alt="Smily Face Icon"
        />
        <PurchaseHeadingCongratulations>
          <StyledH1>Congratulations!</StyledH1>
        </PurchaseHeadingCongratulations>
      </PurchaseHeadingGrid>
    </div>
  );
};

const ConfirmPurchaseContentWrap = () => {
  return (
    <div>
      <SubTextOrange>
        <StyledH2>
          We have sent an email to you and the seller confirming your <br></br>
          purchase. Please contact the seller directly to arrange payment{" "}
          <br></br>
          and postage or collection.
        </StyledH2>
      </SubTextOrange>
      <SubTextBlue>
        <StyledH3>
          Please remember to leave feedback for the seller once you have{" "}
          <br></br> received your item. If you have any issues with this
          purchase, <br></br> please contact the seller directly in the first
          instance.
          <br></br>
          <br></br>
          This purchase will now appear in your My Purchases list.
        </StyledH3>
      </SubTextBlue>
    </div>
  );
};

const PurchaseButtonWrap = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ConfirmPurchaseButtons>
        <Button
          buttonType="button"
          onClick={() => {
            navigate("/myaccount");
          }}
          type="blueborder"
          text="GO TO MY ACCOUNT"
        />
        <Button
          buttonType="button"
          onClick={() => {
            navigate("/buy");
          }}
          type="bluebg"
          text="BROWSE OTHER LISTINGS"
        />
      </ConfirmPurchaseButtons>
    </div>
  );
};

export default ConfirmPurchase;
