import { ListboxButton } from "@reach/listbox";
import "@reach/listbox/styles.css";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";
import { SelectInputClass } from "../components/SelectInput";
import { ALL_LOCATIONS } from "../constants/locations";
import { useAuth } from "../hooks/useAuth";

const ChangeRegionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 120px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  ${up("lg")} {
    margin-top: 80px;
  }
`;

const ChangeRegionForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fbf0eb;
  width: 332px;
  height: 289px;
  margin-top: 40px;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;

  ${up("lg")} {
    width: 732px;
    height: 289px;
    margin-top: 40px;
    padding-top: 20px;
    padding-left: 117px;
    padding-right: 117px;
  }
`;

const ChangeRegionSubText = styled.div`
  margin-top: 20px;
`;

const ChangeRegionLabelClass = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 5px;
  color: #304669;
  text-align: left;
`;

const ChangeRegionButton = styled.button`
  align-self: center;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  width: 190px;
  height: 63px;
  background-color: #304669;
  border-style: solid;
  border-radius: 3px;
  border-color: #304669;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #304669;
    color: #304669;
    transition-duration: 500ms;
  }
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 36px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

const StyledH3 = styled.h3`
  color: #304669;
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;

  ${up("lg")} {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const ChangeRegion = () => {
  return (
    <ChangeRegionWrapper>
      <ChangeRegionHeroWrap />
      <LayoutBodyFullwidth>
        <ChangeRegionFormWrap />
      </LayoutBodyFullwidth>
    </ChangeRegionWrapper>
  );
};

const ChangeRegionHeroWrap = () => {
  return (
    <div>
      <StyledH1>Change your location</StyledH1>
      <ChangeRegionSubText>
        <StyledH3>You can change your location below.</StyledH3>
      </ChangeRegionSubText>
    </div>
  );
};

const ChangeRegionFormWrap = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { user, updateUserDetails } = useAuth();
  const onSubmit = useCallback(
    (data: { location: string }) => {
      if (!user) {
        return;
      }

      updateUserDetails(
        {
          publicData: {
            location: data.location,
          },
        },
        () => {
          navigate("/mydetails");
        }
      );
    },
    [user]
  );

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ChangeRegionForm onSubmit={handleSubmit(onSubmit)}>
        <ChangeRegionLabelClass>New Location</ChangeRegionLabelClass>
        <SelectInputClass
          {...register("location", { required: "Select a region" })}
          defaultValue={""}
        >
          <option disabled value="">
            Location
          </option>
          {Object.keys(ALL_LOCATIONS).map((location: any) => (
            <option key={location} value={location}>
              {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
            </option>
          ))}
        </SelectInputClass>

        <ChangeRegionButton type="submit">CHANGE LOCATION</ChangeRegionButton>
      </ChangeRegionForm>
    </div>
  );
};

export default ChangeRegion;
