import React from "react";
import { IId } from "../components/AuthProvider";
import { IListing } from "./useListing";

export const TRANS_ALIAS = "flex-default-process/release-1";

export const enum TRANSITIONS {
  PURCHASE = "transition/purchase",
  RESERVE_STOCK = "transition/reservestock",
  ACCEPT_STOCK = "transition/acceptstock",
  REVIEW_ONE_BY_CUSTOMER = "transition/review-1-by-customer",
  REVIEW_ONE_BY_PROVIDER = "transition/review-1-by-provider",
  REVIEW_TWO_BY_CUSTOMER = "transition/review-2-by-customer",
  REVIEW_TWO_BY_PROVIDER = "transition/review-2-by-provider",
}
export interface IApiTransaction {
  id: IId;
  type: string;
  attributes: {
    createdAt: Date;
    processName: string;
    lastTransition: TRANSITIONS;
    lastTransitionedAt: Date;
    payinTotal: {
      amount: number;
    };
    payoutTotal: {
      amount: number;
    };
    lineItems: [
      {
        code: string;
        quantity: number;
        units: number;
        seats: number;
        reversal: boolean;
        unitPrice: {
          amount: number;
        };
        lineTotal: {
          amount: number;
        };
        includeFor: ["customer", "provider"];
      }
    ];
    protectedData: any;
  };
  relationships: ITransactionRelationships;
}

export type IRelationship = {
  data: {
    id: IId;
    type: "listing" | "provider" | "customer";
  };
};
export interface ITransactionRelationships {
  customer: IRelationship;
  provider: IRelationship;
  listing: IRelationship;
}

export type ROLE = "provider" | "customer";
export interface ITransaction {
  myRole: ROLE;
  listingId: string;
}

export enum MY_ROLE {
  PROVIDER = "provider",
  CUSTOMER = "customer",
}
export interface IHistoricListing extends IListing {
  myRole: MY_ROLE;
  transaction: IApiTransaction;
  canGiveFeedback: boolean;
  nextFeedbackTransition: TRANSITIONS | undefined;
  amISecondToGiveFeedback: boolean;
}

export enum ReviewRating {
  BAD = 1,
  FIFTY_FIFTY = 2,
  GOOD = 3,
}
export interface IReviewToPost {
  transactionId: string;
  reviewRating: ReviewRating;
  reviewContent: string;
}

export interface IReviewFromApi {
  id: IId;
  attributes: {
    type: "ofProvider" | "ofCustomer";
    rating: ReviewRating;
    content: string;
    createdAt: Date;
  };
  relationships: {
    author: { data: { id: IId } };
    subject: { data: { id: IId } };
  };
}

export interface TransactionContextType {
  purchaseListingById: (listingId: string, callback: VoidFunction) => void;
  historicListings: IHistoricListing[];
  boughtListings: IHistoricListing[];
  soldListings: IHistoricListing[];
  userFeedback: { [userId: string]: IReviewFromApi[] };
  submitReview: (
    review: IReviewToPost,
    transition: TRANSITIONS,
    callback: VoidFunction
  ) => void;
  getTransactionHistory: VoidFunction;
  getUsersFeedback: (userId: string) => void;
  userScoresById: { [key: string]: number };
}

export const TransactionContext = React.createContext<TransactionContextType>(
  null!
);
export const useTransaction = () => React.useContext(TransactionContext);
