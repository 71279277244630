import styled from "styled-components";

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: grid;
  width: fit-content;
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: min-content;
  gap: 20px;
  column-gap: 20px;
  row-gap: 80px;
`;

export const ListingTilePreviewContainer = (props: any) => {
  return (
    <Center>
      <Wrapper>{props.children}</Wrapper>
    </Center>
  );
};
