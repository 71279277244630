import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";

const ChangeEmailConfirmLogo = styled.img`
  width: 89px;
  height: 89px;
  ${up("lg")} {
    width: 120px;
    height: 120px;
  }
`;

const ChangeEmailConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 60px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  ${up("lg")} {
    margin-top: 60px;
  }
`;

const ChangeEmailConfirmButtons = styled.div`
  width: 240px;
  margin-top: 20px;

  ${up("lg")} {
    width: 288px;
    margin-top: 33px;
  }
`;

const SubText = styled.div`
  margin-top: 20px;

  ${up("lg")} {
    margin-top: 33px;
  }
`;

const StyledH1 = styled.h1`
  margin-top: 21px;
  color: #f27442;
  font-size: 30px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
    margin-top: 51px;
  }
`;

const StyledH4 = styled.h4`
  color: #304669;
  font-size: 24px;
  font-weight: normal;

  ${up("lg")} {
    font-size: 30px;
  }
`;

export const ChangeEmailConfirm = () => {
  const navigate = useNavigate();
  return (
    <ChangeEmailConfirmWrapper>
      <ChangeEmailConfirmLogo
        src="/assets/images/bellybutton-hearticon.png"
        alt="Bellybutton Heart Icon"
      />
      <StyledH1>Congratulations!</StyledH1>
      <SubText>
        <StyledH4>Your email address has been updated.</StyledH4>
      </SubText>
      <ChangeEmailConfirmButtons>
        <Button
          buttonType="button"
          onClick={() => {
            navigate("/myaccount");
          }}
          type="bluebg"
          text="GO TO MY ACCOUNT"
        />
      </ChangeEmailConfirmButtons>
    </ChangeEmailConfirmWrapper>
  );
};

export default ChangeEmailConfirm;
