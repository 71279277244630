import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { H2, H4 } from "../components/Header";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { MyAccountHero } from "../components/MyAccountHero";
import { MyAccountSidebar } from "../components/MyAccountSidebar";
import { SuccessText } from "../components/SuccessText";
import { COLORS } from "../constants/colors";
import {
  IReviewToPost,
  ReviewRating,
  TRANSITIONS,
  useTransaction,
} from "../hooks/useTransaction";

const Wrapper = styled.div``;

const MainContent = styled.div`
  display: flex;
  ${up("lg")} {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }
  margin-bottom: 80px;
`;

const Main = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  ${up("lg")} {
    margin-left: 60px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const LabelClass = styled.label`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 40px;
  color: #304669;
  text-align: left;
`;

const InputClass = styled.textarea`
  width: 100%;
  padding: 20px;
  height: 149px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
`;

const Button = styled.button`
  font-size: 12px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 120px;
  ${up("lg")} {
    width: 288px;
  }
  height: 50px;
  background-color: #f27442;
  border-style: solid;
  border-radius: 0px;
  border-color: #f27442;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #f27442;
    color: #f27442;
    transition-duration: 500ms;
  }
`;

const ButtonRound = styled.button`
  font-size: 12px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 288px;
  height: 50px;
  background-color: #f27442;
  border-style: solid;
  border-radius: 10px;
  border-color: #f27442;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #f27442;
    color: #f27442;
    transition-duration: 500ms;
  }
`;

const GiveSellerCancelFeedbackButton = styled.button`
  width: 120px;
  ${up("lg")} {
    width: 288px;
  }
  align-self: right;
  font-size: 12px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  height: 50px;
  background-color: #ffffff;
  border-style: solid;
  border-radius: 0px;
  border-color: #f27442;
  color: #f27442;
  &:hover {
    cursor: pointer;
    background-color: #f27442;
    border: 2px;
    border-style: solid;
    border-color: #f27442;
    color: #ffffff;
    transition-duration: 500ms;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubText = styled.div`
  text-align: left;
  color: ${COLORS.NAVY_BLUE};
  line-height: 20px;
  border-top: 0px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
  border-color: #304669;
  padding-bottom: 32px;
  margin-bottom: 24px;
  font-size: 16px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${up("md")} {
    flex-direction: row;
  }
  justify-content: flex-start;
  align-items: center;
`;

//TO DO - round the checkboxes and add in validation
const CheckBoxInputClass = styled.input.attrs({ type: "radio" })`
  height: 20px;
  width: 20px;
  :checked {
    height: 30px;
    width: 30px;
  }
`;

const CheckBoxLabelClass = styled.label`
  padding-right: 20px;
`;

const CheckBoxIcon = styled.img`
  height: 36px;
  width: 36px;
  padding-left: 10px;
  padding-right: 10px;
`;

const IconCheck = styled.div`
  display: flex;
  align-items: center;
`;

interface IPartialReviewData {
  reviewRating: ReviewRating;
  reviewContent: string;
}

export const GiveFeedback = () => {
  const { submitReview } = useTransaction();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { transactionId } = useParams();
  // Get my rule from the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const transactionStep = searchParams.get("transactionStep");
  const listingTitle = searchParams.get("listingTitle");
  const otherPerson = searchParams.get("otherPerson");
  const navigate = useNavigate();

  if (!transactionStep) {
    return <div>Error, invalid transaction step.</div>;
  }

  const onSubmit = (data: IPartialReviewData) => {
    if (!transactionId) {
      alert("No transaction id");
      return;
    }
    const review: IReviewToPost = {
      transactionId,
      reviewContent: data.reviewContent,
      reviewRating: parseInt(data.reviewRating as any as string),
    };
    submitReview(review, transactionStep as any as TRANSITIONS, () => {
      setIsSubmitted(true);
    });
  };
  const onCancelClicked = () => {
    navigate("/mypurchases");
  };
  return (
    <Wrapper>
      {!isSubmitted && <MyAccountHero>Give feedback</MyAccountHero>}
      <LayoutBodyFullwidth>
        <LayoutBody>
          {isSubmitted && (
            <div>
              <SuccessText
                title="Feedback submitted"
                description="Thank you for leaving feedback."
              />
              <div
                style={{
                  marginTop: "28px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <ButtonRound
                  onClick={() => {
                    navigate("/mypurchases");
                  }}
                >
                  GO TO MY PURCHASES
                </ButtonRound>
              </div>
            </div>
          )}
          {!isSubmitted && (
            <MainContent>
              <MyAccountSidebar />
              {/* <Sidebar options={SidebarOptions} /> */}
              <Main>
                <H2
                  style={{
                    color: "#F27442",
                    fontSize: "40px",
                    fontWeight: "bold",
                    textAlign: "left",
                    marginTop: "16px",
                    marginBottom: "32px",
                  }}
                >
                  Give feedback
                </H2>
                <H4
                  style={{
                    color: "#F27442",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                    marginBottom: "32px",
                  }}
                >
                  You are about to give feedback on "{listingTitle}".
                </H4>
                <SubText>
                  <span style={{ fontWeight: "bold" }}>
                    You will not have a chance to edit this feedback
                  </span>
                  , so be honest and fair. Keep your language clean. <br></br>{" "}
                  Abuse or misuse of the feedback system may result in a ban.
                </SubText>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <H4
                    style={{
                      color: "#304669",
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "16px",
                    }}
                  >
                    How was your experience with{" "}
                    {otherPerson ? otherPerson : "this buyer/seller"}?
                    {/* How was your experience with seller username? */}
                  </H4>
                  <CheckBoxWrapper>
                    <CheckBoxInputClass
                      {...register("reviewRating", {
                        required: true,
                      })}
                      value={ReviewRating.GOOD}
                    />
                    <IconCheck>
                      <CheckBoxIcon
                        src="/assets/images/thumbsupicon.svg"
                        alt="Thumbs Up Icon"
                      />
                      <CheckBoxLabelClass style={{ color: "#31B447" }}>
                        Good
                      </CheckBoxLabelClass>
                    </IconCheck>
                    <CheckBoxInputClass
                      {...register("reviewRating", {
                        required: true,
                      })}
                      value={ReviewRating.FIFTY_FIFTY}
                    />
                    <IconCheck>
                      <CheckBoxIcon
                        src="/assets/images/straightfaceicon.svg"
                        alt="Straight Face Icon"
                      />
                      <CheckBoxLabelClass style={{ color: "#F27442" }}>
                        50/50
                      </CheckBoxLabelClass>
                    </IconCheck>
                    <CheckBoxInputClass
                      {...register("reviewRating", {
                        required: true,
                      })}
                      value={ReviewRating.BAD}
                    />
                    <IconCheck>
                      <CheckBoxIcon
                        src="/assets/images/thumbsdownicon.svg"
                        alt="Thumbs Down Icon"
                      />
                      <CheckBoxLabelClass style={{ color: "#8A0000" }}>
                        Bad
                      </CheckBoxLabelClass>
                    </IconCheck>
                  </CheckBoxWrapper>
                  <LabelClass>What would you like to say?</LabelClass>
                  <InputClass
                    {...register("reviewContent", { required: true })}
                  />
                  <ButtonWrapper>
                    <Button type="submit">SUBMIT FEEDBACK</Button>
                    <GiveSellerCancelFeedbackButton onClick={onCancelClicked}>
                      CANCEL
                    </GiveSellerCancelFeedbackButton>
                  </ButtonWrapper>
                </Form>
              </Main>
            </MainContent>
          )}
        </LayoutBody>
      </LayoutBodyFullwidth>
    </Wrapper>
  );
};

export default GiveFeedback;
