import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { Button } from "./Button";
import { SectionHeading } from "./SectionHeading";

export const SellConfirmArea = ({
  handleSubmit,
  createDraftListing,
}: {
  handleSubmit: any;
  createDraftListing: (listing: any) => void;
}) => {
  const navigate = useNavigate();
  return (
    <PreviewListingContent>
      <SectionHeading
        icon={EyeIcon}
        titleColor={COLORS.DARK_ORANGE}
        title="Preview listing"
        subTitle={
          <p
            style={{
              color: "#304669",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "34px",
            }}
          >
            If you're happy with everything above, click the Preview button
            below and you'll be able to preview your listing before we list it.
            If you don't want to list this item anymore, click the Cancel
            button.
          </p>
        }
      >
        <></>
      </SectionHeading>

      <PreviewListingGrid>
        <div style={{ width: "288px", marginRight: "48px" }}>
          <Button
            buttonType="button"
            type="secondary"
            text="Cancel Listing"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div style={{ width: "288px" }}>
          <Button
            buttonType="button"
            onClick={handleSubmit(createDraftListing)}
            type="primary"
            text="Preview Listing"
          />
        </div>
      </PreviewListingGrid>
    </PreviewListingContent>
  );
};

const EyeIcon = (
  <img
    width={48}
    height={32}
    // Add orange filter to svg
    src="/assets/images/eyeball.svg"
    alt="Eye Icon"
  />
);

const PreviewListingContent = styled.div`
  margin-top: 50px;
  margin-bottom: 60px;
`;

const PreviewListingGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 24px;

  button {
    margin-bottom: 16px;
  }

  ${up("sm")} {
    margin-left: 0;
    justify-content: center;
    flex-direction: row;

    button {
      margin-bottom: 0;
    }
  }
`;
