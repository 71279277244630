import "@reach/listbox/styles.css";
import { useEffect, useState } from "react";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { Button } from "../components/Button";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { MyAccountHero } from "../components/MyAccountHero";
import { MyAccountSidebar } from "../components/MyAccountSidebar";
import { MyListingTile } from "../components/MyListingTile";
import { NoResults } from "../components/NoResults";
import { IListing, useListing } from "../hooks/useListing";
import { IHistoricListing, useTransaction } from "../hooks/useTransaction";

const MyListingsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${up("lg")} {
    grid-template-columns: 1fr 4fr;
  }
  margin-top: 56px;
  margin-bottom: 60px;
`;

const MyListingsMainContent = styled.div`
  margin-left: 20px;
  margin-top: 2px;
`;

const MyListingsListingGrid = styled.div`
  display: flex;
  justify-content: center;
  ${up("lg")} {
    justify-content: flex-start;
  }
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 970px;
  max-width: 100vw;
`;

export const MyListings = () => {
  const [soldFilter, setSoldFilter] =
    useState<"sold" | "unsold" | undefined>(undefined);

  const { getMyListings, myListings } = useListing();
  const { getTransactionHistory } = useTransaction();
  useEffect(() => {
    getMyListings();
    getTransactionHistory();
  }, [getMyListings, getTransactionHistory]);

  const hasListings = myListings.length > 0;

  return (
    <div>
      <MyAccountHero>My listings</MyAccountHero>
      <LayoutBodyFullwidth>
        <LayoutBody>
          <MyListingsContent>
            <MyAccountSidebar />
            <MyListingsMainContent>
              <FilterButtons setSoldFilter={setSoldFilter} />
              <ListingGrid soldFilter={soldFilter} listings={myListings} />
              {!hasListings && <NoResults type="listings" />}
            </MyListingsMainContent>
          </MyListingsContent>
        </LayoutBody>
      </LayoutBodyFullwidth>
    </div>
  );
};

const FilterButtons = ({
  setSoldFilter,
}: {
  setSoldFilter: (soldFilter: "sold" | "unsold" | undefined) => void;
}) => (
  <Buttons>
    <div style={{ width: "200px", marginRight: "20px" }}>
      <Button
        type="primary"
        buttonType="button"
        text="SOLD"
        onClick={() => {
          setSoldFilter("sold");
        }}
      />
    </div>
    <div style={{ width: "200px" }}>
      <Button
        type="secondary"
        buttonType="button"
        text="UNSOLD"
        onClick={() => {
          setSoldFilter("unsold");
        }}
      />
    </div>
  </Buttons>
);
interface IListingGridProps {
  listings: IListing[];
  soldFilter?: "sold" | "unsold";
}

const ListingGrid = (props: IListingGridProps) => {
  const { myListings, getMyListings } = useListing();
  const { soldListings } = useTransaction();
  useEffect(() => {
    getMyListings();
  }, [getMyListings]);

  const { listings, soldFilter } = props;
  const publishedListings = listings.filter(
    (listing: IListing) => listing.attributes.state === "published"
  );

  return (
    <MyListingsListingGrid>
      {soldFilter === "unsold" || !soldFilter
        ? publishedListings.map((listing: IListing) => (
            <MyListingTile
              key={listing.id.uuid + "published"}
              listing={listing}
            />
          ))
        : null}
      {soldFilter === "sold" || !soldFilter
        ? soldListings.map((listing: IHistoricListing) => (
            <MyListingTile
              sold
              transactionStep={listing.nextFeedbackTransition}
              transactionId={listing.transaction.id.uuid}
              canGiveFeedback={listing.canGiveFeedback}
              key={listing.id.uuid + listing.transaction.id.uuid + "sold"}
              listing={listing}
            />
          ))
        : null}
    </MyListingsListingGrid>
  );
};

export default MyListings;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  ${up("lg")} {
    justify-content: flex-start;
  }
`;
