import { useEffect } from "react";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { useTransaction } from "../hooks/useTransaction";
import { NoResults } from "./NoResults";
import { PurchasedListingTile } from "./PurchasedListingTitle";

const ListingGrid = styled.div`
  display: grid;
  justify-items: center;
  ${up("lg")} {
    justify-items: left;
  }
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  margin-bottom: 40px;
`;

export const PurchasedListings = ({ hideError }: { hideError?: boolean }) => {
  const { boughtListings, getTransactionHistory } = useTransaction();
  useEffect(() => {
    getTransactionHistory();
  }, []);
  const hasPurchases = boughtListings.length > 0;

  return (
    <ListingGrid>
      {boughtListings.map((listing) => (
        <PurchasedListingTile
          key={listing.id.uuid + listing.transaction.id.uuid}
          listing={listing}
        />
      ))}
      {!hasPurchases && !hideError && <NoResults type="purchases" />}
    </ListingGrid>
  );
};
