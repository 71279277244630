/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useState } from "react";
import { IImage } from "../hooks/useImage";
import { IListing, ListingContext } from "../hooks/useListing";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { UUID, Money } = require("sharetribe-flex-sdk").types;

const sharetribeSdk = require("sharetribe-flex-sdk");
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_CLIENT_ID,
});

interface ICreateListingData {
  title: string;
  description: string;
  price: number;
  category: string;
  subCategory: string;
  condition: string;
  postage: string;
  location: string;
}
interface IUpdateListingData {
  id: string;
  title: string;
  description: string;
  price: number;
  category: string;
  subCategory: string;
  condition: string;
  postage: string;
  location: string;
}

export const ListingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading] = useState(true);
  const [myListings, setMyListings] = useState<IListing[]>([]);
  const [publicListings, setPublicListings] = useState<IListing[]>([]);
  const [featuredListings, setFeaturedListings] = useState<IListing[]>([]);
  const [allMetadata, setAllMetadata] = useState<any>({});
  const [newListingImages, setNewListingImages] = useState<IImage[]>([]);
  const [listingById, setListingById] = useState<IListing | null>(null);
  const [listingsByIds, setListingsByIds] = useState<IListing[]>([]);
  const [ownListingById, setOwnListingById] = useState<IListing | null>(null);
  const [imagesCurrentlyUploading, setImagesCurrentlyUploading] = useState(0);
  const [listingsByUserId, setListingsByUserId] = useState<IListing[]>([]);

  // Adds an array of images to the listingImages
  // Uses the id.uuid of the image as the key
  const storeMetadata = useCallback(
    (images: any) => {
      const newListingImages: any = {};
      images.forEach((image: any) => {
        // Used to be IImage
        newListingImages[image.id.uuid] = image;
      });
      // Add new images to old ones
      setAllMetadata((oldListingImages: any) => {
        return { ...oldListingImages, ...newListingImages };
      });
    },
    [setAllMetadata]
  );

  const getMyListings = useCallback(async () => {
    sdk.ownListings
      .query({ include: ["images", "author", "currentStock"] })
      .then((res: any) => {
        const listings = res.data.data;
        // Filter to only include listings with stock relationship and stock greater than 0
        const filteredListings = listings.filter((listing: any) =>
          res.data.included.some(
            (included: any) =>
              included.id.uuid ===
                listing?.relationships?.currentStock?.data?.id?.uuid &&
              included.attributes?.quantity > 0
          )
        );
        storeMetadata(res.data.included);
        setMyListings(filteredListings);
      });
  }, [storeMetadata, setMyListings]);

  const uploadImage = useCallback(
    async (image: File) => {
      setImagesCurrentlyUploading((oldState: number) => oldState + 1);
      sdk.images
        .upload(
          {
            image,
          },
          {
            expand: true,
          }
        )
        .then((res: any) => {
          // callback(res.data.data.id.uuid);
          setNewListingImages((oldImages: IImage[]) => [
            ...oldImages,
            res.data.data,
          ]);
          setImagesCurrentlyUploading((oldState: number) =>
            Math.max(oldState - 1, 0)
          );
        })
        .catch(() => {
          setImagesCurrentlyUploading((oldState: number) =>
            Math.max(oldState - 1, 0)
          );
          alert("Error uploading image, please try again.");
        });
    },
    [setImagesCurrentlyUploading, setNewListingImages]
  );

  const createListing = useCallback(
    (data: ICreateListingData, callback: (listingId: string) => void) => {
      const {
        title,
        description,
        price,
        category,
        subCategory,
        condition,
        postage,
        location,
      } = data;

      const imageIdsAsStrings = newListingImages.map(
        (image: IImage) => image.id.uuid
      );

      const convertedImages = imageIdsAsStrings.map(
        (imageId: string) => new UUID(imageId)
      );
      const hasImages = convertedImages.length > 0;

      const draftRequest = {
        price: new Money((price as number) * 100, "USD"),
        title,
        description,
        privateData: {},
        publicData: {
          location,
          category,
          subCategory,
          condition,
          postage,
          featured: false,
        },
        images: hasImages ? convertedImages : undefined,
      };

      const draftOptions = {
        expand: true,
        // include: hasImages ? ["images"] : undefined,
      };

      sdk.ownListings
        .createDraft(draftRequest, draftOptions)
        .then((res: any) => {
          return sdk.stockAdjustments.create(
            {
              listingId: res.data.data.id,
              quantity: 1,
            },
            {
              expand: true,
              include: ["ownListing"],
            }
          );
        })
        .then((res: any) => {
          callback(res.data.data.relationships.ownListing.data.id.uuid);
        })
        .catch((err: any) => {
          console.error(err);
          // TODO raise an error thrown here to user on listing creation page.
        });
    },
    [newListingImages]
  );

  // Moves the listing from draft state to fully listed.
  const publishDraftListing = (listingId: string, callback: VoidFunction) => {
    sdk.ownListings
      .publishDraft(
        {
          id: new UUID(listingId),
        },
        {
          expand: true,
        }
      )
      .then(() => {
        callback();
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const getPublicListings = useCallback(async () => {
    sdk.listings
      .query({
        // minStock: 1,
        include: ["images", "author"],
      })
      .then((res: any) => {
        storeMetadata(res.data.included);
        setPublicListings(res.data.data);
      });
  }, [storeMetadata]);

  const getListingById = useCallback(
    (listingId: string) => {
      sdk.listings
        .show({ id: listingId, include: ["images", "author"] })
        .then((res: any) => {
          storeMetadata(res.data.included);
          setListingById(res.data.data);
          return res.data.data;
        });
      return undefined;
    },
    [setListingById, storeMetadata]
  );
  const getListingsByIds = useCallback(
    (listingIds: string[]) => {
      const joinedIdsWithCommas = listingIds.join(",");
      return sdk.listings
        .query({
          ids: joinedIdsWithCommas,
          include: ["images", "author"],
        })
        .then((res: any) => {
          storeMetadata(res.data.included);
          setListingsByIds(res.data.data);
          return res.data.data;
        });
    },
    [storeMetadata]
  );

  const getOwnListingById = useCallback(
    (listingId: string) => {
      sdk.ownListings
        .show({ id: listingId, include: ["images", "author"] })
        .then((res: any) => {
          storeMetadata(res.data.included);
          setOwnListingById(res.data.data);
        });
    },
    [setOwnListingById, storeMetadata]
  );

  const getListingsByUserId = useCallback(
    (userId: string) => {
      sdk.listings
        .query({
          minStock: 1,
          authorId: userId,
          include: ["images", "author"],
        })
        .then((res: any) => {
          storeMetadata(res.data.included);
          setListingsByUserId(res.data.data);
        });
    },
    [setListingsByUserId, storeMetadata]
  );

  // Should only be called during the editing phase when you already have uploaded the actual image file.
  const addNewListingImages = useCallback(
    (images: IImage[]) => {
      setNewListingImages(images);
    },
    [setNewListingImages]
  );

  const removeNewListingImage = useCallback(
    (image: IImage) => {
      setNewListingImages((oldImages: IImage[]) =>
        oldImages.filter((i: IImage) => i.id.uuid !== image.id.uuid)
      );
    },
    [setNewListingImages]
  );

  const getFeaturedListings = useCallback(async () => {
    sdk.listings
      .query({ minStock: 1, pub_featured: true, include: ["images", "author"] })
      .then((res: any) => {
        const listings: IListing[] = res.data.data;
        const featuredListings = listings.filter(
          (listing: IListing) => listing.attributes.publicData.featured
        );
        storeMetadata(res.data.included);
        setFeaturedListings(featuredListings);
      });
  }, [setFeaturedListings, storeMetadata]);

  const updateListing = useCallback(
    (data: IUpdateListingData, callback: (listingId: string) => void) => {
      const {
        id,
        title,
        description,
        price,
        category,
        subCategory,
        condition,
        postage,
        location,
      } = data;
      const imageIdsAsStrings = newListingImages.map(
        (image: IImage) => image.id.uuid
      );

      const convertedImages = imageIdsAsStrings.map(
        (imageId: string) => new UUID(imageId)
      );
      const hasImages = convertedImages.length > 0;

      const request = {
        id,
        price: new Money((price as number) * 100, "USD"),
        title,
        description,
        privateData: {},
        publicData: {
          location,
          category,
          subCategory,
          condition,
          postage,
        },
        images: hasImages ? convertedImages : undefined,
      };

      const options = {
        expand: true,
      };

      sdk.ownListings
        .update(request, options)
        .then((res: any) => {
          callback(res.data.data.id.uuid);
        })
        .catch((err: any) => {
          console.error(err);
        });
    },
    [newListingImages]
  );

  const removeAllImages = useCallback(() => {
    setNewListingImages([]);
  }, [setNewListingImages]);

  const closeListing = useCallback(
    (listingId: string, callback: (error: any | undefined) => void) => {
      sdk.ownListings
        .close(
          {
            id: new UUID(listingId),
          },
          {
            expand: true,
          }
        )
        .then(() => {
          callback(undefined);
        })
        .catch((err: any) => {
          callback(err);
        });
    },
    []
  );

  const value = {
    removeAllImages,
    closeListing,
    isLoading,
    removeNewListingImage,
    createListing,
    getMyListings,
    uploadImage,
    myListings,
    publicListings,
    getPublicListings,
    allMetadata,
    newListingImages,
    publishDraftListing,
    listingById,
    getListingById,
    getListingsByIds,
    listingsByIds,
    featuredListings,
    getFeaturedListings,
    ownListingById,
    getOwnListingById,
    imagesCurrentlyUploading,
    updateListing,
    storeMetadata,
    addNewListingImages,
    getListingsByUserId,
    listingsByUserId,
  };

  return (
    <ListingContext.Provider value={value}>{children}</ListingContext.Provider>
  );
};
