export enum CATEGORIES {
  Bath_Accessories = "Bath accessories",
  BlanketsAndCovers = "Blankets & covers",
  BouncersAndJollyJumpers = "Bouncers & jolly jumpers",
  Books = "Books",
  CarSeats = "Car seats",
  Carriers = "Carriers",
  Clothing = "Clothing",
  CotsAndBassinets = "Cots and bassinets",
  Education = "Education",
  Feeding = "Feeding",
  Furniture = "Furniture",
  Hygiene = "Hygiene",
  Monitors = "Monitors",
  NappiesAndChanging = "Nappies and changing",
  PramsAndStrollers = "Prams and strollers",
  Safety = "Safety",
  Services = "Services",
  SleepAids = "Sleep aids",
  Teething = "Teething",
  Toys = "Toys",
  Walkers = "Walkers",
  Other = "Other",
}

export interface ICategory {
  name: string;
  apiName: string;
  subCategories?: ISubCategory[];
}

export interface ISubCategory {
  name: string;
  apiName: string;
}

export const ALL_CATEGORIES: ICategory[] = [
  {
    name: "Bath accessories",
    apiName: "BATH_ACCESSORIES",
    subCategories: [
      { name: "Toys", apiName: "TOYS" },
      { name: "Baths", apiName: "BATH" },
      { name: "Safety", apiName: "SAFETY" },
      { name: "Towels", apiName: "TOWELS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Blankets & covers",
    apiName: "BLANKETS_COVERS",
    subCategories: [
      { name: "Sleeping bags", apiName: "SLEEPING_BAGS" },
      { name: "Wraps", apiName: "WRAPS" },
      { name: "Sheets", apiName: "SHEETS" },
      { name: "Sets", apiName: "SETS" },
      { name: "Duvets", apiName: "DUVETS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Bouncers & jolly jumpers",
    apiName: "BOUNCERS_JOLY_JUMPERS",
    subCategories: [
      { name: "Bouncers", apiName: "BOUNCERS" },
      { name: "Swings", apiName: "SWINGS" },
      { name: "Jumpers", apiName: "JUMPERS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Books",
    apiName: "BOOKS",
    subCategories: [
      { name: "Children & Babies", apiName: "CHILDREN_BABIES" },
      { name: "Fiction", apiName: "FICTION" },
      { name: "Teaching/Education", apiName: "TEACHING_EDUCATION" },
      { name: "Bulk", apiName: "BULK" },
      { name: "Non-fiction", apiName: "NON_FICTION" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Car seats",
    apiName: "CAR_SEATS",
    subCategories: [
      { name: "Capsules", apiName: "CAPSULES" },
      { name: "Car seats", apiName: "CAR_SEATS" },
      { name: "Booster seats", apiName: "BOOSTER_SEATS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Carriers",
    apiName: "CARRIERS",
    subCategories: [
      { name: "Wearable", apiName: "WEARABLE" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Clothing",
    apiName: "CLOTHING",
    subCategories: [
      { name: "All in one", apiName: "ALL_IN_ONE" },
      { name: "Footwear", apiName: "FOOTWEAR" },
      { name: "Bulk lots", apiName: "BULK_LOTS" },
      { name: "Tops", apiName: "TOPS" },
      { name: "Sets", apiName: "SETS" },
      { name: "Swimwear", apiName: "SWIMWEAR" },
      { name: "Sleepwear", apiName: "SLEEPWEAR" },
      { name: "Dress up", apiName: "DRESS_UP" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Cots & bassinets",
    apiName: "COTS_BASSINETS",
    subCategories: [
      { name: "Cots", apiName: "COTS" },
      { name: "Porta cots", apiName: "PORTA_COTS" },
      { name: "Bassinets", apiName: "BASSINETS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Education",
    apiName: "EDUCATION",
    subCategories: [
      { name: "Babysitting", apiName: "BABYSITTING" },
      { name: "Antenatal", apiName: "ANTENATAL" },
      { name: "Parenting", apiName: "PARENTING" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Feeding",
    apiName: "FEEDING",
    subCategories: [
      { name: "Bibs", apiName: "BIBS" },
      { name: "Kitchen", apiName: "KITCHEN" },
      { name: "Sterilsers", apiName: "STERILISERS" },
      { name: "Pillows", apiName: "PILLOWS" },
      { name: "Breast Pumps", apiName: "BREAST_PUMPS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  { name: "Furniture", apiName: "FURNITURE", subCategories: [] },
  {
    name: "Hygiene",
    apiName: "HYGIENE",
    subCategories: [
      { name: "Sterilisers", apiName: "STERILISERS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  { name: "Monitors", apiName: "MONITORS", subCategories: [] },
  {
    name: "Nappies & changing",
    apiName: "NAPPIES_CHANGING",
    subCategories: [
      { name: "Cloth nappies", apiName: "CLOTH_NAPPIES" },
      { name: "Nappy bags", apiName: "NAPPAY_BAGS" },
      { name: "Change tables/mats", apiName: "CHANGE_TABLES_MATS" },
      { name: "Disposable", apiName: "DISPOSABLE" },
      { name: "Nappy bins", apiName: "NAPPAY_BINS" },
      { name: "Wipes", apiName: "WIPES" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Prams & strollers",
    apiName: "PRAMS_STROLLERS",
    subCategories: [
      { name: "Accessories", apiName: "ACCESSORIES" },
      { name: "Four wheel", apiName: "FOUR_WHEEL" },
      { name: "3 wheel", apiName: "THREE_WHEEL" },
      { name: "Double strollers", apiName: "DOUBLE_STROLLERS" },
      { name: "Prams", apiName: "PRAMS" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Safety",
    apiName: "SAFETY",
    subCategories: [
      { name: "Gates", apiName: "GATES" },
      { name: "Harnesses", apiName: "HARNESSES" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  {
    name: "Services",
    apiName: "SERVICES",
    subCategories: [
      { name: "Fitness", apiName: "FITNESS" },
      { name: "Babysitting", apiName: "BABYSITTING" },
      { name: "Health", apiName: "HEALTH" },
      { name: "Sleep consultants", apiName: "SLEEP_CONSULTANTS" },
      { name: "Feeding support", apiName: "FEEDING_SUPPORT" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  { name: "Sleep aids", apiName: "SLEEP_AIDS", subCategories: [] },
  { name: "Teething", apiName: "TEETHING", subCategories: [] },
  {
    name: "Toys",
    apiName: "TOYS",
    subCategories: [
      { name: "Soft", apiName: "SOFT" },
      { name: "Activity sets", apiName: "ACTIVITY_SETS" },
      { name: "Bulk lots", apiName: "BULK_LOTS" },
      { name: "Musical", apiName: "MUSICAL" },
      { name: "Ride on", apiName: "RIDE_ON" },
      { name: "Other", apiName: "OTHER" },
    ],
  },
  { name: "Walkers", apiName: "WALKERS", subCategories: [] },
  { name: "Other", apiName: "OTHER", subCategories: [] },
];

export const getSubCatNameByApiName = (apiName: string) => {
  for (let i = 0; i < ALL_CATEGORIES.length; i++) {
    if (ALL_CATEGORIES[i].subCategories ?? [].length > 0) {
      for (let j = 0; j < ALL_CATEGORIES[i].subCategories!.length; j++) {
        if (ALL_CATEGORIES[i].subCategories![j].apiName === apiName) {
          return ALL_CATEGORIES[i].subCategories![j].name;
        }
      }
    }
  }
  return "";
};

export const getCatNameByApiName = (apiName: string) => {
  for (let i = 0; i < ALL_CATEGORIES.length; i++) {
    if (ALL_CATEGORIES[i].apiName === apiName) {
      return ALL_CATEGORIES[i].name;
    }
  }
  return "";
};
