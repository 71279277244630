import React, { useCallback, useEffect } from "react";
import { ICategory, ISubCategory } from "../constants/categories";
import { getSortString, SORT_BY } from "../constants/sort";
import { useListing } from "../hooks/useListing";
import { SearchContext } from "../hooks/useSearch";

export interface IListingSearchQuery {
  keyword?: string;
  category?: string;
  subCategory?: string;
  condition?: string;
  location?: string;
  // price?: string;
  // postage?: string;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sharetribeSdk = require("sharetribe-flex-sdk");

// Create new SDK instance
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_CLIENT_ID,
});

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const { storeMetadata } = useListing();

  const [searchResults, setSearchResults] = React.useState<any[]>([]);
  const [keyword, setKeyword] = React.useState("");
  const [category, setCategory] = React.useState<ICategory>({
    name: "All Categories",
    apiName: "",
    subCategories: [],
  });
  const [subCategory, setSubCategory] = React.useState<ISubCategory>({
    name: "All Sub Categories",
    apiName: "",
  });
  const [location, setLocation] = React.useState("");
  const [condition, setCondition] = React.useState("");
  const [sortBy, setSortBy] = React.useState<string | SORT_BY>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const sortString = getSortString(sortBy);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    setSearchResults([]);
    sdk.listings
      .query({
        minStock: 1,
        keywords: keyword.length > 0 ? keyword : undefined,
        pub_category:
          category && category.apiName && category.apiName.length > 0
            ? category.apiName
            : undefined,
        pub_subCategory:
          subCategory && subCategory.apiName ? subCategory.apiName : undefined,
        pub_condition:
          condition && condition.length > 0 ? condition : undefined,
        pub_location: location && location.length > 0 ? location : undefined,
        include: ["images", "author"],
        sort: sortString,
      })
      .then((res: any) => {
        if (abortController.signal.aborted) {
          return;
        }
        setIsLoading(false);
        if (res.data.data.length > 0) {
          storeMetadata(res.data.included);
          setSearchResults(res.data.data);
        }
      });

    // Cancel all hanging requests
    return () => {
      abortController.abort();
    };
  }, [
    setSearchResults,
    setIsLoading,
    storeMetadata,
    keyword,
    category,
    subCategory,
    location,
    condition,
    sortString,
  ]);

  const resetSearch = useCallback(() => {
    setKeyword("");
    setCategory({
      name: "All Categories",
      apiName: "",
      subCategories: [],
    });
    setSubCategory({
      name: "All Sub Categories",
      apiName: "",
    });
    setLocation("");
    setCondition("");
    setSortBy("");
  }, [
    setKeyword,
    setCategory,
    setSubCategory,
    setLocation,
    setCondition,
    setSortBy,
  ]);

  return (
    <SearchContext.Provider
      value={{
        setCategory,
        setSubCategory,
        setLocation,
        setCondition,
        setKeyword,
        setSortBy,
        resetSearch,
        searchResults,
        category,
        subCategory,
        isLoading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
