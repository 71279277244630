import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { useAuth } from "../hooks/useAuth";
import { Hamburger } from "./Hamburger";
import * as BiIcons from "react-icons/bi";
import { useEffect, useState } from "react";

export const MobileNav = () => {
  const [open, setOpen] = useState(false);
  const { isSignedIn } = useAuth();

  return (
    <MobileWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Link to={"/"}>
          <img width={60} src="/assets/images/bellybutton.svg" />
        </Link>
        <Hover>
          <Hamburger
            onClick={() => {
              setOpen(true);
            }}
            text="="
            buttonType="button"
            type="primary"
          />
        </Hover>
      </div>
      <SlideMenu isSignedIn={isSignedIn} open={open} setOpen={setOpen} />
    </MobileWrapper>
  );
};

const MobileWrapper = styled.nav`
  display: inline;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 2000;
  max-width: 100vw;
  ${up("lg")} {
    display: none;
  }
`;

const SlideMenu = ({
  open,
  setOpen,
  isSignedIn,
}: {
  open: boolean;
  setOpen: any;
  isSignedIn: boolean;
}) => {
  const location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [setOpen, location]);

  return (
    <motion.aside
      style={{
        zIndex: 2000,
        padding: open ? "30px" : 0,
        position: "fixed",
        right: 0,
        top: 0,
        backgroundColor: "white",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
      initial={{ width: 0 }}
      animate={{ width: open ? "100%" : 0 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <Link to={"/"}>
          <img width={50} src="/assets/images/bellybutton.svg" />
        </Link>
        <BiIcons.BiExit
          onClick={() => {
            setOpen(false);
          }}
          color="#304669"
          size="35px"
        />
      </div>

      <MenuLink type="tertiary" to="sell">
        Create listing
      </MenuLink>

      <MenuLink type="tertiary" to="faq">
        FAQ
      </MenuLink>

      {!isSignedIn && (
        <>
          <LogoutLink type="secondary" to="sellregister">
            Register
          </LogoutLink>
          <LogoutLink type="primary" to="login">
            Login
          </LogoutLink>
        </>
      )}

      {isSignedIn && (
        <>
          <MenuLink type="secondary" to="/myaccount">
            My account
          </MenuLink>
          <LogoutLink type="primary" to="/logout">
            Logout
          </LogoutLink>
        </>
      )}
    </motion.aside>
  );
};

const MenuLink = styled(Link)`
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 12px;
  text-align: left;
`;
const LogoutLink = styled(Link)`
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 12px;
  text-align: left;
`;

const Hover = styled.div`
  :hover {
    cursor: pointer;
  }
`;
