import { COLORS } from "../constants/colors";

export type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "bluebg"
  | "blueborder";

export const getButtonBorderColor = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return "#7f7f7f59";
    case "secondary":
      return "#7f7f7f";
    case "tertiary":
      return "white";
    case "bluebg":
      return "#7f7f7f";
    case "blueborder":
      return "#7f7f7f";
    default:
      return "white";
  }
};

export const getButtonColor = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return "white";
    case "tertiary":
      return "#7f7f7f";
    case "bluebg":
      return "white";
    case "blueborder":
      return "#7f7f7f";
    default:
      return "#7f7f7f";
  }
};

export const getButtonBackgroundColor = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return "#7f7f7f";
    case "bluebg":
      return "#7f7f7f";
    default:
      return "white";
  }
};

export const getButtonHoveredBackgroundColor = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return "white";
    case "secondary":
      return "#7f7f7f";
    case "bluebg":
      return "white";
    case "blueborder":
      return "#7f7f7f";
    case "tertiary":
    default:
      return "white";
  }
};

export const getButtonHoveredColor = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return "#7f7f7f";
    case "secondary":
      return "white";
    case "tertiary":
      return "#7f7f7f";
    case "bluebg":
      return "#7f7f7f";
    default:
      return "white";
  }
};
