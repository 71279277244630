import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 92px 1fr 200px;
`;

export const Layout = (props: any) => {
  return <Wrapper>{props.children}</Wrapper>;
};

const BodyFullwidth = styled.div`
  max-width: 100vw;
  display: block;
`;

export const LayoutBodyFullwidth = (props: any) => {
  return <BodyFullwidth>{props.children}</BodyFullwidth>;
};

const Body = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

export const LayoutBody = (props: any) => {
  return <Body>{props.children}</Body>;
};
