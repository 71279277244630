import React from "react";
import { BounceLoader } from "react-spinners";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { IListing } from "../hooks/useListing";
import { ListingTilePreview } from "./ListingTilePreview";
import { SearchResultsNone } from "./SearchResultsNone";

export const ListingsGridWithSideContent = (props: {
  children: React.ReactNode;
  listings: IListing[];
  isLoading?: boolean;
}) => {
  const hasListings = props.listings.length > 0;
  return (
    <Wrapper>
      <div>{props.children}</div>
      <ListingsArea>
        {props.listings.map((listing: IListing) => (
          <ListingTilePreview key={listing.id.uuid} listing={listing} />
        ))}
        {props.isLoading && <BounceLoader />}
        {!hasListings && !props.isLoading && (
          <SearchResultsNone
            type="search"
            text="Oops, we don't seem to have any results for that!"
          />
        )}
      </ListingsArea>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${up("md")} {
    margin-left: 24px;
    display: grid;
    grid-column-gap: 40px;
    margin-bottom: 120px;
    grid-template-columns: 300px 1fr;
  }

  ${up("lg")} {
    margin-left: 0;
  }
`;
const ListingsArea = styled.div`
  display: grid;
  grid-row-gap: 20px;
  /* column-gap: 20px; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: min-content;
  justify-items: left;
  margin-left: 24px;
  ${up("md")} {
    justify-items: left;
    margin-top: -80px;
    margin-left: 0;
  }
  ${up("lg")} {
    margin-top: 0;
  }
`;
