import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { AccountDetailsGrid } from "../components/AccountDetailsGrid";
import { Button } from "../components/Button";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { ListingTilePreview } from "../components/ListingTilePreview";
import { MyAccountHero } from "../components/MyAccountHero";
import { MyAccountSidebar } from "../components/MyAccountSidebar";
import { MyFeedbackList } from "../components/MyFeedbackList";
import { MyListingTile } from "../components/MyListingTile";
import { PurchasedListings } from "../components/PurchasedListings";
import { SectionHeading } from "../components/SectionHeading";
import { COLORS } from "../constants/colors";
import { useAuth } from "../hooks/useAuth";
import { IListing, useListing } from "../hooks/useListing";
import { IHistoricListing, useTransaction } from "../hooks/useTransaction";

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const Buttons = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 12px;
  grid-template-columns: 1fr;
  button {
    margin-bottom: 12px;
    max-width: 180px;
  }

  ${up("sm")} {
    grid-template-columns: 1fr 1fr;
    button {
      margin-bottom: initial;
      max-width: initial;
    }
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-column-gap: 40px;
    font-size: 12px;
    margin-bottom: 56px;
  }
`;

const AccountInfoWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: center;

  ${up("sm")} {
    grid-template-columns: 1fr 20%;
    margin-bottom: 40px;
    margin-top: 40px;
    justify-items: initial;
  }
`;

const Listings = styled.div`
  display: grid;
  justify-items: center;
  ${up("lg")} {
    justify-items: left;
  }
  grid-template-columns: repeat(auto-fit, minmax(261px, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-bottom: 50px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  button {
    font-size: 0.7rem;
  }

  ${up("lg")} {
    button {
      font-size: initial;
    }
    margin-left: 40px;
  }
`;

const InfoImage = styled.img`
  display: flex;
  flex-direction: column;
  object-fit: scale-down;
  width: 119px;
  height: 89px;
`;

const Delimiter = styled.div`
  height: 1px;
  margin-bottom: 56px;
  width: 100%;
  background-color: ${COLORS.NAVY_BLUE};
`;

export const Account = () => {
  return <Wrapper />;
};

export const Wrapper = () => {
  const { id } = useParams();
  const isMyAccount = !id;
  return (
    <>
      <div>
        {/* {isMyAccount && <MyAccountHero>My account</MyAccountHero>} */}
        <LayoutBodyFullwidth>
          <LayoutBody>
            <ContentWrapper>
              <MyAccountSidebar />
              <Content />
            </ContentWrapper>
          </LayoutBody>
        </LayoutBodyFullwidth>
      </div>
    </>
  );
};

const PapersIcon = () => (
  <img src="/assets/images/papersicon.svg" alt="Papers Icon" />
);
const FeedbackIcon = () => (
  <img src="/assets/images/feedbackicon.svg" alt="Feedback Icon" />
);

const AccountInfo = () => {
  const { id } = useParams();
  const { getUserById, usersByIdImageURL } = useAuth();
  const isMyAccount = !id;
  const navigate = useNavigate();
  const { currentUserImageURL, openFileSelector } = useAuth();

  const userImageUrl = isMyAccount
    ? currentUserImageURL
    : usersByIdImageURL[id];

  useEffect(() => {
    if (id) {
      getUserById(id);
    }
  }, [getUserById, id]);

  return (
    <>
      <AccountInfoWrapper>
        <AccountDetailsGrid />
        <InfoImage src={userImageUrl ?? "/assets/images/teddy.png"} />
      </AccountInfoWrapper>
      {isMyAccount && (
        <Buttons>
          <Button
            buttonType="button"
            onClick={() => {
              navigate("/sell");
            }}
            type="secondary"
            text="SELL SOMETHING NOW"
          />
          <Button
            buttonType="button"
            onClick={() => {
              navigate("/mydetails");
            }}
            type="secondary"
            text="UPDATE MY DETAILS"
          />
          <Button
            buttonType="button"
            onClick={() => {
              navigate("/myfeedback");
            }}
            type="secondary"
            text="VIEW MY FEEDBACK"
          />

          <Button
            buttonType="button"
            onClick={() => {
              openFileSelector();
            }}
            type="secondary"
            text="CHANGE PROFILE PHOTO"
          />
        </Buttons>
      )}
    </>
  );
};

const HeaderButtonWrapper = styled.div`
  width: 425px;
  max-width: 100%;
`;

const Content = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isMyAccount = !id;

  // If not my account we need to get details for the user with this ID to show their stuff here.

  return (
    <Main>
      <AccountInfo />
      <Delimiter />
      <SectionHeading
        icon={<PapersIcon />}
        title={isMyAccount ? `My listings` : "Listings"}
      >
        <HeaderButtonWrapper>
          {isMyAccount ? (
            <Button
              buttonType="button"
              onClick={() => {
                navigate("/mylistings");
              }}
              type="secondary"
              text="See all of my listings"
            />
          ) : (
            <></>
          )}
        </HeaderButtonWrapper>
      </SectionHeading>

      <MyListings userId={id} />
      {isMyAccount && (
        <>
          <Delimiter />
          <SectionHeading icon={<FeedbackIcon />} title="My purchases">
            <HeaderButtonWrapper>
              <Button
                buttonType="button"
                onClick={() => {
                  navigate("/mypurchases");
                }}
                type="secondary"
                text="See all of my purchases"
              />
            </HeaderButtonWrapper>
          </SectionHeading>

          <PurchasedListings hideError />
        </>
      )}
      <Delimiter />
      <SectionHeading title={isMyAccount ? "My feedback" : "Feedback"}>
        <HeaderButtonWrapper>
          {isMyAccount && (
            <Button
              text="SEE ALL OF MY FEEDBACK"
              type="secondary"
              buttonType="button"
              onClick={() => navigate("/myfeedback")}
            />
          )}
          {!isMyAccount && <></>}
        </HeaderButtonWrapper>
      </SectionHeading>
      <MyFeedbackList />
    </Main>
  );
};

const MyListings = ({ userId }: { userId?: string }) => {
  const isOtherUser = userId !== undefined;
  const { myListings, getMyListings, getListingsByUserId, listingsByUserId } =
    useListing();
  const { soldListings } = useTransaction();
  useEffect(() => {
    if (isOtherUser) {
      getListingsByUserId(userId);
    } else {
      getMyListings();
    }
  }, [getMyListings, getListingsByUserId, userId]);

  const publishedListings = myListings.filter(
    (listing: IListing) => listing.attributes.state === "published"
  );

  return (
    <Listings>
      {isOtherUser &&
        listingsByUserId.map((listing: IListing) => (
          <ListingTilePreview
            key={listing.id.uuid + "listing"}
            listing={listing}
          />
        ))}
      {!isOtherUser &&
        publishedListings.map((listing: IListing) => (
          <MyListingTile
            key={listing.id.uuid + "purchased"}
            listing={listing}
          />
        ))}
      {!isOtherUser &&
        soldListings.map((listing: IHistoricListing) => (
          <MyListingTile
            sold
            transactionStep={listing.nextFeedbackTransition}
            transactionId={listing.transaction.id.uuid}
            canGiveFeedback={listing.canGiveFeedback}
            key={listing.id.uuid + listing.transaction.id.uuid + "sold"}
            listing={listing}
          />
        ))}
    </Listings>
  );
};
