import { IUser } from "../components/AuthProvider";
import { IListing, IMetaData } from "../hooks/useListing";

export const getUsernameForListing = (
  listing: IListing,
  allMetadata: { [key: string]: IMetaData }
): string => {
  try {
    return (
      (allMetadata[listing.relationships.author.data.id.uuid] as IUser)
        ?.attributes?.profile.displayName ?? "username not found"
    );
  } catch (error: any) {
    console.error("Error finding username. ", error);
    return "username not found";
  }
};
