import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SuccessText } from "../components/SuccessText";
import { useAuth } from "../hooks/useAuth";

export const VerifyEmail = () => {
  const { verifyEmail } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("t");
  const [hasVerified, setHasVerified] = useState(false);

  useEffect(() => {
    if (token && !hasVerified) {
      verifyEmail(token, () => {
        setHasVerified(true);
      });
    }
  }, [verifyEmail, token, setHasVerified, hasVerified]);

  return (
    <div>
      {!hasVerified && <div>Verifying email, please wait...</div>}
      {hasVerified && (
        <SuccessText
          title="Email verified"
          description="Email successfuly verified. Feel free to leave this page."
        />
      )}
    </div>
  );
};
