import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { useAuth } from "../hooks/useAuth";

// This is a funny page, we only need it to logout the user but it shouldn't ever actually really be showing up :)
export const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout(() => {
      navigate("/");
    });
  }, []);

  // TODO make this look nice in the unlikely event that it's actually showing up
  return (
    <div>
      Logging you out...
      <BounceLoader />
    </div>
  );
};
