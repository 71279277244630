import * as BiIcons from "react-icons/bi";

// const HamburgerIcon = styled.link`
//   width: 30px;
//   height: 30px;
//   margin-right: -20px;
//   margin-top: 5px;
// `;

export const Hamburger = (props: any) => {
  return <BiIcons.BiMenuAltRight {...props} color="#304669" size="35px" />;
};
