import { useCallback, useEffect } from "react";
import Loader from "react-spinners/BarLoader";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";
import { COLORS } from "../constants/colors";
import { IImage } from "../hooks/useImage";
import { IListing, useListing } from "../hooks/useListing";
import { getAllImageIdsFromListingObject } from "../utils/images";
import { Button } from "./Button";
import { H2, H4 } from "./Header";
import { SectionHeading } from "./SectionHeading";

const UploadContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  column-gap: 2rem;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

interface IImagePickerProps {
  existingListing?: IListing;
}

const ImageGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${up("md")} {
    display: grid;
    justify-content: left;
    border: solid 1px ${COLORS.DARK_CHARCOAL_GREY};
    /* width: 742px; */
    max-width: 100%;
    grid-template-columns: min-content min-content min-content;

    grid-gap: 16px;
    padding: 16px;
    min-height: 422px;
  }
`;

const Thumbnail = styled.img`
  width: 228px;
  height: 190px;
  object-fit: scale-down;
`;

const RemoveImageButton = styled.img`
  color: ${COLORS.NAVY_BLUE};
`;

const TrashBackground = styled.div`
  background-color: #ffffffa5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 5px;
  right: 5px;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${up("md")} {
    flex-direction: row;
    justify-content: space-between;
  }
  ${up("lg")} {
    flex-direction: column;
    margin-right: 40px;
  }
`;

const ImageThumbnail = (props: {
  imageUrl: string;
  onRemove: VoidFunction;
}) => {
  return (
    <div style={{ position: "relative", width: "228px", height: "190px" }}>
      <Thumbnail src={props.imageUrl} />
      <TrashBackground>
        <RemoveImageButton
          width={25}
          height={25}
          src="/assets/images/Trash.svg"
          onClick={props.onRemove}
        />
      </TrashBackground>
    </div>
  );
};

const MAX_IMAGE_COUNT = 5;

const Icon = styled.img`
  width: 35px;
  height: 35px;
`;

export const ImagePicker = ({ existingListing }: IImagePickerProps) => {
  const {
    uploadImage,
    newListingImages,
    removeNewListingImage,
    addNewListingImages,
    imagesCurrentlyUploading,
    allMetadata,
    removeAllImages,
  } = useListing();

  const [openFileSelector, { plainFiles, clear, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: true,
    limitFilesConfig: useCallback(() => {
      return { min: 1, max: MAX_IMAGE_COUNT - newListingImages.length };
    }, [newListingImages])(),
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      // maxHeight: 900, // in pixels
      // maxWidth: 1600,
      //   minHeight: 600,
      //   minWidth: 768,
      minHeight: 0,
      minWidth: 0,
    },
  });

  const addAllImagesFromExistingListing = useCallback(() => {
    if (existingListing) {
      const allExistingListingImages = getAllImageIdsFromListingObject(
        existingListing
      ).map((imageId: string) => allMetadata[imageId] as IImage);
      addNewListingImages(allExistingListingImages);
    }
  }, [existingListing, allMetadata, addNewListingImages]);

  // Uploads each image that is selected by the file selector.
  useEffect(() => {
    plainFiles.forEach((file: any) => {
      uploadImage(file);
    });
  }, [plainFiles, uploadImage]);

  useEffect(() => {
    addAllImagesFromExistingListing();
  }, [addAllImagesFromExistingListing]);

  useEffect(() => {
    return () => {
      removeAllImages();
    };
  }, [removeAllImages]);

  if (errors.length) {
    return (
      <div>
        <button onClick={() => openFileSelector()}>
          Something went wrong, retry!
        </button>
        {errors[0].fileSizeTooSmall && "File size is too small!"}
        {errors[0].fileSizeToolarge && "File size is too large!"}
        {errors[0].readerError && "Problem occured while reading file!"}
        {errors[0].maxLimitExceeded && "Too many files"}
        {errors[0].minLimitNotReached && "Not enought files"}
      </div>
    );
  }

  const canUpload =
    newListingImages.length + imagesCurrentlyUploading < MAX_IMAGE_COUNT;

  return (
    <>
      <SectionHeading
        icon={<Icon src="/assets/images/searchicon.svg" />}
        title="Listing photos"
      >
        <></>
      </SectionHeading>
      <Subtitle>
        Upload photos of your items here. Try to make sure they're taken in good
        light, are not blurry; and show all the features and condition of your
        item. You can add up to 5 photos. You can add listing details and price
        next.
      </Subtitle>
      <UploadContainer>
        <ContentBeforeBox>
          <H2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "left",
              marginBottom: "16px",
              color: COLORS.DARK_ORANGE,
              marginTop: 0,
            }}
          >
            Upload photos
          </H2>
          <p
            style={{
              textAlign: "left",
              color: COLORS.DARK_ORANGE,
              fontSize: "16px",
            }}
          >
            You can upload photos from your computer.
          </p>
          <br />
          {/* For each key of the fileToImageIdMap
          map the key to the value and display the image thumbnail */}
          {canUpload && (
            <ButtonWrapper>
              <div style={{ width: "288px", marginBottom: "16px" }}>
                <Button
                  onClick={() => openFileSelector()}
                  type="secondary"
                  buttonType="button"
                  text="CLICK HERE TO UPLOAD"
                />
              </div>
              <div style={{ width: "288px", marginBottom: "16px" }}>
                <Button
                  onClick={removeAllImages}
                  type="primary"
                  buttonType="button"
                  text="REMOVE ALL PHOTOS"
                />
              </div>
            </ButtonWrapper>
          )}
        </ContentBeforeBox>
        <div>
          <ImageGrid>
            {newListingImages.map((image: IImage) => {
              return (
                <ImageThumbnail
                  key={image.id.uuid}
                  imageUrl={image.attributes.variants.default.url}
                  onRemove={() => {
                    removeNewListingImage(image);
                  }}
                />
              );
            })}
            {/* Render component equal to count of imagescurrentlyuploading */}
            {Array.from(Array(imagesCurrentlyUploading)).map(
              (_, index: number) => {
                return <Loader key={index} />;
              }
            )}
          </ImageGrid>
        </div>
      </UploadContainer>
    </>
  );
};

const Subtitle = styled.p`
  color: #304669;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 48px;

  margin-left: 24px;
  margin-right: 24px;

  ${up("md")} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const ContentBeforeBox = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  ${up("md")} {
    margin-left: 0;
    margin-right: 0;
  }
`;
