import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IListing, useListing } from "../hooks/useListing";
import { useTransaction } from "../hooks/useTransaction";
import { formatPriceFromPriceObject } from "../utils/price";
import { getUsernameForListing } from "../utils/users";
import { Button } from "./Button";
import { H1, H4 } from "./Header";
import { RequireAuth } from "./RequireAuth";

const SuccessfulPurchaseWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 60px;
  margin-right: 60px;
`;

const SuccessfulPurchaseHeroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessfulPurchaseHeroButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  width: 640px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonItem = styled.div`
  height: 63px;
  width: 288px;
`;

export const ConfirmPurchaseSection = (props: {
  listing: IListing;
  onCancelPurchase: () => void;
}) => {
  const navigate = useNavigate();
  const { allMetadata } = useListing();
  const { purchaseListingById } = useTransaction();

  const onPurchaseSuccess = () => {
    navigate("/successfulpurchase");
  };

  return (
    <RequireAuth>
      <SuccessfulPurchaseWrapper>
        <SuccessfulPurchaseHeroText>
          <H1
            style={{
              color: "#304669",
              fontSize: "40px",
              fontWeight: "bold",
            }}
          >
            Confirm your purchase of {props.listing.attributes.title}
          </H1>
          <H4
            style={{
              color: "#304669",
              fontSize: "30px",
              fontWeight: "regular",
              textAlign: "center",
              marginTop: "30px",
              lineHeight: "40px",
            }}
          >
            You are about to purchase this item for{" "}
            {formatPriceFromPriceObject(props.listing.attributes.price)} from{" "}
            {getUsernameForListing(props.listing, allMetadata)}. If you{" "}
            <br></br>
            want to proceed with this purchase, click confirm below.
          </H4>
          <H4
            style={{
              color: "#8A0000",
              fontSize: "14px",
              fontWeight: "500",
              textAlign: "left",
              fontStyle: "italic",
              marginTop: "30px",
            }}
          >
            If you confirm this purchase, you will be legally obligated to
            proceed with the sale. If you do not wish to proceed, click the
            Cancel button.
          </H4>
        </SuccessfulPurchaseHeroText>
        <SuccessfulPurchaseHeroButtons>
          <ButtonItem>
            <Button
              buttonType="button"
              type="secondary"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                purchaseListingById(props.listing.id.uuid!, onPurchaseSuccess);
              }}
              text="Confirm Purchase"
            />
          </ButtonItem>
          <ButtonItem>
            <Button
              buttonType="button"
              onClick={props.onCancelPurchase}
              type="primary"
              text="Cancel Purchase"
            />
          </ButtonItem>
        </SuccessfulPurchaseHeroButtons>
      </SuccessfulPurchaseWrapper>
    </RequireAuth>
  );
};
