import "@reach/listbox/styles.css";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { MyAccountHero } from "../components/MyAccountHero";
import { MyAccountSidebar } from "../components/MyAccountSidebar";
import { PurchasedListings } from "../components/PurchasedListings";

const Content = styled.div`
  display: grid;
  ${up("lg")} {
    grid-template-columns: 1fr 4fr;
  }
  grid-column-gap: 40px;
  margin-bottom: 60px;
`;

export const MyPurchases = () => {
  return (
    <LayoutBodyFullwidth>
      <MyAccountHero>My purchases</MyAccountHero>
      <LayoutBody>
        <Content>
          <MyAccountSidebar />
          <PurchasedListings />
        </Content>
      </LayoutBody>
    </LayoutBodyFullwidth>
  );
};

export default MyPurchases;
