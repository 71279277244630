export enum COLORS {
  LIGHT_GREY = "#FEFBFA",
  BABY_BLUE = "#F1F9FF",
  OFF_WHITE = "#D16D3D08",
  NAVY_BLUE = "#304669",
  OFF_WHITE2 = "#FF4A1C",
  DARK_ORANGE = "#FF4A1C",
  LIGHT_CHARCOAL_GREY = "#95989A",
  LIGHT_BLUE = "#BCE0FD",
  OFF_WHITE3 = "#FF4A1C",
  LIGHT_PINK = "#FBF0EB",
  DARK_CHARCOAL_GREY = "#353B3C",
  BURGUNDY_RED = "#FF4A1C",
  NEW_BRIGHT = "#FF4A1C",
  NEW_DARK = "#353B3C",
  NEW_GREY = "#95989A",
  PRIMARY = "#2AB3FB",

  SECONDARY = "#1E3AFD",
  GRADIENT = "linear-gradient(89.84deg, rgba(42, 179, 251, 0.84) 0.15%, rgba(30, 58, 253, 0.84) 99.88%)",
  BLACK = "#282D2F",
  BLACK75 = "#282D2F75",
  BLACK50 = "#282D2F50",
  BLACK25 = "#282D2F25",
  BLACK10 = "#282D2F10",
  BLACK5 = "#282D2F05",
}

export const NEW_BRIGHT = "#FF4A1C";
export const NEW_DARK = "#353B3C";
export const NEW_GREY = "#95989A";

// #FF4A1C
// #353B3C
// #8D80AD
//
