interface IPrice {
  amount: number;
}

export const formatPriceFromPriceObject = (price: IPrice | undefined) => {
  // Round price to 2 decimal places
  if (!price) {
    return "FREE";
  }

  const priceInDollars = price.amount / 100;
  const roundedPrice = priceInDollars.toFixed(2);
  const formattedPrice = `$${roundedPrice}`;
  return formattedPrice;
};
