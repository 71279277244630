import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import {
  ALL_CATEGORIES,
  ICategory,
  ISubCategory,
} from "../constants/categories";
import { CONDITIONS } from "../constants/conditions";
import { ALL_LOCATIONS } from "../constants/locations";
import { SORT_BY, SORT_BY_TEXT } from "../constants/sort";
import { useSearch } from "../hooks/useSearch";
import { Button } from "./Button";
import { LayoutBody, LayoutBodyFullwidth } from "./Layout";
import { ListingsGridWithSideContent } from "./ListingsGridWithSidecontent";
import { SectionHeading } from "./SectionHeading";

const ButtonItem = styled.div`
  width: 300px;
  height: 63px;
  margin-bottom: 20px;
`;

const ListingsSidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 24px;

  ${up("md")} {
    margin-left: 0;
  }
  ${up("lg")} {
    align-items: center;
  }
`;

const SideBarButtons = styled.div``;

const SearchDropdownFilter = styled.select`
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 50px;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }
`;

const TextInput = styled.input`
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 50px;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

interface IFormInputs {
  category: ICategory;
  subCategory: string;
  condition: CONDITIONS;
  location: ALL_LOCATIONS;
  sort: SORT_BY;
}

export const SearchSection = () => {
  const { searchResults, setSortBy, isLoading } = useSearch();
  const { register, watch } = useForm<IFormInputs>();
  const sort = watch("sort");

  useEffect(() => {
    setSortBy(sort);
  }, [setSortBy, sort]);

  return (
    <LayoutBodyFullwidth>
      <LayoutBody>
        <SectionHeading title="Search">
          <SearchDropdownFilter {...register("sort")}>
            <option value={undefined}>Sort by price</option>
            {Object.keys(SORT_BY).map((sort: any) => (
              <option key={sort + "sort"} value={sort}>
                {SORT_BY_TEXT[sort as SORT_BY]}
              </option>
            ))}
          </SearchDropdownFilter>
        </SectionHeading>
        <ListingsGridWithSideContent
          isLoading={isLoading}
          listings={searchResults}
        >
          <Sidebar />
        </ListingsGridWithSideContent>
      </LayoutBody>
    </LayoutBodyFullwidth>
  );
};

interface ISidebarFormInputs {
  category: ICategory;
  subCategory: ISubCategory;
  condition: CONDITIONS;
  location: ALL_LOCATIONS;
  keyword: string;
}

const Sidebar = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<ISidebarFormInputs>();
  const {
    setKeyword,
    setLocation,
    setCategory,
    setCondition,
    setSubCategory,
    resetSearch,
  } = useSearch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [subCats, setSubCategories] = useState<ISubCategory[]>([]);

  const keywords = searchParams.get("keywords");
  useEffect(() => {
    if (keywords) {
      setKeyword(keywords);
    }
  }, [keywords, setKeyword]);

  // Watch the category selection
  const rawCategory = watch("category");
  const rawSubCategory = watch("subCategory");

  const condition = watch("condition");
  const location = watch("location");

  const onKeywordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
    },
    [setKeyword]
  );

  useEffect(() => {
    if (rawCategory) {
      const parsedCat = JSON.parse(rawCategory as any as string);
      setCategory(parsedCat);
      setSubCategories(parsedCat.subCategories);
    } else {
      setCategory(undefined as any as ICategory);
      setSubCategory(undefined as any as ICategory);
    }
    if (rawSubCategory) {
      setSubCategory(JSON.parse(rawSubCategory as any as string));
    } else {
      setSubCategory(undefined as any as ICategory);
    }
  }, [rawCategory, rawSubCategory, setCategory, setSubCategory]);

  useEffect(
    () => {
      setCondition(condition);
      setLocation(location);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [condition, location]
  );

  const onSubmit = useCallback(
    // Stricly speaking we don't need this since it kind of duplicates the use effects that automatically update the filters anyways..
    (data: any) => {
      setLocation(data.location);
      setCategory(
        data.category.length > 0 ? JSON.parse(data.category) : undefined
      );
      setCondition(data.condition);
      setSubCategory(
        data.subCategory.length > 0 ? JSON.parse(data.subCategory) : undefined
      );
    },
    [setLocation, setCategory, setCondition, setSubCategory]
  );
  return (
    <ListingsSidebar>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          placeholder="Keywords"
          {...(register("keyword"), { onChange: onKeywordChange })}
        />
        <SearchDropdownFilter {...register("condition")}>
          <option value={""}>Condition</option>
          {Object.keys(CONDITIONS).map((cond: any) => (
            <option key={cond} value={cond}>
              {CONDITIONS[cond as keyof typeof CONDITIONS]}
            </option>
          ))}
        </SearchDropdownFilter>

        <SearchDropdownFilter {...register("category")}>
          <option value="">Select Category</option>
          {ALL_CATEGORIES.map((category: ICategory) => (
            <option
              key={category.apiName + "conditioninput"}
              value={JSON.stringify(category)}
            >
              {category.name}
            </option>
          ))}
        </SearchDropdownFilter>
        {subCats && subCats.length > 0 && (
          <>
            <SearchDropdownFilter
              disabled={!subCats || !(subCats.length > 0)}
              {...register("subCategory")}
            >
              <option value="">Select Sub Category</option>
              {/* Make an option for each string value for the SUBCATEGORY enum */}
              {subCats &&
                subCats.map((subCategory: ISubCategory) => (
                  <option
                    key={subCategory.apiName + "subcatinput"}
                    value={JSON.stringify(subCategory)}
                  >
                    {subCategory.name}
                  </option>
                ))}
            </SearchDropdownFilter>
          </>
        )}
        <SearchDropdownFilter {...register("location")}>
          <option value={""}>Location</option>
          {Object.keys(ALL_LOCATIONS).map((location: any) => (
            <option key={location} value={location}>
              {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
            </option>
          ))}
        </SearchDropdownFilter>
        <SideBarButtons>
          <ButtonItem>
            <Button
              onClick={handleSubmit(onSubmit)}
              buttonType="button"
              type="secondary"
              text="SEARCH"
            />
          </ButtonItem>
          <ButtonItem>
            <Button
              onClick={() => {
                resetSearch();
              }}
              type="secondary"
              buttonType="reset"
              text="CLEAR ALL FILTERS"
            />
          </ButtonItem>
        </SideBarButtons>
      </Form>
    </ListingsSidebar>
  );
};
