import React from "react";
import { IUser } from "../components/AuthProvider";
export interface IImage {
  id: { uuid: string };
  attributes: {
    variants: {
      default: {
        width: number;
        height: number;
        url: string;
      };
    };
  };
}
export interface IListing {
  id: { uuid: string };
  attributes: {
    title: string;
    state?: "draft" | "published" | "pendingApproval" | "closed";
    description: string;
    price: { amount: number };
    createdAt: string;
    publicData: {
      featured: boolean;
      location: string;
      category: string;
      subCategory: string;
      condition: string;
      postage: string;
    };
  };
  relationships: {
    images: {
      data: IImage[];
    };
    author: { data: IUser };
  };
}

export type IMetaData = IImage | IUser;

interface ListingContextType {
  isLoading: boolean;
  createListing: (data: any, callback: (listingId: string) => void) => void;
  uploadImage: (image: File) => void;
  removeAllImages: () => void;
  addNewListingImages: (images: IImage[]) => void;
  removeNewListingImage: (image: IImage) => void;
  publishDraftListing: (listingId: string, callback: VoidFunction) => void;
  getMyListings: VoidFunction;
  getPublicListings: VoidFunction;
  updateListing: (data: any, callback: (listingId: string) => void) => void;
  myListings: IListing[];
  publicListings: IListing[];
  listingsByIds: IListing[];
  allMetadata: { [key: string]: IMetaData };
  storeMetadata: (metadata: IMetaData[]) => void;
  newListingImages: IImage[];
  getListingById: (listingId: string) => IListing | undefined;
  getListingsByIds: (listingIds: string[]) => IListing[];
  listingById: IListing | null;
  getOwnListingById: (listingId: string) => void;
  ownListingById: IListing | null;
  featuredListings: IListing[];
  getFeaturedListings: VoidFunction;
  imagesCurrentlyUploading: number;
  closeListing: (listingId: string, callback: VoidFunction) => void;
  getListingsByUserId: (userId: string) => void;
  listingsByUserId: IListing[];
}

export const ListingContext = React.createContext<ListingContextType>(null!);
export const useListing = () => React.useContext(ListingContext);

// export const MockListing = (): IListing => ({
//   id: { uuid: `${Math.random() * 1000}` },
//   attributes: {
//     title: "Mock Listing",
//     description: "Really great listing",
//     price: { amount: 100 },
//     publicData: {
//       featured: false,
//       location: "London",
//       category: "Fashion",
//       subCategory: "Clothing",
//       condition: "New",
//       postage: "Free",
//     },
//   },
//   relationships: {
//     images: {
//       data: [MockImage()],
//     },
//     author: MockUser(),
//   },
// });
