import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";

const StyledH3 = styled.h3`
  color: #304669;
  font-size: 30px;
  font-weight: normal;
  text-align: left;
  line-height: 25px;

  ${up("lg")} {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Page404 = () => {
  return (
    <LayoutBodyFullwidth>
      <Wrapper>
        <StyledH3>Dang that page doesn't seem to exist :(</StyledH3>
      </Wrapper>
    </LayoutBodyFullwidth>
  );
};

export default Page404;
