import React, { useCallback, useEffect, useState } from "react";
import { useFilePicker } from "use-file-picker";
import { AuthContext, AUTH_ERROR_CODE } from "../hooks/useAuth";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { UUID } = require("sharetribe-flex-sdk").types;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const sharetribeSdk = require("sharetribe-flex-sdk");

export interface IId {
  uuid: string;
}

export interface IUser {
  id: IId;
  attributes: {
    banned?: boolean;
    createdAt: Date;
    deleted?: boolean;
    email: string;
    emailVerified: boolean;
    pendingEmail: boolean | null;
    profile: {
      abbreviatedName: string;
      displayName: string;
      firstName: string;
      lastName: string;
      bio: string | null;
      metadata: any;
      privateData: any;
      protectedData: {
        phoneNumber: string;
      };
      publicData: {
        location: string;
      };
    };
  };
}

// Create new SDK instance
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_CLIENT_ID,
});

export interface ISignupDetails {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  location: string;
  phoneNumber: string;
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<IUser | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [error, setError] = useState<AUTH_ERROR_CODE | null>(null);
  const [usersById, setUsersById] = useState<{ [userId: string]: IUser }>({});
  const [usersByIdImageURL, setUsersByIdImageURL] = useState<{
    [userId: string]: string;
  }>({});
  const [currentUserImageURL, setCurrentUserImageURL] = useState<string | null>(
    null
  );

  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: true,
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      // maxHeight: 900, // in pixels
      // maxWidth: 1600,
      //   minHeight: 600,
      //   minWidth: 768,
      minHeight: 0,
      minWidth: 0,
    },
  });

  const getUser = useCallback(() => {
    sdk.currentUser
      .show({ include: "profileImage" })
      .then((res: any) => {
        setUser(res.data.data);
        if (res.data.included.length > 0) {
          setCurrentUserImageURL(
            res.data.included[0].attributes.variants.default.url
          );
        }
      })
      .catch((error: any) => {
        console.error("Error getting user!", error);
      });
  }, [setUser, setCurrentUserImageURL]);

  const checkIsSignedIn = useCallback(() => {
    sdk
      .authInfo()
      .then((authInfo: any) => {
        const isSignedInCheck = authInfo && authInfo.isAnonymous === false;
        setIsSignedIn(isSignedInCheck);
        setIsLoading(false);
        getUser();
      })
      .catch(() => {
        setIsSignedIn(false);
        setIsLoading(false);
      });
  }, [getUser, setIsSignedIn, setIsLoading]);

  const logout = async (callback: VoidFunction) => {
    setIsLoading(true);
    try {
      await sdk.logout();
      setIsSignedIn(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
    callback();
  };

  const login = async (
    { email, password }: { email: string; password: string },
    callback: VoidFunction
  ) => {
    setIsLoading(true);
    sdk
      .login({ username: email, password })
      .then(() => {
        setIsSignedIn(true);
        setIsLoading(false);
        getUser();
        callback();
      })
      .catch((error: any) => {
        console.error(error);
        setError(AUTH_ERROR_CODE.INVALID_PASSWORD_OR_USER);
        setIsLoading(false);
      });
  };

  const signup = (
    {
      displayName,
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
      location,
    }: ISignupDetails,
    callback: VoidFunction
  ) => {
    sdk.currentUser
      .create(
        {
          email,
          password,
          firstName,
          lastName,
          displayName,
          publicData: {
            location,
          },
          protectedData: {
            phoneNumber,
            email,
          },
        },
        {
          expand: true,
        }
      )
      .then(async () => {
        await login({ email, password }, () => {
          callback();
        });
      })
      .catch((res: any) => {
        // An error occurred
        console.error(
          `Request failed with status: ${res.status} ${res.statusText}`
        );
      });
  };

  const changePassword = useCallback(
    (
      currentPassword: string,
      newPassword: string,
      callback: (error: any | undefined) => void
    ) => {
      sdk.currentUser
        .changePassword(
          {
            currentPassword,
            newPassword,
          },
          {
            expand: true,
          }
        )
        .then(() => {
          callback(undefined);
        })
        .catch((error: any) => {
          callback(error);
          console.error(error);
        });
    },
    []
  );

  const requestPasswordReset = useCallback(
    (email: string, callback: VoidFunction) => {
      sdk.passwordReset
        .request(
          {
            email,
          },
          {}
        )
        .then(() => {
          // res.data
          callback();
        })
        .catch((error: any) => {
          // An error occurred
          console.error(error);
        });
    },
    []
  );

  const resetPassword = useCallback(
    (
      token: string,
      newPassword: string,
      email: string,
      callback: VoidFunction
    ) => {
      sdk.passwordReset
        .reset(
          {
            email: email,
            passwordResetToken: token,
            newPassword,
          },
          {}
        )
        .then(() => {
          // res.data
          callback();
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    []
  );
  const updateUserDetails = useCallback((data: any, callback: VoidFunction) => {
    sdk.currentUser
      .updateProfile(
        { ...data },
        {
          expand: true,
          include: ["profileImage"],
        }
      )
      .then(() => {
        callback();
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  const updateProfilePicture = useCallback(
    async (image: File, callback: VoidFunction) => {
      setIsLoading(true);
      sdk.images
        .upload(
          {
            image,
          },
          {
            expand: true,
          }
        )
        .then((res: any) => {
          const newImageId = res.data.data.id.uuid;

          sdk.currentUser
            .updateProfile({
              profileImageId: new UUID(newImageId),
            })
            .then(() => {
              getUser();
              setIsLoading(false);
              callback();
            })
            .catch((error: any) => {
              console.error(error);
              callback();
            });
        })
        .catch(() => {
          setIsLoading(false);
          alert("Error uploading image, please try again.");
        });

      callback();
    },
    []
  );

  const getUserById = useCallback(
    (userId: string) => {
      sdk.users
        .show({ id: new UUID(userId), include: "profileImage" })
        .then((res: any) => {
          setUsersById((prev) => ({ ...prev, [userId]: res.data.data }));

          if (res.data?.included?.length > 0) {
            setUsersByIdImageURL((prev) => ({
              ...prev,
              [userId]: res.data.included[0].attributes.variants.default.url,
            }));
          }
        });
    },
    [setUsersByIdImageURL, setUsersById]
  );

  const verifyEmail = useCallback((token: string, callback: () => void) => {
    sdk.currentUser
      .verifyEmail(
        {
          verificationToken: token,
        },
        {
          expand: true,
        }
      )
      .then(() => {
        callback();
      });
  }, []);

  // Uploads each image that is selected by the file selector.
  useEffect(() => {
    plainFiles.forEach((file: any) => {
      updateProfilePicture(file, () => {
        console.log("Done image upload");
      });
    });
  }, [plainFiles, updateProfilePicture]);

  useEffect(() => {
    checkIsSignedIn();
  }, [checkIsSignedIn]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  const value = {
    user,
    login,
    logout,
    signup,
    isSignedIn,
    isLoading,
    checkIsSignedIn,
    error,
    changePassword,
    requestPasswordReset,
    resetPassword,
    updateUserDetails,
    updateProfilePicture,
    currentUserImageURL,
    openFileSelector,
    getUser,
    getUserById,
    usersById,
    usersByIdImageURL,
    verifyEmail,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
