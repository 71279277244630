import React from "react";
import { up } from "styled-breakpoints";
import styled from "styled-components";

export const SectionHeading = (props: {
  title: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  subTitle?: React.ReactNode;
  titleColor?: string;
}) => {
  return (
    <Wrapper>
      <Row>
        <IconTextWrapper>
          {!props.icon && <Icon src="/assets/images/searchicon.svg" />}
          {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
          <Heading style={{ color: props.titleColor ?? "#304669" }}>
            {props.title}
          </Heading>
        </IconTextWrapper>
        <ChildWrapper>{props.children}</ChildWrapper>
      </Row>
      {props.subTitle && <Row>{props.subTitle}</Row>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-left: 24px;
  margin-right: 24px;

  ${up("lg")} {
    margin-right: 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const ChildWrapper = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 18px;
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  ${up("lg")} {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  margin-right: 24px;
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  ${up("lg")} {
    font-size: 40px;
  }

  font-weight: bold;
  text-align: left;
`;
