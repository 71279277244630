import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { IListing, useListing } from "../hooks/useListing";
import { TRANSITIONS } from "../hooks/useTransaction";
import { getListingDateAsStringFromListing } from "../utils/date";
import { getImageURLByListing } from "../utils/images";
import { formatPriceFromPriceObject } from "../utils/price";
import { getUsernameForListing } from "../utils/users";
import { Button } from "./Button";

interface IMyListingTileProps {
  listing: IListing;
  sold?: boolean;
  soldTo?: string;
  canGiveFeedback?: boolean;
  transactionId?: string;
  transactionStep?: TRANSITIONS;
}

export const MyListingTile = (props: IMyListingTileProps) => {
  const { listing } = props;
  const { allMetadata } = useListing();
  const navigate = useNavigate();
  const imageUrl = getImageURLByListing(listing, allMetadata);

  const editListingClicked = (e: Event) => {
    navigate("/edit-listing/" + listing.id.uuid);
    e.stopPropagation();
  };

  const withdrawListingClicked = (e: Event) => {
    navigate("/withdrawlisting/" + listing.id.uuid);
    e.stopPropagation();
  };

  const clickWrapper = () => {
    navigate("/listing/" + listing.id.uuid);
  };

  const giveFeedbackClicked = (e: Event) => {
    // const transactionStep = getTransactionStepForFeedback(props.listing);
    navigate(
      "/give-feedback/" +
        props.transactionId +
        "?transactionStep=" +
        props.transactionStep +
        "&listingTitle=" +
        props.listing.attributes.title
    );
    e.stopPropagation();
  };

  const dateFormatted = getListingDateAsStringFromListing(listing);
  const formattedPrice = formatPriceFromPriceObject(listing.attributes.price);
  return (
    <Wrapper onClick={clickWrapper}>
      <HeadingArea>
        <Title>{listing.attributes.title}</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Date>
            <i>Listed {dateFormatted}</i>
          </Date>
          <Price>{formattedPrice}</Price>
        </div>
      </HeadingArea>
      <Image src={imageUrl} />

      <ButtonArea>
        {props.sold && <SoldBadge>SOLD</SoldBadge>}
        {props.canGiveFeedback && (
          <Button
            text="GIVE FEEDBACK"
            type="secondary"
            buttonType="button"
            onClick={giveFeedbackClicked}
          />
        )}
        {!props.sold && (
          <>
            <Button
              type="secondary"
              buttonType="button"
              text="EDIT LISTING DETAILS"
              onClick={editListingClicked}
            />
            <Button
              type="secondary"
              buttonType="button"
              text="WITHDRAW LISTING"
              onClick={withdrawListingClicked}
            />
          </>
        )}
      </ButtonArea>
    </Wrapper>
  );
};

export const SoldBadge = styled.div`
  background-color: ${COLORS.NAVY_BLUE};
  color: white;
  width: fit-content;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.2rem 0.52rem;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
  margin-left: auto;
  margin-right: auto;
`;

const Image = styled.img`
  height: 240px;
  object-fit: contain;
  margin-bottom: 12px;
  background-color: white;
  border-top: 1px solid ${COLORS.LIGHT_CHARCOAL_GREY};
  border-bottom: 1px solid ${COLORS.LIGHT_CHARCOAL_GREY};
`;

const Wrapper = styled.div`
  width: 288px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${COLORS.DARK_ORANGE};
  background-color: ${COLORS.LIGHT_GREY};
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  border-radius: 4px;
  border: 1px solid ${COLORS.DARK_CHARCOAL_GREY};
`;

const HeadingArea = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 8px;
`;

const DateTitleArea = styled.div`
  display: flex;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
`;

const Date = styled.h2`
  font-size: 12px;
`;

const Price = styled.h3`
  font-size: 32px;
  font-weight: bold;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  align-self: center;

  button {
    margin-bottom: 20px;
    font-size: 12px;
  }
`;
