/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { ICategory, ISubCategory } from "../constants/categories";
import { CONDITIONS } from "../constants/conditions";
import { ALL_LOCATIONS } from "../constants/locations";
import { SORT_BY } from "../constants/sort";
import { IListing } from "./useListing";

interface SearchContextType {
  setCondition: (condition: CONDITIONS) => void;
  setSortBy: (sortBy: SORT_BY | string) => void;
  setLocation: (location: ALL_LOCATIONS) => void;
  setCategory: (category: ICategory) => void;
  setSubCategory: (subCategory: ISubCategory) => void;
  setKeyword: (keyword: string) => void;
  resetSearch: () => void;
  searchResults: IListing[];
  category: ICategory;
  subCategory: ISubCategory;
  isLoading: boolean;
}

const defaultSearchContext: SearchContextType = {
  setCondition: () => {},
  setSortBy: () => {},
  setLocation: () => {},
  setCategory: () => {},
  setSubCategory: () => {},
  setKeyword: () => {},
  resetSearch: () => {},
  searchResults: [],
  category: { name: "All Categories", apiName: "", subCategories: [] },
  subCategory: { name: "All Sub Categories", apiName: "" },
  isLoading: false,
};

export const SearchContext =
  React.createContext<SearchContextType>(defaultSearchContext);
export const useSearch = () => React.useContext(SearchContext);
