import styled from "styled-components";

export const ErrorList = styled.ul`
  background-color: #b91b1b;
  list-style: none;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  color: white;
  text-align: left;

  li: {
    margin-left: 10px;
  }
`;
