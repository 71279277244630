import { Link } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { MobileFooter } from "./MobileFooter";

const FooterWrapper = styled.div`
  /* background-color: ${COLORS.SECONDARY};
   */
  // #51b2c8
  // #117897
  // create a gradient between these
  line-height: 1.5;
  background-image: linear-gradient(
    180deg,
    rgba(81, 178, 200, 1) 0%,
    rgba(17, 120, 151, 1) 100%
  );
  height: fit-content;
  padding: 40px;

  ${up("lg")} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const FooterLogo = styled.img`
  width: 218px;
  height: 48px;
`;

const FooterListItem = styled.div`
  color: white;
  text-decoration: none;
  :hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

export const Footer = () => {
  return (
    <>
      {/* <MobileFooter /> */}
      <FooterWrapper>
        <LogoContainer>
          <FooterLogo src="/assets/images/bellybutton.svg" />
        </LogoContainer>
        <Col>
          <Heading>Help & Support </Heading>

          <List>
            <FooterListItem>
              <Link to="Faq" style={{ textDecoration: "none", color: "white" }}>
                FAQ
              </Link>
            </FooterListItem>
            {/* <FooterListItem>
              <Link
                to="ContactUs"
                style={{ textDecoration: "none", color: "white" }}
              >
                Contact us
              </Link>
            </FooterListItem> */}
            {/* <FooterListItem>
              <Link
                to="ReportUser"
                style={{ textDecoration: "none", color: "white" }}
              >
                Report a user
              </Link>
            </FooterListItem> */}
            <FooterListItem>
              <Link
                to="TermsConditions"
                style={{ textDecoration: "none", color: "white" }}
              >
                Terms & Conditions
              </Link>
            </FooterListItem>
            <FooterListItem>
              <Link
                to="PrivacyPolicy"
                style={{ textDecoration: "none", color: "white" }}
              >
                Privacy Policy
              </Link>
            </FooterListItem>
            {/* <FooterListItem>
              <Link
                to="contactus"
                style={{ textDecoration: "none", color: "white" }}
              >
                Advertise with us
              </Link>
            </FooterListItem> */}
            <FooterListItem>
              <a
                href="mailto:jonahdevs@gmail.com"
                style={{
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "white",
                }}
              >
                jonahdevs@gmail.com
              </a>
            </FooterListItem>
          </List>
        </Col>
        <Col>
          <Heading>Buy & Sell </Heading>

          <List>
            <FooterListItem>
              <Link
                to="SellRegister"
                style={{ textDecoration: "none", color: "white" }}
              >
                Register
              </Link>
            </FooterListItem>
            <FooterListItem>
              <Link
                to="Login"
                style={{ textDecoration: "none", color: "white" }}
              >
                Login
              </Link>
            </FooterListItem>
            <FooterListItem>
              <Link
                to="Sell"
                style={{ textDecoration: "none", color: "white" }}
              >
                List an item
              </Link>
            </FooterListItem>
            {/* <FooterListItem>
              <Link to="buy" style={{ textDecoration: "none", color: "white" }}>
                Browse
              </Link>
            </FooterListItem> */}
          </List>
        </Col>
        <Col>
          <Heading>My Account </Heading>

          <List>
            <FooterListItem>
              <Link
                to="MyListings"
                style={{ textDecoration: "none", color: "white" }}
              >
                My listings
              </Link>
            </FooterListItem>
            <FooterListItem>
              <Link
                to="MyPurchases"
                style={{ textDecoration: "none", color: "white" }}
              >
                My purchases
              </Link>
            </FooterListItem>
            <FooterListItem>
              <Link
                to="MyFeedback"
                style={{ textDecoration: "none", color: "white" }}
              >
                My feedback
              </Link>
            </FooterListItem>
          </List>
        </Col>

        <NourishedBy>
          <p style={{ fontSize: "12px" }}>© JonahDevs 2023</p>
        </NourishedBy>
      </FooterWrapper>
    </>
  );
};

const Heading = styled.div`
  display: none;
  color: white;
  font-weight: bold;
  margin-bottom: 12px;

  ${up("xl")} {
    display: block;
  }
`;
const List = styled.div`
  div {
    margin-bottom: 6px;
  }
`;
const NourishedBy = styled.div`
  color: white;
  text-align: right;
`;
const Col = styled.div`
  text-align: left;
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const LogoContainer = styled.div``;
