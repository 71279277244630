import { useForm } from "react-hook-form";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { Button } from "./Button";
import { COLORS } from "../constants/colors";
import { useEffect, useState } from "react";

interface IContactSellerModalProps {
  senderId?: string;
  recipientId: string;
  toggle: VoidFunction;
  showDialog: boolean;
  listingName: string;
}

// const POST_URL = "https://hooks.zapier.com/hooks/catch/12265884/bkvewty/";
// const POST_URL =
//   "https://z2zbalwwef.execute-api.ap-southeast-2.amazonaws.com/prod/";
const POST_URL =
  "https://7kv7znnkx8.execute-api.ap-southeast-2.amazonaws.com/prod/contact-user";

export const ContactSellerModal = (props: IContactSellerModalProps) => {
  const { senderId, recipientId, toggle, showDialog, listingName } = props;
  const { register, handleSubmit, reset } = useForm();
  const [successShowing, setSuccessShowing] = useState(false);
  useEffect(() => {
    reset();
    setSuccessShowing(false);
  }, [showDialog]);

  const onSubmit = (data: any) => {
    if (!senderId || !recipientId || !data.message) {
      alert("Please log in to send messages.");
      return;
    }
    fetch(POST_URL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        senderId,
        recipientId,
        message: data.message,
        listingName,
      }),
    }).then(() => {
      setSuccessShowing(true);
    });
  };
  return (
    <Dialog
      style={{
        marginTop: "400px",
        width: "600px",
        maxWidth: "100vw",
        borderRadius: "10px",
      }}
      isOpen={showDialog}
      onDismiss={toggle}
    >
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {successShowing && (
          <div
            style={{
              fontWeight: "bold",
              color: COLORS.DARK_ORANGE,
              marginBottom: "20px",
            }}
          >
            Your message has been submitted successfuly
          </div>
        )}
        {!successShowing && (
          <>
            <label style={{ fontWeight: "bold", color: COLORS.DARK_ORANGE }}>
              Send a short question about this listing to the seller.<br></br>
              <br></br> The seller will be able to reply to you directly via
              email.
            </label>
            <textarea
              style={{
                height: "80px",
                marginBottom: "20px",
                marginTop: "12px",
              }}
              {...register("message", { required: true })}
            />
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {!successShowing && (
            <div style={{ width: "130px" }}>
              <Button type="primary" text="Send" buttonType="submit" />
            </div>
          )}
          <div style={{ width: "130px" }}>
            <Button
              onClick={toggle}
              type="secondary"
              text={successShowing ? "Close" : "Cancel"}
              buttonType="button"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};
