import { useNavigate } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { AccountDetailsGrid } from "../components/AccountDetailsGrid";
import { Button } from "../components/Button";
import { H1, H4 } from "../components/Header";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { MyAccountHero } from "../components/MyAccountHero";
import { MyAccountSidebar } from "../components/MyAccountSidebar";
import { useAuth } from "../hooks/useAuth";

const MyDetailsContent = styled.div`
  display: grid;
  ${up("lg")} {
    grid-template-columns: 1fr 4fr;
  }
  grid-column-gap: 76px;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const MyDetailsMainContent = styled.div``;

const MyDetailsWrapperFirst = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;

  ${up("md")} {
    grid-template-columns: 1fr 1fr;
    margin-left: 120px;
    margin-right: 120px;
  }
  margin-top: 40px;
`;

const MyDetailsDivider = styled.div`
  border-top: 1px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  border-style: solid;
  border-color: #304669;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const UploadButtonWrapper = styled.div`
  width: 288px;
  height: 63px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: min(666px, 100vw);
  margin-right: auto;
  margin-bottom: 68px;
  justify-content: space-between;
  margin-left: auto;
  ${up("sm")} {
    flex-direction: row;
  }
  button {
    margin-bottom: 12px;
    width: 220px;

    ${up("sm")} {
      margin-left: 12px;
s    }
    font-size: 10px;
    ${up("md")} {
      width: 190px;
      font-size: 12px;
    }

    ${up("lg")} {
      margin-left: 0;
    }
  }
`;

const MyDetailsWrapperImage = styled.img`
  width: 318px;
  height: 238px;
  object-fit: scale-down;
`;

const MyDetailsWrapperText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${up("md")} {
    display: initial;
    flex-direction: initial;
    align-items: initial;
    margin-left: -40px;
  }
  margin-top: 40px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 500px;
  ${up("lg")} {
    max-width: none;
    display: block;
    justify-content: initial;
    margin-left: 200px;
    margin-right: auto;
  }
  margin-bottom: 48px;
`;

export const MyDetails = () => {
  const navigate = useNavigate();
  const { user, openFileSelector, currentUserImageURL } = useAuth();

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        <MyAccountHero>My details</MyAccountHero>
        <LayoutBodyFullwidth>
          <LayoutBody>
            <MyDetailsContent>
              <MyAccountSidebar />
              <MyDetailsMainContent>
                <MyDetailsWrapperFirst>
                  <MyDetailsWrapperImage
                    src={currentUserImageURL ?? "/assets/images/teddy.png"}
                  />
                  <MyDetailsWrapperText>
                    <H1
                      style={{
                        color: "#304669",
                        fontSize: "30px",
                        lineHeight: "34px",
                        fontWeight: "bold",
                        textAlign: "left",
                        marginBottom: "11px",
                      }}
                    >
                      Upload new profile photo
                    </H1>
                    <H4
                      style={{
                        color: "#304669",
                        fontSize: "20px",
                        fontWeight: "normal",
                        textAlign: "left",
                        marginBottom: "20px",
                      }}
                    >
                      You can upload a photo from your computer.
                    </H4>
                    <UploadButtonWrapper>
                      <Button
                        onClick={() => openFileSelector()}
                        buttonType="button"
                        type="bluebg"
                        text="Click here to upload"
                      />
                    </UploadButtonWrapper>
                  </MyDetailsWrapperText>
                </MyDetailsWrapperFirst>
                <MyDetailsDivider></MyDetailsDivider>
                <MyDetailsContinued>
                  <DetailsWrapper>
                    <AccountDetailsGrid />
                  </DetailsWrapper>
                  <ButtonWrapper>
                    <Button
                      onClick={() => navigate("/changepassword")}
                      buttonType="button"
                      type="secondary"
                      text="CHANGE MY PASSWORD"
                    />

                    <Button
                      onClick={() => navigate("/changeregion")}
                      buttonType="button"
                      type="secondary"
                      text="CHANGE MY LOCATION"
                    />
                    <Button
                      onClick={() => navigate("/changeemail")}
                      buttonType="button"
                      type="secondary"
                      text="CHANGE MY EMAIL"
                    />
                  </ButtonWrapper>
                </MyDetailsContinued>
              </MyDetailsMainContent>
            </MyDetailsContent>
          </LayoutBody>
        </LayoutBodyFullwidth>
      </div>
    </>
  );
};

const MyDetailsContinued = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${up("lg")} {
    display: initial;
    flex-direction: initial;
    align-items: initial;
  }
`;

export default MyDetails;
