import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { Button } from "./Button";
import { H1 } from "./Header";
import { MobileMenuHero } from "./MobileMenuHero";

const DesktopHeroWrapper = styled.div`
  display: flex;
  /* padding: 3rem 0; */
  align-items: center;
  justify-content: center;
  /* background-image: ${COLORS.GRADIENT}; */
  background-image: url("/assets/images/herobatterybackground4.png");
  /* background-image: url("/assets/images/heroimagesmaller.webp"); */
  /* background-size:  */
  // make the background image scale nicely
  background-size: cover;

  // make the background image scale nicely
  background-position: center;
  background-repeat: no-repeat;

  background-position: center-top;
  background-repeat: no-repeat;
  text-align: left;

  height: 355px;

  flex-direction: column;

  @media (min-width: 526px) {
    flex-direction: row;
    height: 800px;
    /* padding-left: 88px;
    padding-left: 88px; */
  }
  /* height: 697px;
  max-width: 100%;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    max-width: 320px;
    height: 597px;
  } */
  /* 
  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    max-width: 375px;
    height: 597px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    max-width: 426px;
    height: 597px;
  }
  @media (max-width: 1000px) {
    display: none;
  } */

  h1 {
    font-size: 24px;

    color: white;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    @media (min-width: 728px) {
      font-size: 40px;
    }
  }
`;

const HeroContent = styled.div`
  width: 50%;
  max-width: 724px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    max-width: 570px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    max-width: 570px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    max-width: 570px;
  }
`;

const HeroLogo = styled.img`
  width: 470px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    width: 270px;
    margin-left: -110px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    width: 270px;
    margin-left: -110px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    width: 270px;
    margin-left: -110px;
  }
`;

const SearchArea = styled.form`
  display: grid;
  grid-template-columns: 70% 30%;
  /* margin-bottom: 20px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  } */
`;

const SearchBoxInput = styled.input`
  padding-left: 32px;
  margin-right: 16px;
  ::placeholder {
    font-weight: bold;
    color: ${COLORS.NAVY_BLUE};
  }
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    width: 270px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    width: 270px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    width: 270px;
  }
`;

const SearchBoxButton = styled.div`
  display: flex;
  width: 238px;
  height: 54px;
  // SMALL MOBILE DEVICE
  @media (max-width: 320px) {
    padding-top: 10px;
    max-width: 278px;
  }

  // MEDIUM MOBILE DEVICE
  @media (max-width: 375px) {
    padding-top: 10px;
    max-width: 278px;
  }
  // LARGE MOBILE DEVICE
  @media (max-width: 426px) {
    padding-top: 10px;
    max-width: 278px;
  }
`;

export const HomeHero = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = ({ keywords }: any) => {
    navigate("/buy?keywords=" + keywords);
  };

  return (
    <>
      {/* <MobileMenuHero /> */}
      <DesktopHeroWrapper>
        {/* Align grid content to the left */}
        <TextWrapper>
          <h1>Join the movement to save the planet and power your projects!</h1>
          <h1>
            Sell your used batteries on our marketplace or buy new ones for your
            next project.
          </h1>

          <ConvertButton />
        </TextWrapper>
      </DesktopHeroWrapper>
    </>
  );
};

const ConvertButton = () => {
  return (
    <Link to={"/sellregister"}>
      <JoinWrapper>Sign me up!</JoinWrapper>
    </Link>
  );
};

const JoinWrapper = styled.div`
  /* background-color: ${COLORS.PRIMARY}; */
  color: ${COLORS.BURGUNDY_RED};
  font-weight: 700;
  border: solid 2px white;

  padding: 12px;
  margin-top: 32px;
  max-width: fit-content;

  border-radius: 40px;

  font-size: 24px;
  @media (min-width: 525px) {
    font-size: 40px;
  }

  /* padding: 18px 50px; */
  /* margin-right: 40px; */
`;

// height: "fit-content",
// // Only big enough to contain content

// // This needs to standout from the background image of the CTA
// backgroundColor: "rgba(0, 0, 0, 0.824)",
// // Soften the edges
// borderRadius: "8px",
// // Padding to make it look nice
// padding: "24px",
const TextWrapper = styled.div`
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.824);
  border-radius: 8px;
  padding: 24px;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 80%;
  h1 {
    font-size: 16px;

    @media (min-width: 525px) {
      font-size: 24px;
    }

    &:first-child {
      padding-bottom: 24px;
    }
  }
`;
