import { up } from "styled-breakpoints";
import styled from "styled-components";
import { ALL_CATEGORIES, ISubCategory } from "../constants/categories";
import { COLORS } from "../constants/colors";
import { ALL_LOCATIONS } from "../constants/locations";
import { POSTAGE } from "../constants/postage";
import { IListing } from "../hooks/useListing";
import { H4 } from "./Header";
import { SectionHeading } from "./SectionHeading";
import { SelectInputClass } from "./SelectInput";

export const SellDetails = ({
  register,
  subCategories,
  showPriceWarning,
}: {
  register: any;
  subCategories: ISubCategory[];
  showPriceWarning: boolean;
  existingListing?: IListing;
}) => {
  return (
    <ListingDetails>
      <SectionHeading
        icon={FileIcon}
        title="Listing details"
        subTitle={
          <H4
            style={{
              color: COLORS.NAVY_BLUE,
              fontSize: "16px",
              fontWeight: "normal",
              textAlign: "left",
            }}
          >
            Complete all the sections below to start your listing. You will get
            to preview your listing next!
          </H4>
        }
      >
        <></>
      </SectionHeading>

      <ListingDetailsGrid>
        <LeftSideInputFields>
          <LeftSideForm>
            <LeftSideLabelClass>Listing Title</LeftSideLabelClass>
            <LeftSideInputClass
              maxLength={200}
              placeholder="Listing Title"
              {...register("title", {
                required: "Enter a title for your listing",
                maxLength: 200,
              })}
            />
            <LeftSideLabelClass>Listing Description</LeftSideLabelClass>

            <LeftSideInputClassLarge
              placeholder="Description"
              maxLength={1000}
              {...register("description", {
                required: "Enter a description for your listing",
                maxLength: 1000,
              })}
            />
          </LeftSideForm>
        </LeftSideInputFields>

        <RightSideInputFields>
          <RightSideForm>
            <RightSideLabelClass>
              <>Price</>
              {showPriceWarning && (
                <span
                  style={{
                    maxWidth: "170px",
                    color: COLORS.BURGUNDY_RED,
                    fontSize: "12px",
                    alignSelf: "end",
                    fontWeight: "normal",
                  }}
                >
                  <i>* Are you sure you want to list this for free?</i>
                </span>
              )}
            </RightSideLabelClass>

            <FakeDollarInputClass>
              <RightSideInputClass
                placeholder={"Price"}
                type={"number"}
                {...register("price", {
                  valueAsNumber: true,
                  required: "Enter a price for your listing",
                  maxLength: 88,
                  min: 0,
                })}
              />
            </FakeDollarInputClass>

            {subCategories && subCategories.length > 0 && (
              <>
                <RightSideLabelClass>Select Sub Category</RightSideLabelClass>
                <SelectInputClass
                  defaultValue={""}
                  disabled={!subCategories || !(subCategories.length > 0)}
                  {...register("subCategory", {
                    required: "Select a sub category",
                  })}
                >
                  <option disabled value="">
                    Select Sub Category
                  </option>
                  {/* Make an option for each string value for the SUBCATEGORY enum */}
                  {subCategories &&
                    subCategories.map((subCategory: ISubCategory) => (
                      <option
                        key={subCategory.apiName + "subCat"}
                        value={JSON.stringify(subCategory)}
                      >
                        {subCategory.name}
                      </option>
                    ))}
                </SelectInputClass>
              </>
            )}

            <RightSideLabelClass>Condition</RightSideLabelClass>
            <SelectInputClass
              {...register("condition", {
                required: "Select a condition",
              })}
              defaultValue={""}
            >
              <option disabled value="">
                Condition
              </option>
              <option value="NEW">New</option>
              <option value="USED">Used</option>
            </SelectInputClass>
            <RightSideLabelClass>Preferred Postage</RightSideLabelClass>
            <SelectInputClass
              {...register("postage", { required: "Select a postage option" })}
              defaultValue={""}
            >
              <option disabled value="">
                Preferred Postage
              </option>
              {Object.keys(POSTAGE).map((postage: any) => (
                <option key={postage} value={postage}>
                  {POSTAGE[postage as keyof typeof POSTAGE]}
                </option>
              ))}
            </SelectInputClass>
            <RightSideLabelClass>Location for listing</RightSideLabelClass>
            <SelectInputClass
              {...register("location", { required: "Select a region" })}
              defaultValue={""}
            >
              <option disabled value="">
                Location
              </option>
              {Object.keys(ALL_LOCATIONS).map((location: any) => (
                <option key={location} value={location}>
                  {ALL_LOCATIONS[location as keyof typeof ALL_LOCATIONS]}
                </option>
              ))}
            </SelectInputClass>
          </RightSideForm>
        </RightSideInputFields>
      </ListingDetailsGrid>
    </ListingDetails>
  );
};

const FileIcon = <img src="/assets/images/papersicon.svg" alt="File Icon" />;

const ListingDetailsGrid = styled.div`
  display: flex;
  flex-direction: column;
  ${up("lg")} {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-column-gap: 60px;
    margin-right: 80px;
  }
`;

const LeftSideInputFields = styled.div`
  display: flex;
  flex-direction: column;
  color: #304669;
  font-size: 16px;
`;

const RightSideInputFields = styled.div``;

const LeftSideForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftSideLabelClass = styled.label`
  font-size: 20px;
  margin-bottom: 12px;
  margin-left: px;
  margin-top: 20px;
  color: #304669;
  font-weight: bold;
  text-align: left;
`;

const LeftSideInputClass = styled.input`
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 20px;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }
`;

const LeftSideInputClassLarge = styled.textarea`
  height: 415px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 20px;
  padding-top: 24px;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }
`;

const RightSideForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightSideLabelClass = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 12px;
  margin-top: 20px;
  color: #304669;
  font-weight: bold;
  text-align: left;
`;
const FakeDollarInputClass = styled.span`
  display: flex;
  position: relative;
  align-items: center;
  height: 50px;
  color: #304669;
  border: 1px inset #ccc;
  border-style: solid;
  border-color: #304669;
  font-size: 14px;

  ::before {
    position: absolute;
    left: 10px;
    content: "$";
  }
`;

const RightSideInputClass = styled.input`
  border: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-left: 20px;
  ::placeholder {
    color: #304669;
    font-weight: normal;
  }
`;

const ListingDetails = styled.div`
  margin-top: 30px;
  padding-bottom: 60px;
  padding-top: 55px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-top: 1px;
  border-style: solid;
  border-color: #304669;
`;
