import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import { useAuth } from "../hooks/useAuth";
import {
  IReviewFromApi,
  ReviewRating,
  useTransaction,
} from "../hooks/useTransaction";
import { getDateAsRelativeString } from "../utils/date";
import { H4 } from "./Header";

const FeedbackItemIcon = styled.img`
  width: 36px;
  height: 36px;
`;

const ICON_FOR_RATING: { [key in ReviewRating]: React.ReactNode } = {
  [ReviewRating.BAD]: (
    <FeedbackItemIcon src="/assets/images/thumbsdownicon.svg" />
  ),
  [ReviewRating.FIFTY_FIFTY]: (
    <FeedbackItemIcon src="/assets/images/straightfaceicon.svg" />
  ),
  [ReviewRating.GOOD]: <FeedbackItemIcon src="/assets/images/thumbsup.svg" />,
};

export const MyFeedbackList = () => {
  const { getUsersFeedback, userFeedback } = useTransaction();
  const { user, usersById } = useAuth();
  const navigate = useNavigate();
  // WIll only be present when checking other users user pages.
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      // Checking for other users feedback
      getUsersFeedback(id);
      return;
    }
    if (!user?.id) return;
    getUsersFeedback(user?.id.uuid);
  }, [getUsersFeedback, user]);
  const onClickAuthor = (id: string) => {
    navigate("/account/" + id);
  };

  if (!user?.id) return <div>Loading...</div>;
  const myFeedback = userFeedback[id ?? user.id.uuid];
  if (!myFeedback) return <div>Loading...</div>;
  if (!usersById) return <div>Loading...</div>;

  return (
    <Sections>
      {myFeedback.map((feedback: IReviewFromApi) => {
        if (
          !usersById ||
          !usersById[feedback.relationships.author.data.id.uuid] ||
          !usersById[feedback.relationships.subject.data.id.uuid]
        ) {
          return <div key={feedback.id.uuid}>Loading...</div>;
        }

        return (
          <FeedbackItem key={feedback.id.uuid}>
            <FeedbackItemHeader>
              <IconUsername>
                {ICON_FOR_RATING[feedback.attributes.rating]}
                <FeedbackItemHeading
                  onClick={() => {
                    onClickAuthor(feedback.relationships.author.data.id.uuid);
                  }}
                >
                  <H4
                    style={{
                      color: "#304669",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    From{" "}
                    {
                      usersById[feedback.relationships.author.data.id.uuid]
                        .attributes.profile.displayName
                    }
                  </H4>
                </FeedbackItemHeading>
              </IconUsername>

              <FeedbackItemSellerUsername>
                <H4
                  style={{
                    color: "#304669",
                    fontSize: "8px",
                    fontWeight: "bold",
                    border: "2px",
                    borderStyle: "solid",
                    borderRadius: "8px",
                    borderColor: "#304669",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  {
                    usersById[feedback.relationships.subject.data.id.uuid]
                      .attributes.profile.displayName
                  }{" "}
                  was the{" "}
                  {feedback.attributes.type === "ofCustomer"
                    ? "buyer"
                    : "seller"}
                </H4>
              </FeedbackItemSellerUsername>
            </FeedbackItemHeader>
            <p
              style={{
                fontSize: "20px",
                fontStyle: "italic",
                fontWeight: "regular",
                lineHeight: "30px",
                color: "#304669",
              }}
            >
              {feedback.attributes.content}
            </p>
            <p
              style={{
                textAlign: "right",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "normal",
                lineHeight: "30px",
                color: "#304669",
              }}
            >
              Feedback given{" "}
              {getDateAsRelativeString(new Date(feedback.attributes.createdAt))}
            </p>
          </FeedbackItem>
        );
      })}
    </Sections>
  );
};

const Sections = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${up("lg")} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    margin-top: 60px;
  }
`;

const FeedbackItem = styled.div`
  text-align: left;
  margin-bottom: 60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
`;

const FeedbackItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${up("md")} {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 20px;
  }
`;

const FeedbackItemHeading = styled.div`
  margin-top: 5px;
  margin-left: 5px;

  :hover {
    cursor: pointer;
  }
`;

const FeedbackItemSellerUsername = styled.div``;

const IconUsername = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
