import { up } from "styled-breakpoints";
import styled from "styled-components";
import { H2 } from "../components/Header";

interface ISearchResultsNoneProps {
  type?: "search" | "listings" | "purchases";
  text: string;
}

const OopsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 20px;
  ${up("lg")} {
    grid-template-columns: 1fr 1fr;
    margin-top: 100px;
    width: 850px;
  }
  max-width: 100vw;
  align-items: center;
  margin-bottom: 40px;
`;

const OopsImg = styled.img`
  width: 120px;
  ${up("lg")} {
    width: 200px;
  }
`;

const Text = styled.div`
  color: #f27442;
  font-size: 1.5rem;
  ${up("lg")} {
    font-size: 50px;
  }
  font-weight: 900;
  /* margin-left: -150px; */
`;

export const SearchResultsNone = (props: ISearchResultsNoneProps) => {
  const { type, text } = props;
  return (
    <OopsSection>
      <OopsImg src="/assets/images/sadtear.svg" />
      <Text>{text}</Text>
    </OopsSection>
  );
};
