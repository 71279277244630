import styled from "styled-components";
import { Ad } from "../components/Ad";
import { AllListingsWithCategorySidebar } from "../components/AllListingsWithCategorySidebar";
import { SearchProvider } from "../components/SearchProvider";
import { SearchSection } from "../components/SearchSection";

const BuyPageWrapper = styled.div`
  margin-bottom: 120px;
`;

export const Buy = () => {
  return (
    <SearchProvider>
      <BuyPageWrapper>
        <SearchSection />
        {/* <AllListingsWithCategorySidebar /> */}
      </BuyPageWrapper>
    </SearchProvider>
  );
};

export default Buy;
