import React from "react";
import { ISignupDetails, IUser } from "../components/AuthProvider";

export enum AUTH_ERROR_CODE {
  INVALID_PASSWORD_OR_USER = "auth/invalid-password",
  WEAK_PASSWORD = "auth/weak-password",
  EMAIL_EXISTS = "auth/email-already-in-use",
}

interface AuthContextType {
  user: IUser | undefined;
  login: (
    data: { email: string; password: string },
    callback: VoidFunction
  ) => void;
  logout: (callback: VoidFunction) => void;
  signup: (data: ISignupDetails, callback: VoidFunction) => void;
  isSignedIn: boolean;
  isLoading: boolean;
  error: AUTH_ERROR_CODE | null;
  changePassword: (
    currentPassword: string,
    newPassword: string,
    callback: (error: any | undefined) => void
  ) => void;
  requestPasswordReset: (email: string, callback: VoidFunction) => void;
  resetPassword: (
    email: string,
    newPassword: string,
    token: string,
    callback: VoidFunction
  ) => void;
  updateUserDetails: (data: any, callback: VoidFunction) => void;
  updateProfilePicture: (image: File, callback: VoidFunction) => void;
  currentUserImageURL: string | null;
  openFileSelector: VoidFunction;
  usersById: { [userId: string]: IUser };
  getUserById: (userId: string) => void;
  usersByIdImageURL: { [userId: string]: string };
  verifyEmail: (token: string, callback: () => void) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
export const useAuth = () => React.useContext(AuthContext);
