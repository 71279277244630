import { up } from "styled-breakpoints";
import styled from "styled-components";
import { LayoutBody, LayoutBodyFullwidth } from "../components/Layout";
import { MyAccountSidebar } from "../components/MyAccountSidebar";
import { MyFeedbackList } from "../components/MyFeedbackList";
import { SectionHeading } from "../components/SectionHeading";

const Content = styled.div`
  display: grid;
  padding: 24px;
  ${up("lg")} {
    grid-template-columns: 1fr 4fr;
  }
  grid-column-gap: 43px;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const FeedbackMainContent = styled.div`
  ${up("lg")} {
    margin-left: 15px;
  }
  margin-top: 10px;
`;

const HeadingIcon = styled.img`
  height: 30px;
  width: 30px;
`;

export const MyFeedback = () => {
  return (
    <LayoutBodyFullwidth>
      <LayoutBody>
        <Content>
          <MyAccountSidebar />
          <FeedbackMainContent>
            <SectionHeading
              icon={
                <HeadingIcon
                  src="/assets/images/feedbackicon.svg"
                  alt="Feedback Icon"
                />
              }
              title="All of my feedback"
            >
              <></>
            </SectionHeading>
            <MyFeedbackList />
          </FeedbackMainContent>
        </Content>
      </LayoutBody>
    </LayoutBodyFullwidth>
  );
};
