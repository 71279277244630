import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { LayoutBodyFullwidth } from "../components/Layout";
import { useAuth } from "../hooks/useAuth";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { up } from "styled-breakpoints";

export const ChangePassword = () => {
  return (
    <ChangePasswordWrapper>
      <ChangePasswordHeroWrap />
      <LayoutBodyFullwidth>
        <ChangePasswordFormWrap />
      </LayoutBodyFullwidth>
    </ChangePasswordWrapper>
  );
};

const ChangePasswordHeroWrap = () => {
  return (
    <div>
      <StyledH1>Change your password</StyledH1>
      <ChangePasswordSubText>
        <StyledH4>Change your password below.</StyledH4>
      </ChangePasswordSubText>
    </div>
  );
};

const ChangePasswordFormWrap = () => {
  // Password reset flow - ignore for password changes only ---------
  const [searchParams, setSearchParams] = useSearchParams();
  const emailFromParams = searchParams.get("e");
  const tokenFromParams = searchParams.get("t");
  const isResetRequest = !!tokenFromParams && !!emailFromParams;
  const [showInvalidPasswordError, setShowInvalidPasswordError] =
    useState(false);

  const formSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Password is mandatory")
      .min(8, "Password must be at least 8 characters long"),
    newPasswordConfirm: Yup.string()
      .required("Password is mandatory")
      .min(8, "Password must be at least 8 characters long")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const { changePassword, resetPassword } = useAuth();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    if (isResetRequest) {
      resetPassword(tokenFromParams, data.newPassword, emailFromParams, () => {
        navigate("/changepasswordconfirm");
      });
      return;
    }

    changePassword(
      data.currentPassword,
      data.newPassword,
      (error: any | undefined) => {
        if (error) {
          setShowInvalidPasswordError(true);
          return;
        }
        navigate("/changepasswordconfirm");
      }
    );
  };

  return (
    <div>
      <ChangePasswordForm onSubmit={handleSubmit(onSubmit)}>
        {!isResetRequest && (
          <>
            <ChangePasswordLabelClass>
              Current password
            </ChangePasswordLabelClass>
            <ChangePasswordInputClass
              type={"password"}
              {...register("currentPassword", { required: true })}
              placeholder="Password"
            />
          </>
        )}
        <ChangePasswordLabelClass>New password</ChangePasswordLabelClass>
        <ChangePasswordInputClass
          type={"password"}
          {...register("newPassword", { required: true })}
          placeholder="New password"
        />
        <ChangePasswordLabelClass>
          Type your new password again
        </ChangePasswordLabelClass>
        <ChangePasswordInputClass
          type={"password"}
          {...register("newPasswordConfirm", { required: true })}
          placeholder="New password again"
        />
        <PasswordErrors>{errors.newPasswordConfirm?.message}</PasswordErrors>
        <PasswordErrors>
          {showInvalidPasswordError && "Wrong password, please try again."}
        </PasswordErrors>
        <ChangePasswordButton type="submit">
          CHANGE PASSWORD
        </ChangePasswordButton>
      </ChangePasswordForm>
    </div>
  );
};

const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 120px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  ${up("lg")} {
    margin-top: 80px;
  }
`;

const ChangePasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fbf0eb;
  width: 332px;
  height: 435px;
  padding-top: 60px;
  margin-top: 40px;
  padding-bottom: 40px;

  ${up("lg")} {
    width: 732px;
    height: 475px;
  }
`;

const ChangePasswordLabelClass = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #304669;
  font-weight: bold;
  text-align: center;
  margin-left: 0px;

  ${up("lg")} {
    margin-left: 110px;
    text-align: left;
  }
`;

const ChangePasswordInputClass = styled.input`
  width: 254px;
  height: 50px;
  color: #304669;
  border: 1px;
  border-style: solid;
  border-color: #304669;
  padding-left: 10px;
  align-self: center;
  ::placeholder {
    font-size: 14px;
    color: #304669;
    font-weight: normal;
  }

  ${up("lg")} {
    width: 508px;
  }
`;

const ChangePasswordButton = styled.button`
  align-self: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  width: 190px;
  height: 63px;
  background-color: #304669;
  border-style: solid;
  border-radius: 3px;
  border-color: #304669;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 2px;
    border-style: solid;
    border-color: #304669;
    color: #304669;
    transition-duration: 500ms;
  }

  ${up("lg")} {
    margin-top: 40px;
  }
`;

const ChangePasswordSubText = styled.div`
  margin-top: 20px;
`;

const StyledH1 = styled.h1`
  color: #f27442;
  font-size: 36px;
  font-weight: bold;

  ${up("lg")} {
    font-size: 60px;
  }
`;

const StyledH4 = styled.h4`
  color: #304669;
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;

  ${up("lg")} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const PasswordErrors = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

export default ChangePassword;
