import { ISidebarOption, Sidebar } from "./Sidebar";

// PAGE LAYOUT EXPORT
const SidebarOptions: ISidebarOption[] = [
  {
    title: "Buying",
    subOptions: [
      {
        title: "My purchases",
        link: "/mypurchases",
      },
    ],
  },
  {
    title: "Selling",
    subOptions: [
      {
        title: "My listings",
        link: "/mylistings",
      },
    ],
  },
  {
    title: "My details",
    subOptions: [
      {
        title: "My details",
        link: "/mydetails",
      },
      {
        title: "My feedback",
        link: "/myfeedback",
      },
    ],
  },
  { title: "Help & support", link: "/faq", subOptions: [] },
];

export const MyAccountSidebar = () => <Sidebar options={SidebarOptions} />;
