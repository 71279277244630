/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { IListing, useListing } from "../hooks/useListing";
import {
  IApiTransaction,
  IHistoricListing,
  IReviewFromApi,
  IReviewToPost,
  MY_ROLE,
  ReviewRating,
  TransactionContext,
  TRANSITIONS,
  TRANS_ALIAS,
} from "../hooks/useTransaction";
import { canIGiveFeedbackOnApiTransaction } from "../utils/transactions";
const { UUID } = require("sharetribe-flex-sdk").types;

const sharetribeSdk = require("sharetribe-flex-sdk");
//www.sharetribe.com/api-reference/
// Create new SDK instance
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_CLIENT_ID,
});

export const TransactionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getListingsByIds } = useListing();
  const { getUserById } = useAuth();

  const [historicListings, setHistoricListings] = useState<IHistoricListing[]>(
    []
  );
  const [boughtListings, setBoughtListings] = useState<IHistoricListing[]>([]);
  const [soldListings, setSoldListings] = useState<IHistoricListing[]>([]);
  const [userFeedback, setUserFeedback] = useState<{
    [userId: string]: IReviewFromApi[];
  }>({});
  // Maps user ids to a number score.
  const [userScoresById, setUserScoresById] = useState<{
    [userId: string]: number;
  }>({});

  useEffect(() => {
    setBoughtListings(
      historicListings.filter((listing) => listing.myRole === MY_ROLE.CUSTOMER)
    );

    setSoldListings(
      historicListings.filter((listing) => listing.myRole === MY_ROLE.PROVIDER)
    );
  }, [historicListings]);

  const setUserScoreByFeedback = useCallback(
    (feedback: IReviewFromApi[], userId: string) => {
      const score = feedback.reduce(
        (acc, feedback) =>
          feedback.attributes.rating === ReviewRating.GOOD ? acc + 1 : acc,
        0
      );
      setUserScoresById((prev) => ({ ...prev, [userId]: score }));
    },
    [setUserScoresById]
  );

  const convertListingsToHistoricListings = useCallback(
    async (
      transactionHistory: IApiTransaction[],
      listings: IListing[]
    ): Promise<IHistoricListing[]> => {
      //TODO: Abstract this out to a single function that can be reused globally.
      // Get user id.
      const userId = await sdk.currentUser
        .show()
        .then((res: any) => {
          return res.data.data.id.uuid;
        })
        .catch((error: any) => {
          console.error("Error getting user!", error);
        });

      const historicListings: IHistoricListing[] = [];
      // Go through all the listings and match them up with the historic transaction that relates to it.
      for (const transaction of transactionHistory) {
        const listing = listings.find(
          (listing) =>
            listing.id.uuid === transaction.relationships.listing.data.id.uuid
        );
        if (listing) {
          const myRole =
            transaction.relationships.provider.data.id.uuid === userId
              ? MY_ROLE.PROVIDER
              : MY_ROLE.CUSTOMER;
          const { canGiveFeedback, nextFeedbackTransition } =
            canIGiveFeedbackOnApiTransaction(transaction, myRole);
          const amISecondToGiveFeedback = false;
          historicListings.push({
            ...listing,
            transaction: transaction,
            myRole,
            canGiveFeedback,
            nextFeedbackTransition,
            amISecondToGiveFeedback,
          });
        }
      }
      return historicListings;
    },
    []
  );

  const getListingsForTransactions = useCallback(
    async (transactions: IApiTransaction[]) => {
      if (!transactions || transactions.length === 0) {
        return;
      }

      const listingIds = transactions.reduce<string[]>(
        (acc, transaction) => [
          ...acc,
          transaction.relationships.listing.data.id.uuid,
        ],
        []
      );
      const listings = await getListingsByIds(listingIds);
      const convertedListings = await convertListingsToHistoricListings(
        transactions,
        listings
      );
      setHistoricListings(convertedListings);
    },
    [setHistoricListings]
  );

  const purchaseListingById = (listingId: string, callback: VoidFunction) => {
    sdk.transactions
      .initiate({
        processAlias: TRANS_ALIAS,
        transition: TRANSITIONS.RESERVE_STOCK,
        params: {
          listingId,
          stockReservationQuantity: 1,
        },
      })
      .then((res: any) => {
        return sdk.transactions.transition({
          id: res.data.data.id,
          transition: TRANSITIONS.ACCEPT_STOCK,
          params: {},
        });
      })
      .then(() => {
        callback();
      })
      .error((error: any) => {
        console.error("Error starting transaction!", error);
      });
  };

  const getTransactionHistory = useCallback(() => {
    sdk.transactions
      .query({
        // only: "order",
        // lastTransitions: "transition/acceptstock",
        include: ["listing", "provider", "customer"],
      })
      .then((res: { data: { data: IApiTransaction[] } }) => {
        getListingsForTransactions(res.data.data);
      });
  }, [getListingsForTransactions]);

  const getUsersForAllFeedback = useCallback(
    (userFeedback: IReviewFromApi[]) => {
      userFeedback.forEach((feedback) => {
        getUserById(feedback.relationships.author.data.id.uuid);
        getUserById(feedback.relationships.subject.data.id.uuid);
      });
    },
    [userFeedback, getUserById]
  );

  const submitReview = useCallback(
    (
      review: IReviewToPost,
      transition: TRANSITIONS,
      callback: VoidFunction
    ) => {
      sdk.transactions
        .transition(
          {
            id: new UUID(review.transactionId),
            transition,
            params: {
              reviewRating: review.reviewRating,
              reviewContent: review.reviewContent,
            },
          },
          {
            expand: true,
          }
        )
        .then(() => {
          callback();
        });
    },
    []
  );
  const getUsersFeedback = useCallback(
    (userId: string) => {
      sdk.reviews
        .query({
          subjectId: new UUID(userId),
          include: ["author", "subject"],
        })
        .then((res: any) => {
          setUserFeedback((prev) => ({ ...prev, [userId]: res.data.data }));
          getUsersForAllFeedback(res.data.data);
          setUserScoreByFeedback(res.data.data, userId);
        });
    },
    [setUserFeedback]
  );

  const value = {
    purchaseListingById,
    historicListings,
    soldListings,
    boughtListings,
    submitReview,
    userFeedback,
    getUsersFeedback,
    getTransactionHistory,
    userScoresById,
  };

  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  );
};
